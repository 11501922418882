import React from 'react';
import { useTranslation } from 'react-i18next';
import { TitlePositioner } from '@shared/general';
import { DictionaryTab } from './dictionary-tab/dictionary-tab';
import { TermsTab } from './terms-tab/terms-tab';
import { PricesTab } from './prices-tab/prices-tab';
import { EditModelButton, GridLabel, Panel, RenderIf, Snackbar, Tabs, TextLabel, UsecaseButton } from '@lib/ui-components';
import { ProjectBranchViewModel, ProjectType } from '@lib/api-interface';
import { RolesEnum } from '../../../../../domain/enums/roles.enum';
import { useUserState } from '../../../../../state/UserState';
import { AuthoritiesEnum } from '../../../../../domain/enums/authorities.enum';
import * as Yup from 'yup';
import { useQueryClient } from '@tanstack/react-query';
import { DataResultsDto, RestUsecaseClientService } from '@lib/common-sdk';
import { useProjectContext } from '@context';
import { DateTimeService } from '../../../../../services/date-time.service';
import { useStateIfMounted } from 'use-state-if-mounted';

export const ProjectTab = () => {
  const { t } = useTranslation();
  const userState = useUserState();
  const canSetProjectNumber = userState.authorities.findIndex((value) => value === AuthoritiesEnum.ASSIGNS_PROJECT_NUMBER) >= 0;
  const queryClient = useQueryClient();
  const projectView = useProjectContext();
  const [failureMessage, setFailureMessage] = useStateIfMounted('');

  return (
    <>
      <Panel borderType='raised'>
        <TitlePositioner marginLeft='0.6rem'>
          <TextLabel label={t('Model.Project.data')} />
        </TitlePositioner>
        <GridLabel gridTemplateColumns='repeat(6,1fr)'>
          <Panel gap='1rem'>
            <TextLabel label={t('projectNum')} value={projectView.projectNumber} />
            <TextLabel label={t('projectLeadPerson')} value={projectView.projectLeadPerson} />
          </Panel>
          <Panel gap='1rem'>
            <TextLabel label={t('projectName')} value={projectView.name} />
            <TextLabel label={t('salesperson')} value={projectView.sellerPerson} />
          </Panel>
          <Panel gap='1rem'>
            <TextLabel label={t('Model.Project.mainCompanyName')} value={projectView.mainCompanyBranchName} />
            <TextLabel label={t('invoicingPerson')} value={projectView.invoicePerson} />
          </Panel>
          <Panel gap='1rem'>
            <TextLabel label={t('Model.Project.projectNameForSubcontractor')} value={projectView.projectNameForSubcontractor} />
          </Panel>
          <Panel gap='1rem'>
            <TextLabel
              label={t('contractConclusionDate')}
              value={projectView.contractConclusionDate ? new Date(projectView.contractConclusionDate).toISOString() : undefined}
              dateFormat={DateTimeService.DEFAULT_FORMAT_DATE}
            />
          </Panel>
          <GridLabel gridTemplateColumns='none' justifyContent='flex-end'>
            <EditModelButton
              id={0} // TODO: Rewrite to use RestUseCaseButton
              entity={projectView}
              modelName={'projects'}
              dialogTitle={t('editData')}
              fields={[
                {
                  rowItems: [
                    {
                      label: t('projectNum'),
                      type: 'text',
                      field: 'projectNumber',
                      isVisible: () => canSetProjectNumber,
                    },
                    {
                      label: t('Model.Project.selectMainCompany'),
                      type: 'table-select',
                      field: 'mainCompanyBranchId',
                      defaultValue: projectView.mainCompanyBranchId,
                      tableSelectParams: {
                        modelName: 'projectBranchViews',
                        modelClass: ProjectBranchViewModel,
                        selectedValueField: 'branchId',
                        search: 'byProjectId',
                        displayFormat: '{companyName} {companyBranchName}',
                        removeHateoasPathFromResult: true,
                        dialogTitle: t('Model.Project.selectMainCompany'),
                        fetchFilters: { projectId: projectView.projectId },
                        columns: [
                          {
                            key: 'companyName',
                            label: t('companyName'),
                            type: 'text',
                          },
                          {
                            key: 'companyBranchName',
                            label: t('Model.CompanyBranch.name'),
                            type: 'text',
                          },
                          {
                            key: 'vatin',
                            label: t('vatin'),
                            type: 'text',
                          },
                        ],
                      },
                    },
                  ],
                },
                {
                  rowItems: [
                    {
                      label: t('projectType'),
                      type: 'select',
                      field: 'type',
                      width: '22rem',
                      selectOptions: [
                        { value: ProjectType.TRADING, label: t('Model.Project.type.TRADING') },
                        { value: ProjectType.ONE_TIME, label: t('Model.Project.type.ONE_TIME') },
                        { value: ProjectType.OTHER, label: t('Model.Project.type.OTHER') },
                      ],
                    },
                    { label: t('name'), type: 'text', field: 'name' },
                  ],
                },
                {
                  label: t('Model.Project.projectNameForSubcontractor'),
                  type: 'text',
                  field: 'projectNameForSubcontractor',
                },
                {
                  label: t('salesperson'),
                  type: 'table-select',
                  field: 'sellerPersonId',
                  defaultValue: projectView.sellerPersonId,
                  tableSelectParams: {
                    modelName: 'accounts',
                    search: 'byRolesAndAuthorities',
                    displayFormat: '{name}',
                    dialogTitle: t('salesperson'),
                    removeHateoasPathFromResult: true,
                    fetchFilters: {
                      roles: RolesEnum.ORGANIZATION,
                      authorities: AuthoritiesEnum.PROJECT_SALES,
                    },
                    columns: [
                      {
                        key: 'name',
                        label: t('name'),
                        type: 'text',
                      },
                      {
                        key: 'login',
                        label: t('login'),
                        type: 'text',
                      },
                      {
                        key: 'email',
                        label: t('email'),
                        type: 'text',
                      },
                    ],
                  },
                },
                {
                  label: t('projectLeadPerson'),
                  type: 'table-select',
                  field: 'projectLeadPersonId',
                  defaultValue: projectView.projectLeadPersonId,
                  tableSelectParams: {
                    modelName: 'accounts',
                    search: 'byRolesAndAuthorities',
                    displayFormat: '{name}',
                    removeHateoasPathFromResult: true,
                    dialogTitle: t('projectLeadPerson'),
                    fetchFilters: {
                      roles: RolesEnum.ORGANIZATION,
                      authorities: AuthoritiesEnum.PROJECT_LEAD,
                    },
                    columns: [
                      {
                        key: 'name',
                        label: t('name'),
                        type: 'text',
                      },
                      {
                        key: 'login',
                        label: t('login'),
                        type: 'text',
                      },
                      {
                        key: 'email',
                        label: t('email'),
                        type: 'text',
                      },
                    ],
                  },
                },
                {
                  label: t('invoicingPerson'),
                  type: 'table-select',
                  field: 'invoicePersonId',
                  defaultValue: projectView.invoicePersonId,
                  tableSelectParams: {
                    modelName: 'accounts',
                    search: 'byRolesAndAuthorities',
                    displayFormat: '{name}',
                    dialogTitle: t('invoicingPerson'),
                    removeHateoasPathFromResult: true,
                    fetchFilters: {
                      roles: RolesEnum.ORGANIZATION,
                      authorities: AuthoritiesEnum.PROJECT_INVOICE,
                    },
                    columns: [
                      {
                        key: 'name',
                        label: t('name'),
                        type: 'text',
                      },
                      {
                        key: 'login',
                        label: t('login'),
                        type: 'text',
                      },
                      {
                        key: 'email',
                        label: t('email'),
                        type: 'text',
                      },
                    ],
                  },
                },
                {
                  label: t('contractConclusionDate'),
                  type: 'date',
                  field: 'contractConclusionDate',
                  defaultValue: projectView.contractConclusionDate,
                },
              ]}
              validationSchema={{
                type: Yup.string().required(t('Model.Project.type.required')),
                name: Yup.string().required(t('Model.Project.name.required')),
                projectNameForSubcontractor: Yup.string().required(t('Model.Project.projectNameForSubcontractor.required')),
                mainCompanyBranchId: Yup.string().required(t('Model.Project.selectMainCompany.required')),
                projectLeadPersonId: Yup.string().required(t('projectLeadPerson.required')),
                sellerPersonId: Yup.string().required(t('salesperson.required')),
                invoicePersonId: Yup.string().required(t('invoicingPerson.required')),
              }}
              additionalFormButtons={[
                <UsecaseButton
                  groupName={'project'}
                  useCaseName={'UpdateProjectActiveStatusUseCase'}
                  hiddenValues={{ projectId: projectView.projectId, active: !projectView.active }}
                  buttonLabel={t(projectView.active ? 'deactivate' : 'reactivate')}
                  forceConfirmation={true}
                  color='warning'
                  onError={(error) => setFailureMessage(error)}
                  onSaved={async () => queryClient.invalidateQueries(['projectViews', projectView.projectId])}
                />,
              ]}
              overrideSaveCall={async (payload: any) => {
                try {
                  payload.projectId = projectView.projectId;
                  await RestUsecaseClientService.post<any, unknown>('project', 'update-project-data', payload);
                  return new DataResultsDto(false, 200, undefined, undefined, undefined);
                } catch (error: any) {
                  return new DataResultsDto(true, error.status, undefined, undefined, undefined);
                }
              }}
              onSaved={async () => queryClient.invalidateQueries(['projectViews', projectView.projectId])}
            />
          </GridLabel>
        </GridLabel>
      </Panel>
      <Tabs
        marginTop='1rem'
        panels={[
          { label: t('Projects.ViewProject.dictionaryTab'), component: <DictionaryTab /> },
          { label: t('Projects.ViewProject.termsTab'), component: <TermsTab /> },
          { label: t('Projects.ViewProject.pricesTab'), component: <PricesTab /> },
        ]}
        borderType='none'
      />
      <RenderIf true={failureMessage !== undefined && failureMessage !== ''}>
        <Snackbar message={failureMessage} severity='error' onClose={() => setFailureMessage('')} />
      </RenderIf>
    </>
  );
};

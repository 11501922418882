import { Expose } from 'class-transformer';
import { WasteModel } from '../global-infrastructure/waste.model';

export class ProjectWaste {
  @Expose()
  projectWasteId?: string;
  @Expose()
  projectWasteDescription?: string;
  @Expose()
  waste: WasteModel | undefined;
}

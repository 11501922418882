import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextLabel } from '@lib/ui-components';
import { TitlePositioner } from '@shared/general';
import { useCompanyBranchContext } from '../../../../../../../../../contexts/company-branch.context';
import { CompanyContactPersonsTable } from '../../../../company-contact-persons-table';
import {
  ContactPersonTypeEnum,
} from '../../../../../../../../../libs/api-interface/models/company/contact-person-type.enum';

export const CompanyBranchOrders = () => {
  const { t } = useTranslation();
  const companyBranch = useCompanyBranchContext();

  return (
    <>
      <TitlePositioner>
        <TextLabel label={t('orderContacts')} />
      </TitlePositioner>
      <CompanyContactPersonsTable companyId={companyBranch.companyId!} branchId={companyBranch.branchId} type={ContactPersonTypeEnum.ORDER} />
    </>
  );
};

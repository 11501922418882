import { AddModelButton } from '@lib/ui-components';
import { CompanyBranchViewModel, ProjectType } from '@lib/api-interface';
import { RolesEnum } from '../../../domain/enums/roles.enum';
import { AuthoritiesEnum } from '../../../domain/enums/authorities.enum';
import * as Yup from 'yup';
import { DataResultsDto, RestUsecaseClientService } from '@lib/common-sdk';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface CreateProjectButtonProps {
  onSaved: () => void;
}

export const CreateProjectButton = (props: CreateProjectButtonProps) => {
  const { t } = useTranslation();

  return (
    <AddModelButton
      buttonLabel={t('Projects.AddProject.header')}
      dialogTitle={t('Projects.AddProject.header')}
      modelName='projects'
      fields={[
        {
          rowItems: [
            {
              label: t('projectType'),
              type: 'select',
              field: 'type',
              width: '22rem',
              selectOptions: [
                { value: ProjectType.TRADING, label: t('Model.Project.type.TRADING') },
                { value: ProjectType.ONE_TIME, label: t('Model.Project.type.ONE_TIME') },
                { value: ProjectType.OTHER, label: t('Model.Project.type.OTHER') },
              ],
            },
            { label: t('name'), type: 'text', field: 'name' },
          ],
        },
        {
          label: t('Model.Project.projectNameForSubcontractor'),
          type: 'text',
          field: 'projectNameForSubcontractor',
        },
        {
          rowItems: [
            {
              label: t('Model.Project.selectMainCompany'),
              type: 'table-select',
              field: 'mainCompanyBranchId',
              tableSelectParams: {
                modelName: 'companyBranchViews',
                modelClass: CompanyBranchViewModel,
                selectedValueField: 'branchId',
                search: 'byClientAndCentral',
                displayFormat: '{name}',
                removeHateoasPathFromResult: true,
                dialogTitle: t('Model.Project.selectMainCompany'),
                fetchFilters: { central: true, roleClient: true },
                columns: [
                  {
                    key: 'name',
                    label: t('Model.CompanyBranch.name'),
                    type: 'text',
                  },
                  {
                    key: 'vatin',
                    label: t('vatin'),
                    type: 'text',
                  },
                ],
              },
            },
            {
              label: t('projectNum'),
              type: 'text',
              field: 'projectNumberSequence',
              width: '29rem',
            },
          ],
        },
        {
          label: t('salesperson'),
          type: 'table-select',
          field: 'sellerPersonId',
          tableSelectParams: {
            modelName: 'accounts',
            search: 'byRolesAndAuthorities',
            displayFormat: '{name}',
            dialogTitle: t('salesperson'),
            removeHateoasPathFromResult: true,
            fetchFilters: {
              roles: RolesEnum.ORGANIZATION,
              authorities: AuthoritiesEnum.PROJECT_SALES,
            },
            columns: [
              {
                key: 'name',
                label: t('name'),
                type: 'text',
              },
              {
                key: 'login',
                label: t('login'),
                type: 'text',
              },
              {
                key: 'email',
                label: t('email'),
                type: 'text',
              },
            ],
          },
        },
        {
          label: t('projectLeadPerson'),
          type: 'table-select',
          field: 'projectLeadPersonId',
          tableSelectParams: {
            modelName: 'accounts',
            search: 'byRolesAndAuthorities',
            displayFormat: '{name}',
            removeHateoasPathFromResult: true,
            dialogTitle: t('projectLeadPerson'),
            fetchFilters: {
              roles: RolesEnum.ORGANIZATION,
              authorities: AuthoritiesEnum.PROJECT_LEAD,
            },
            columns: [
              {
                key: 'name',
                label: t('name'),
                type: 'text',
              },
              {
                key: 'login',
                label: t('login'),
                type: 'text',
              },
              {
                key: 'email',
                label: t('email'),
                type: 'text',
              },
            ],
          },
        },
        {
          label: t('invoicingPerson'),
          type: 'table-select',
          field: 'invoicePersonId',
          tableSelectParams: {
            modelName: 'accounts',
            search: 'byRolesAndAuthorities',
            displayFormat: '{name}',
            dialogTitle: t('invoicingPerson'),
            removeHateoasPathFromResult: true,
            fetchFilters: {
              roles: RolesEnum.ORGANIZATION,
              authorities: AuthoritiesEnum.PROJECT_INVOICE,
            },
            columns: [
              {
                key: 'name',
                label: t('name'),
                type: 'text',
              },
              {
                key: 'login',
                label: t('login'),
                type: 'text',
              },
              {
                key: 'email',
                label: t('email'),
                type: 'text',
              },
            ],
          },
        },
        {
          label: t('contractConclusionDate'),
          type: 'date',
          field: 'contractConclusionDate',
        },
      ]}
      hiddenValues={{
        terms: {
          general: {
            organizationAsSide: true,
          },
        },
      }}
      validationSchema={{
        type: Yup.string().required(t('Model.Project.type.required')),
        name: Yup.string().required(t('Model.Project.name.required')),
        projectNumberSequence: Yup.string().required(t('Model.Project.projectNumber.required')),
        projectNameForSubcontractor: Yup.string().required(t('Model.Project.projectNameForSubcontractor.required')),
        mainCompanyBranchId: Yup.string().required(t('Model.Project.selectMainCompany.required')),
        projectLeadPersonId: Yup.string().required(t('projectLeadPerson.required')),
        sellerPersonId: Yup.string().required(t('salesperson.required')),
        invoicePersonId: Yup.string().required(t('invoicingPerson.required')),
      }}
      overrideSaveCall={async (payload: any) => {
        try {
          await RestUsecaseClientService.post<any, unknown>('project', 'create-project', payload);
          return new DataResultsDto(false, 200, undefined, undefined, undefined);
        } catch (error: any) {
          return new DataResultsDto(true, error.status, undefined, undefined, undefined);
        }
      }}
      onSaved={async () => props.onSaved()}
    />
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  AddModelButton,
  DataTable,
  DataTableColumn,
  DeleteModelButton,
  GeneralFormItem,
  GeneralFormRowOfItems,
  GetContentButton,
  Panel,
  RenderIf,
  TextLabel,
  Toolbar,
} from '@lib/ui-components';
import { CompanyRole, CompanyViewModel, ProjectType, ProjectView } from '@lib/api-interface';
import * as Yup from 'yup';
import { DateTimeService } from '../../../../../services/date-time.service';

export interface CompanyAgreementsProps {
  company: CompanyViewModel;
  role: CompanyRole;
  addNewDocumentButtonHidden?: boolean;
}

export default function CompanyAgreements(props: CompanyAgreementsProps) {
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const { t } = useTranslation();
  const [selectedProjectId, selectProjectId] = React.useState('');

  const projectField: GeneralFormItem = React.useMemo(() => {
    const formData: GeneralFormItem = {
      label: t('project'),
      type: 'table-select-by-api',
      field: 'projectId',
      tableSelectByApiParams: {
        apiPostParams: { companyId: props.company.companyId, role: props.role },
        apiPostEndpoint: '/api/usecase/project/fetch-projects-for-company',
        dialogTitle: t('project'),
        displayFormat: '{name}',
        selectedValueField: 'projectId',
        onRowSelected: (row: ProjectView) => selectProjectId(row?.projectId ?? ''),
        columns: [
          {
            key: 'projectNumber',
            label: t('projectNum'),
            type: 'text-chip',
          },
          {
            key: 'name',
            label: t('name'),
            type: 'text',
          },
          {
            key: 'vatin',
            label: t('vatin'),
            type: 'text',
          },
          {
            key: 'type',
            label: t('projectType'),
            type: 'enum',
            enumValues: [
              { value: ProjectType.TRADING, label: t('Model.Project.type.TRADING') },
              { value: ProjectType.ONE_TIME, label: t('Model.Project.type.ONE_TIME') },
              { value: ProjectType.OTHER, label: t('Model.Project.type.OTHER') },
            ],
          },
          {
            key: 'mainCompanyBranchName',
            label: t('Model.Project.mainCompanyName'),
            type: 'text',
          },
          {
            key: 'sellerPerson',
            label: t('salesperson'),
            type: 'text',
          },
          {
            key: 'projectLeadPerson',
            label: t('projectLeadPerson'),
            type: 'text',
          },
          {
            key: 'invoicePerson',
            label: t('invoicingPerson'),
            type: 'text',
          },
          {
            key: 'contractConclusionDate',
            label: t('contractConclusionDate'),
            type: 'date',
          },
        ],
      },
    };
    return formData;
  }, [t, props.company.companyId]);

  const companyBranchField: GeneralFormItem = React.useMemo(() => {
    const formDataForClient: GeneralFormItem = {
      field: 'companyBranchId',
      label: t('branch'),
      type: 'table-select-by-api',
      tableSelectByApiParams: {
        apiPostParams: {
          companyId: props.company.companyId,
          role: props.role,
          projectId: selectedProjectId,
        },
        apiPostEndpoint: '/api/usecase/project/fetch-branches-for-project-company',
        dialogTitle: t('branch'),
        displayFormat: '{companyBranchName}',
        selectedValueField: 'branchId',
        columns: [
          {
            key: 'companyBranchName',
            label: t('Model.CompanyBranch.name'),
            type: 'text',
          },
        ],
      },
    };
    formDataForClient.isDeactivated = formDataForClient.resetField = !selectedProjectId;

    const formDataForSubcontractor: GeneralFormItem = {
      label: t('branch'),
      type: 'table-select',
      field: 'companyBranchId',
      tableSelectParams: {
        modelName: 'companyBranchViews',
        dialogTitle: t('branch'),
        displayFormat: '{name}',
        selectedValueField: 'branchId',
        columns: [{ key: 'name', label: t('Model.CompanyBranch.name'), type: 'text' }],
        fetchFilters: { companyId: props.company.companyId, role: props.role },
      },
    };

    return props.role === CompanyRole.CLIENT ? formDataForClient : formDataForSubcontractor;
  }, [selectedProjectId, t, props.company.companyId]);

  const noticePeriodOptions = [
    {
      label: t('month'),
      value: 'MONTH',
    },
    {
      label: t('twoMonths'),
      value: 'TWO_MONTHS',
    },
    {
      label: t('threeMonths'),
      value: 'THREE_MONTHS',
    },
    {
      label: t('sixMonths'),
      value: 'SIX_MONTHS',
    },
    {
      label: t('year'),
      value: 'YEAR',
    },
  ];

  const addNewDocumentButton = React.useMemo(() => {
    const addNewDocumentFields: (GeneralFormItem | GeneralFormRowOfItems)[] = [
      companyBranchField,
      {
        label: t('dragAndDropAFile'),
        type: 'file',
        field: 'content',
      },
      {
        label: t('comment'),
        type: 'text',
        field: 'comment',
      },
    ];

    if (props.role === CompanyRole.CLIENT) {
      addNewDocumentFields.unshift(projectField);
      addNewDocumentFields.push(
        { label: t('validFrom'), type: 'date', field: 'activeFrom' },
        {
          label: t('noticePeriod'),
          type: 'select',
          field: 'noticePeriod',
          selectOptions: noticePeriodOptions,
        },
        { label: t('forUnlimitedPeriod'), type: 'boolean', field: 'forUnlimitedPeriod' },
        {
          label: t('validUntil'),
          type: 'date',
          field: 'activeTo',
          isVisible: (data: { forUnlimitedPeriod?: boolean }) => data['forUnlimitedPeriod'] == false,
        },
      );
    }

    return (
      <AddModelButton
        multipart={true}
        buttonLabel={t('addNewDocument')}
        dialogTitle={t('addNewDocument')}
        modelName={'companyFileViews'}
        fields={addNewDocumentFields}
        hiddenValues={{ type: 'AGREEMENT', companyId: props.company.companyId, role: props.role }}
        validationSchema={{
          companyBranchId: Yup.string().required(t('requiredField')),
          content: Yup.mixed().required(t('requiredField')),
          projectId: Yup.string().when('role', {
            is: CompanyRole.CLIENT,
            then: Yup.string().required(t('requiredField')),
            otherwise: Yup.string().notRequired(),
          }),
        }}
        onBeforeSave={(formData: any) => {
          const agreementData: any = {};
          agreementData.activeFrom = formData.activeFrom;
          agreementData.forUnlimitedPeriod = formData.forUnlimitedPeriod;
          agreementData.noticePeriod = formData.noticePeriod;
          if (!formData.forUnlimitedPeriod) {
            agreementData.activeTo = formData.activeTo;
          }
          formData.agreementDataJsonString = JSON.stringify(agreementData);
          delete formData.activeFrom;
          delete formData.forUnlimitedPeriod;
          delete formData.activeTo;
          delete formData.noticePeriod;
          return formData;
        }}
        onSaved={async () => {
          setTableReloadKey(tableReloadKey + 1);
        }}
      />
    );
  }, [t, props.company.companyId, projectField, companyBranchField]);

  const clientDataTableColumns: DataTableColumn[] = [
    {
      key: 'fileName',
      label: t('file'),
      type: 'text',
    },
    {
      key: 'comment',
      label: t('comment'),
      type: 'text',
    },
    {
      key: 'project.projectNumber',
      label: t('projectNum'),
      type: 'text-chip',
    },
    {
      key: 'companyBranch.name',
      label: t('Model.CompanyBranch.name'),
      type: 'text',
    },
    {
      key: 'companyBranch.registrationAddress.town',
      label: t('locality'),
      type: 'text',
    },
    {
      key: 'companyBranch.registrationAddress.street',
      label: t('street'),
      type: 'text',
    },
    {
      key: 'agreementData.activeFrom',
      label: t('validFrom'),
      type: 'date',
    },
    {
      key: 'agreementData.forUnlimitedPeriod',
      label: t('forUnlimitedPeriod'),
      type: 'boolean',
      align: 'center',
    },
    {
      key: 'agreementData.activeTo',
      label: t('validUntil'),
      type: 'date',
    },
    {
      key: 'agreementData.noticePeriod',
      label: t('noticePeriod'),
      type: 'enum',
      enumValues: noticePeriodOptions,
    },
    {
      key: 'uploadDate',
      label: t('added'),
      type: 'date',
    },
    {
      key: 'uploadBy',
      label: t('introducedBy'),
      type: 'text',
    },
  ];

  const subcontractorDataTableColumns: DataTableColumn[] = [
    {
      key: 'fileName',
      label: t('file'),
      type: 'text',
    },
    {
      key: 'comment',
      label: t('comment'),
      type: 'text',
    },
    {
      key: 'companyBranch.name',
      label: t('Model.CompanyBranch.name'),
      type: 'text',
    },
    {
      key: 'uploadDate',
      label: t('added'),
      type: 'date',
    },
    {
      key: 'uploadBy',
      label: t('introducedBy'),
      type: 'text',
    },
  ];

  const dataTable = React.useMemo(() => {
    return (
      <DataTable
        refreshKey={tableReloadKey}
        fetchFilters={{ companyId: props.company.companyId, type: 'AGREEMENT', role: props.role }}
        fetchPostUrl={'/api/resource/companyFileViews/by-params'}
        columns={props.role === CompanyRole.CLIENT ? clientDataTableColumns : subcontractorDataTableColumns}
        rowOptions={[
          {
            renderer: (row) => <GetContentButton label={t('viewFile')} icon={<VisibilityIcon />} resourcePath={`api/resource/companyFileViews/${row.id}/content`} />,
          },
          {
            renderer: (row) => <DeleteModelButton modelName={'companyFileViews'} id={row.id} onDeleted={async () => setTableReloadKey(tableReloadKey + 1)} />,
          },
        ]}
      />
    );
  }, [props.company.companyId, t, tableReloadKey]);

  return (
    <Panel borderType='flat'>
      <RenderIf false={props.addNewDocumentButtonHidden}>
        <Toolbar right={[addNewDocumentButton]} />
      </RenderIf>
      {dataTable}
      {props.company.lastModifiedClientAgreementsBy != null && props.role === CompanyRole.CLIENT && (
        <TextLabel
          value={`${t('lastModifiedFiles')}: ${props.company.lastModifiedClientAgreementsBy}  [${DateTimeService.isoStringDateToDateTime(
            props.company.lastModifiedClientAgreementsDate,
          )}]`}
        />
      )}
      {props.company.lastModifiedSubcontractorAgreementsBy != null && props.role === CompanyRole.SUBCONTRACTOR && (
        <TextLabel
          value={`${t('lastModifiedFiles')}: ${props.company.lastModifiedSubcontractorAgreementsBy}  [${DateTimeService.isoStringDateToDateTime(
            props.company.lastModifiedSubcontractorAgreementsDate,
          )}]`}
        />
      )}
    </Panel>
  );
}

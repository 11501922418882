import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, NavigateButton } from '@lib/ui-components';
import { useProjectContext } from '../../../../../contexts/project.context';
import { CompanyBranchViewModel } from '@lib/api-interface';

export default function MonthlyPayoffTab() {
  const { t } = useTranslation();
  const projectView = useProjectContext();

  return (
    <DataTable
      excel={true}
      refreshKey={1}
      fetchPostUrl='/api/project/subcontractors'
      fetchFilters={{ projectId: projectView.projectId }}
      preSortIndexes={[4]}
      columns={[
        {
          key: 'branchNestedNumber',
          label: t('branchNestedNumber'),
          type: 'numeric',
          align: 'right',
        },
        {
          key: 'companyName',
          label: t('companyName'),
          type: 'text',
          minWidth: '15rem',
        },
        {
          key: 'name',
          label: t('branch'),
          type: 'text',
          minWidth: '15rem',
        },
        {
          key: 'central',
          label: t('headquarter'),
          type: 'boolean',
          align: 'center',
        },
        {
          key: 'vatin',
          label: t('vatin'),
          type: 'text',
        },
        {
          key: 'registrationAddress.postalCode',
          label: t('postcode'),
          type: 'text',
        },
        {
          key: 'registrationAddress.town',
          label: t('locality'),
          type: 'text',
        },
        {
          key: 'registrationAddress.street',
          label: t('street'),
          type: 'text',
        },
        {
          key: 'registrationAddress.houseNum',
          label: t('houseNum'),
          type: 'text',
        },
        {
          key: 'registrationAddress.apartmentNum',
          label: t('apartmentNum'),
          type: 'text',
        },
        {
          key: 'registrationAddress.province',
          label: t('province'),
          type: 'text',
        },
      ]}
      rowOptions={[
        {
          renderer: (row: CompanyBranchViewModel) => <NavigateButton label={t('show')} to={`/companies/!${row.companyId}/!${row.branchId}`} />,
        },
      ]}
    />
  );
}

import { IsNotEmpty } from 'class-validator';
import { Expose } from 'class-transformer';
import { BaseModel } from '@lib/common-sdk';
import { PaymentMethod } from '../service/payment-method.enum';
import { InvoicingMethod } from '../service/invoicing-method.enum';

export enum ProjectType {
  TRADING = 'TRADING',
  ONE_TIME = 'ONE_TIME',
  OTHER = 'OTHER',
}

export enum PixSettlementDate {
  MONTHLY_PREVIOUS_MONTH = 'MONTHLY_PREVIOUS_MONTH',
  MONTHLY_CURRENT_MONTH = 'MONTHLY_CURRENT_MONTH',
  DAILY_LAST_DAY_PREVIOUS_MONTH = 'DAILY_LAST_DAY_PREVIOUS_MONTH',
  DAILY_LAST_DAY_CURRENT_MONTH = 'DAILY_LAST_DAY_CURRENT_MONTH',
  DAILY_COLLECTION_DAY_PREVIOUS_DAY = 'DAILY_COLLECTION_DAY_PREVIOUS_DAY',
  DAILY_COLLECTION_DAY_CURRENT_DAY = 'DAILY_COLLECTION_DAY_CURRENT_DAY',
}

export class ProjectTerms {
  @Expose()
  allowedMassDiff?: number;
  @Expose()
  rsi?: boolean;
  @Expose()
  counselling?: boolean;
  @Expose()
  packing?: boolean;
  @Expose()
  poi?: boolean;
  @Expose()
  machineService?: boolean;
  @Expose()
  employeeOutsourcing?: boolean;

  // settlements
  @Expose() aggregatingSettlement?: boolean;
  @Expose() paymentMethod?: PaymentMethod;
  @Expose() invoicingMethod?: InvoicingMethod;
}

export class ProjectSettings {
  @Expose() newServicesDontRequireAdminAcceptance?: boolean;
  @Expose() branchCanGenerateReports?: boolean;
  @Expose() userAdminCanReportCollections?: boolean;
  @Expose() collectionRequestActive?: boolean;
  @Expose() organizationIsParticipating?: boolean;

  @Expose() acceptDeclarationChangeByOrganizationAdmin?: boolean;
  @Expose() acceptDeclarationChangeByUserAdmin?: boolean;
  @Expose() acceptDeclarationChangeByClientBranch?: boolean;
  @Expose() acceptDeclarationChangeByRegionalAdmin?: boolean;
}

export class ProjectPixSettings {
  @Expose() pixSettlementDate?: PixSettlementDate;
}

export class ProjectView extends BaseModel {
  @Expose()
  projectId?: string; // Aggregate id

  @IsNotEmpty()
  @Expose()
  projectNumber?: string;

  @IsNotEmpty()
  @Expose()
  name?: string;

  @IsNotEmpty()
  @Expose()
  projectNameForSubcontractor?: string;

  @IsNotEmpty()
  @Expose()
  sellerPerson?: string;

  @IsNotEmpty()
  @Expose()
  sellerPersonId?: string;

  @IsNotEmpty()
  @Expose()
  invoicePerson?: string;

  @IsNotEmpty()
  @Expose()
  invoicePersonId?: string;

  @IsNotEmpty()
  @Expose()
  projectLeadPerson?: string;

  @IsNotEmpty()
  @Expose()
  projectLeadPersonId?: string;

  @IsNotEmpty()
  @Expose()
  mainCompanyId?: string; // centrala główna - aggregate id

  @Expose()
  mainCompanyName?: string; // nazwa centrali głównej

  @Expose()
  mainCompanyBranchId?: string; // oddział centrali głównej - branchId

  @Expose()
  mainCompanyBranchName?: string; // nazwa oddziału centrali głównej

  @IsNotEmpty()
  @Expose()
  type?: ProjectType;

  @Expose()
  vatin?: string;

  @Expose()
  terms?: ProjectTerms;

  @Expose()
  projectSettings?: ProjectSettings;

  @Expose()
  projectPixSettings?: ProjectPixSettings;

  @Expose()
  contractConclusionDate?: Date;

  @Expose()
  active?: boolean;
}

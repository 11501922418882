import * as React from 'react';
import { BreadcrumbMatch } from 'use-react-router-breadcrumbs';
import i18n from 'i18next';
import { Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { getBreadcrumbDataProvider } from '@lib/ui-components';
import { useQuery } from '@tanstack/react-query';
import DownloadingIcon from '@mui/icons-material/Downloading';

export interface BreadcrumbProps {
  match: BreadcrumbMatch<string>;
}

const dummyFetchFunction = async (): Promise<string> => {
  return '';
};

export const Breadcrumb = (props: BreadcrumbProps) => {
  function getNormalButton(text: string) {
    return (
      <Button variant='text' color='secondary' size='small'>
        {text}
      </Button>
    );
  }

  // eslint-disable-next-line
  const { t } = useTranslation(); // needed to react to language change (even if not used directly)
  const translations: any = i18n.getDataByLanguage(i18n.language);
  const keys = translations.common;
  const path = props.match.pathname;

  let postfix = 'home';
  if (path !== '/') {
    postfix = path.substring(1).replaceAll('/', '.');
  }
  const key = `Breadcrumb.${postfix}`;
  const breadcrumbParts = key.split('.');
  const lastBreadcrumbPart = breadcrumbParts[breadcrumbParts.length - 1];
  const breadcrumbCode = getBreadcrumbCode(breadcrumbParts);
  const dataProvider = getBreadcrumbDataProvider(breadcrumbCode);
  const hasDataProvider = dataProvider !== undefined;
  const hasDownloadableId = breadcrumbParts[breadcrumbParts.length - 1].startsWith('!');
  const maybeModelId = breadcrumbParts[breadcrumbParts.length - 1].substring(1);
  const fetchFunction = hasDataProvider ? dataProvider.fetchFunction(maybeModelId) : dummyFetchFunction;

  const [label, setLabel] = React.useState('');
  const { data, isLoading } = useQuery([hasDataProvider ? dataProvider.query : '#BreadcrumbQueryNotSet#', maybeModelId], fetchFunction, {
    enabled: hasDownloadableId && hasDataProvider,
  });

  let displayLabel = label;
  if (data) {
    displayLabel = dataProvider?.mapToBreadcrumb(data) ?? '';
  }

  // update if language changed and no data provider
  React.useEffect(() => {
    if (!dataProvider) {
      if (!lastBreadcrumbPart.startsWith('!')) {
        const dynamicKey = `Breadcrumb.${lastBreadcrumbPart}`;
        if (keys[dynamicKey] !== undefined) {
          setLabel(keys[dynamicKey]);
        }
      } else {
        setLabel(keys[key] !== undefined ? keys[key] : key);
      }
    }
  }, [keys]);

  function getBreadcrumbCode(parts: string[]): string {
    let breadcrumbCode = 'Breadcrumb';
    for (let i = 1; i < parts.length - 1; i += 1) {
      if (parts[i].startsWith('!')) {
        breadcrumbCode += '.$';
      } else {
        breadcrumbCode += `.${parts[i]}`;
      }
    }
    return breadcrumbCode;
  }

  return (
    <NavLink className={styles['link']} to={props.match.pathname}>
      {!hasDataProvider && getNormalButton(displayLabel)}
      {hasDataProvider && !isLoading && getNormalButton(displayLabel)}
      {hasDataProvider && isLoading && <DownloadingIcon fontSize='small' color='secondary' style={{ verticalAlign: 'middle' }} />}
    </NavLink>
  );
};

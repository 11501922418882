import { TFunction } from 'react-i18next';
import { InfrastructureCategory } from '@lib/api-interface';

export class InfrastructureCategorySelectOptionsFactory {
  static getSelectOptionsWithAll(t: TFunction<'common'>) {
    return [
      {
        label: t('Model.Infrastructure.InfrastructureCategory.ALL'),
        value: InfrastructureCategory.ALL,
      },
      ...this.getSelectOptions(t),
    ];
  }

  static getSelectOptions(t: TFunction<'common'>) {
    return [
      {
        label: t('Model.Infrastructure.InfrastructureCategory.DUMPSTER'),
        value: InfrastructureCategory.DUMPSTER,
      },
      {
        label: t('Model.Infrastructure.InfrastructureCategory.ROLL_OFF_CONTAINER'),
        value: InfrastructureCategory.ROLL_OFF_CONTAINER,
      },
      {
        label: t('Model.Infrastructure.InfrastructureCategory.BARREL'),
        value: InfrastructureCategory.BARREL,
      },
      {
        label: t('Model.Infrastructure.InfrastructureCategory.BALER'),
        value: InfrastructureCategory.BALER,
      },
      {
        label: t('Model.Infrastructure.InfrastructureCategory.STATIONARY_PRESS'),
        value: InfrastructureCategory.STATIONARY_PRESS,
      },
      {
        label: t('Model.Infrastructure.InfrastructureCategory.BIG_BAG'),
        value: InfrastructureCategory.BIG_BAG,
      },
      {
        label: t('Model.Infrastructure.InfrastructureCategory.PRESS_CONTAINER'),
        value: InfrastructureCategory.PRESS_CONTAINER,
      },
      {
        label: t('Model.Infrastructure.InfrastructureCategory.PALLET'),
        value: InfrastructureCategory.PALLET,
      },
      {
        label: t('Model.Infrastructure.InfrastructureCategory.BAG'),
        value: InfrastructureCategory.BAG,
      },
      {
        label: t('Model.Infrastructure.InfrastructureCategory.LOOSE'),
        value: InfrastructureCategory.LOOSE,
      },
      {
        label: t('Model.Infrastructure.InfrastructureCategory.IBC'),
        value: InfrastructureCategory.IBC,
      },
      {
        label: t('Model.Infrastructure.InfrastructureCategory.TANK'),
        value: InfrastructureCategory.TANK,
      },
      {
        label: t('Model.Infrastructure.InfrastructureCategory.BIN'),
        value: InfrastructureCategory.BIN,
      },
      {
        label: t('Model.Infrastructure.InfrastructureCategory.GITTERBOX'),
        value: InfrastructureCategory.GITTERBOX,
      },
      {
        label: t('Model.Infrastructure.InfrastructureCategory.PALOXE'),
        value: InfrastructureCategory.PALOXE,
      },
      {
        label: t('Model.Infrastructure.InfrastructureCategory.ASP'),
        value: InfrastructureCategory.ASP,
      },
    ];
  }
}

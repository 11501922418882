import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LeftMenuLayout } from '../../components/layouts/left-menu-layout/left-menu-layout';
import { PickupsBranch } from './pickups/pickups-branch';
import { Infrastructure } from './infrastructure/infrastructure';
import { ContactForm } from '../client-admin/contact-form/contact-form';
import { ClientDocuments } from './files/client-documents';
import { Reports } from '../organization/reports/reports';
import { InstructionPDF } from '../public/poi-instruction';

export const ClientUserRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<LeftMenuLayout />}>
        <Route index element={<Navigate to='/pickups' />} />
        <Route path='pickups' element={<PickupsBranch />} />
        <Route path='infrastructure' element={<Infrastructure />} />
        <Route path='contact-form' element={<ContactForm />} />
        <Route path='documents' element={<ClientDocuments />} />
        <Route path='reports' element={<Reports />} />
        <Route path='instruction' element={<InstructionPDF />} />
      </Route>
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};

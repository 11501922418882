import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, Panel } from '@lib/ui-components';
import { ProjectType } from '@lib/api-interface';

export interface CompanyBranchDataProjectsProps {
  branchId: string;
}

export const CompanyBranchAssignedProjects = (props: CompanyBranchDataProjectsProps) => {
  const { t } = useTranslation();

  return (
    <Panel borderType='flat'>
      <DataTable
        refreshKey={1}
        fetchUrl={'/api/organization/project-branches'}
        fetchFilters={{ branchId: props.branchId, deleted: false }}
        columns={[
          {
            key: 'project.projectNumber',
            label: t('projectNum'),
            type: 'text-chip',
          },
          {
            key: 'project.name',
            label: t('projectName'),
            type: 'text',
            linkPath: '/projects/',
            linkRowId: 'project.projectId',
          },
          {
            key: 'project.sellerPerson',
            label: t('salesperson'),
            type: 'text',
          },
          {
            key: 'project.projectLeadPerson',
            label: t('projectLeadPerson'),
            type: 'text',
          },
          {
            key: 'project.invoicePerson',
            label: t('invoicingPerson'),
            type: 'text',
          },
          {
            key: 'project.type',
            label: t('projectType'),
            type: 'enum',
            enumValues: [
              { value: ProjectType.TRADING, label: t('Model.Project.type.TRADING') },
              { value: ProjectType.ONE_TIME, label: t('Model.Project.type.ONE_TIME') },
              { value: ProjectType.OTHER, label: t('Model.Project.type.OTHER') },
            ],
          },
          {
            key: 'project.creationDate',
            label: t('creationDate'),
            type: 'date',
          },
        ]}
      />
    </Panel>
  );
};

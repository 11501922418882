import { BaseModel } from '@lib/common-sdk';
import { Expose } from 'class-transformer';
import { CompanyAddress, ServicePrice, ServiceType } from '@lib/api-interface';

export enum CollectionRequestType {
  PICKUP = 'PICKUP',
}

export enum CollectionRequestStatus {
  SCHEDULED = 'SCHEDULED',
  IN_REALIZATION = 'IN_REALIZATION',
  FEEDBACK_ALLOWED = 'FEEDBACK_ALLOWED',
  COMPLETED = 'COMPLETED',
  MISSED = 'MISSED',
}

export enum SettlementType {
  PER_MONTH = 'PER_MONTH',
  PER_COLLECTION = 'PER_COLLECTION',
}

export class CollectionRequestViewModel extends BaseModel {
  @Expose()
  collectionRequestId?: string;
  @Expose()
  serviceId?: string;
  @Expose()
  companyBranchId?: string;
  @Expose()
  projectId?: string;
  @Expose()
  type?: CollectionRequestType;
  @Expose()
  status?: CollectionRequestStatus;
  @Expose()
  scheduledStatusChange?: Date;
  @Expose()
  reactionTime?: number;
  @Expose()
  active?: boolean;
  @Expose()
  serviceType?: ServiceType;
  @Expose()
  mass?: number;
  @Expose()
  containers?: number;
  @Expose()
  comment?: string;
  @Expose()
  collectionDate?: string;
  @Expose()
  collectionYearMonth?: string;
  @Expose()
  wasteCode?: string;
  @Expose()
  wasteName?: string;
  @Expose()
  wasteDescription?: string;
  @Expose()
  container?: string;
  @Expose()
  clientCollectionPrice?: ServicePrice;
  @Expose()
  subcontractorCollectionPrice?: ServicePrice;
  @Expose()
  clientTransportPrice?: ServicePrice;
  @Expose()
  subcontractorTransportPrice?: ServicePrice;
  @Expose()
  transporter?: string;
  @Expose()
  clientName?: string;
  @Expose()
  projectNumber?: string;
  @Expose()
  branchName?: string;
  @Expose()
  branchRegistrationAddress?: CompanyAddress;
  @Expose()
  hasSettlement?: boolean;
  @Expose()
  settlementType?: SettlementType;
  @Expose()
  settlementId?: string;
  @Expose()
  emails?: string[];
  @Expose()
  emailsString?: string;
  @Expose()
  creationDate?: Date;
  @Expose()
  createdBy?: string;
  @Expose()
  createdByEmail?: string;
  @Expose()
  lastModifiedDate?: Date;
  @Expose()
  lastModifiedBy?: string;
  @Expose()
  lastModifiedByEmail?: string;
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { TitlePositioner } from '@shared/general';
import { CheckboxLabel, Panel, TextLabel } from '@lib/ui-components';
import { useCompanyBranchContext } from '../../../../../../../../../contexts/company-branch.context';
import { CompanyContactPersonsTable } from '../../../../company-contact-persons-table';
import {
  ContactPersonTypeEnum,
} from '../../../../../../../../../libs/api-interface/models/company/contact-person-type.enum';
import { useCompanyView } from '@data-source-hooks';
import { useQueryClient } from '@tanstack/react-query';

export const CompanyBranchInvoices = () => {
  const { t } = useTranslation();
  const companyBranch = useCompanyBranchContext();
  const [companyQuery] = useCompanyView(companyBranch.companyId!);
  const queryClient = useQueryClient();

  return (
    <>
      <Panel borderType='none' direction={'column'} justifyContent='left' gap='1rem' contentMarginLeft='1rem' contentMarginTop='-2rem'>
        <CheckboxLabel
          label={t('acceptElectronicInvoices')}
          value={companyQuery.data?.digitalInvoiceConsent}
          type='toggle'
          marginTop='-2rem'
          editByAggregateButton={{
            entity: companyQuery.data,
            aggregateName: 'CompanyAggregate',
            commandName: 'updateCompanyDigitalInvoiceConsent',
            field: 'digitalInvoiceConsent',
            fieldLabel: t('acceptElectronicInvoices'),
            aggregateId: companyBranch.companyId!,
            onSaved: async (result) => {
              await queryClient.invalidateQueries(['companyViews', companyBranch.companyId]);
            },
          }}
        />
      </Panel>
      <TitlePositioner>
        <TextLabel label={t('einvoicesSendingContacts')} />
      </TitlePositioner>
      <CompanyContactPersonsTable companyId={companyBranch.companyId!} branchId={companyBranch.branchId} type={ContactPersonTypeEnum.EINVOICE} />
    </>
  );
};

import React from 'react';
import { DataTable, Panel, TableFilterItem } from '@lib/ui-components';
import { useTranslation } from 'react-i18next';
import { ServicePriceBearer, ServiceType } from '@lib/api-interface';
import i18n from 'i18next';
import { priceItem } from '../../../../projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-services/shared-components/shared';

interface ScheduledProps {
  serviceType: ServiceType;
  companyId?: string;
  companyBranchId?: string;
  onDefaultFiltersUpdated?: (filters: Array<TableFilterItem>) => void;
  instanceFiltersRef: React.MutableRefObject<TableFilterItem[]>;
}

export const ScheduledAndPerRequest = (props: ScheduledProps) => {
  const { t } = useTranslation();
  const translations: any = i18n.getDataByLanguage(i18n.language);

  return (
    <Panel>
      <DataTable
        refreshKey={1}
        fetchPostUrl={'/api/service/listByCompany'}
        fetchFilters={{
          subcontractorId: props.companyId ? props.companyId : props.companyBranchId,
          serviceType: props.serviceType,
          active: true,
          searchBySubcontractorCompanyBranch: !!props.companyBranchId,
        }}
        defaultTableFilters={props.instanceFiltersRef.current}
        onDefaultTableFiltersUpdated={(filters) => {
          if (props.onDefaultFiltersUpdated !== undefined) {
            props.onDefaultFiltersUpdated(filters);
          }
        }}
        columns={[
          { key: 'project.projectNumber', label: t('projectNumber'), type: 'text' },
          { key: 'ownerProjectBranch.companyName', label: t('companyName'), type: 'text' },
          { key: 'ownerProjectBranch.companyBranchName', label: t('branchName'), type: 'text' },
          { key: 'projectWaste.waste.code', label: t('wasteCode'), type: 'text' },
          { key: 'projectWaste.waste.description', label: t('wasteDescription'), type: 'text' },
          { key: 'projectWaste.projectWasteDescription', label: t('ownDescriptionOfWaste'), type: 'text' },
          {
            key: 'currentSettings.subcontractorCollectionPrice.stringValue',
            label: t('Projects.ViewProject.subcontractorCollectionPrice'),
            type: 'text',
            colorOnCondition: (row) => (row.currentSettings.subcontractorCollectionPrice.bearer === ServicePriceBearer.ORGANIZATION_COST ? '#EB5757' : '#0AAD50'),
            renderer: (row) =>
              priceItem(row.currentSettings?.subcontractorCollectionPrice, {
                priceTypeVisible: true,
                translations,
                replaceActualPriceWithZero: !row.companyAsSubcontractor,
              }).value as JSX.Element,
          },
          {
            key: 'currentSettings.subcontractorTransportPrice.stringValue',
            label: t('Projects.ViewProject.subcontractorTransportPrice'),
            type: 'text',
            colorOnCondition: (row) => (row.currentSettings.subcontractorTransportPrice.bearer === ServicePriceBearer.ORGANIZATION_COST ? '#EB5757' : '#0AAD50'),
            renderer: (row) =>
              priceItem(row.currentSettings?.subcontractorTransportPrice, {
                priceTypeVisible: true,
                translations,
                replaceActualPriceWithZero: !row.companyAsTransporter,
              }).value as JSX.Element,
          },
          {
            key: 'projectInfrastructureService.name',
            label: t('resourceName'),
            type: 'text',
            renderer: (row) => (row.resourceInBulk ? t('Model.Infrastructure.InfrastructureCategory.LOOSE') : row.projectInfrastructureService.name),
          },
          { key: 'infrastructureCount', label: t('number'), type: 'numeric' },
          { key: 'collectionInfrastructureService.name', label: t('collectionMethod'), type: 'text' },
          { key: 'collectionInfrastructureCount', label: t('numberOfContainers'), type: 'numeric' },
          {
            key: 'applicableCollectionInfrastructureService.estimatedMass',
            label: t('estimatedMass'),
            type: 'text',
          },
          {
            key: 'companyAsSubcontractor',
            label: t('settledSubcontractorNameShortened'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: 'companyAsTransporter',
            label: t('settledTransportEntityShortened'),
            type: 'boolean',
            align: 'center',
          },
        ]}
      />
    </Panel>
  );
};

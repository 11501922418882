import { Expose } from 'class-transformer';
import { numericToStringFormatter } from '../../../common-sdk/utils/numeric-to-string.formatter';
import { InfrastructureCategory, MASS_PRECISION } from '@lib/api-interface';

export class ProjectInfrastructureView {
  @Expose()
  id?: string;

  @Expose()
  projectId?: string;

  @Expose()
  projectInfrastructureId?: string;

  @Expose()
  category?: string;

  @Expose()
  name?: string;

  @Expose()
  description?: string;

  @Expose()
  projectInfrastructureDescription?: string;

  @Expose()
  mass?: Array<number>;

  @Expose()
  volume?: number;

  @Expose()
  assetCode?: string;

  @Expose()
  deleted?: boolean;

  @Expose()
  estimatedMass?: string;

  get providedMass() {
    if (this.mass != null) {
      return this.mass.map((value) => numericToStringFormatter(value, MASS_PRECISION) + ' t').join(', ');
    }
    return '-';
  }

  get providedVolume() {
    if (this.category === InfrastructureCategory.BALER) {
      return undefined;
    }
    return this.volume;
  }
}

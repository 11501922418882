import { AddIcon, DataTable, DataTableColumn, Toolbar, UsecaseButton } from '@lib/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ProjectBranchInfrastructureServiceViewModel,
} from '../../../../../../../../libs/api-interface/models/project/project-branch-infrastructure-service-view.model';
import { useProjectBranchContext } from '../../../../../../../../contexts/project-branch.context';
import { useStateIfMounted } from 'use-state-if-mounted';
import { ProjectInfrastructureOwnerEnum } from '@lib/api-interface';
import {
  useProjectBranchInfrastructureServiceView,
} from '../../../../../../../../data-source-hooks/project-branch-infrastructure-service.data-source';
import * as Yup from 'yup';

export interface InfrastructureServicePricesProps {
  infrastructureService: ProjectBranchInfrastructureServiceViewModel;
}

export const InfrastructureServicePrices = (props: InfrastructureServicePricesProps) => {
  const [tableReloadKey, setTableReloadKey] = useStateIfMounted(0);
  const { t } = useTranslation();
  const projectBranchViewModel = useProjectBranchContext();

  const [serviceQuery, reloadServiceQuery] = useProjectBranchInfrastructureServiceView(props.infrastructureService.projectBranchInfrastructureServiceId);

  if (serviceQuery.isLoading) {
    return <></>;
  }

  const serviceData = serviceQuery.data!;

  const validationSchema: any = {
    activeFromMonth: Yup.string().required(t('fieldRequired')),
    infrastructureCount: Yup.string().required(t('fieldRequired')),
  };

  const tableColumns: DataTableColumn[] = [];
  tableColumns.push({ key: 'activeFromDate', label: t('priceFrom'), type: 'date' });
  tableColumns.push({ key: 'infrastructureCount', label: t('numberOfContainers'), type: 'long-numeric' });
  if (serviceData.owner !== ProjectInfrastructureOwnerEnum.CLIENT) {
    tableColumns.push({ key: 'clientLease', label: t('leasePriceClient'), type: 'long-numeric' });
    validationSchema.clientLease = Yup.string().required(t('fieldRequired'));
  }
  if (serviceData.owner === ProjectInfrastructureOwnerEnum.SUBCONTRACTOR) {
    tableColumns.push({ key: 'subcontractorLease', label: t('leasePriceSubcontractor'), type: 'long-numeric' });
    validationSchema.subcontractorLease = Yup.string().required(t('fieldRequired'));
  }

  return (
    <>
      <Toolbar
        right={[
          <UsecaseButton
            useCaseName='SetProjectBranchInfrastructureServicePriceSagaUseCase'
            groupName='project'
            variant={'contained'}
            color={'info'}
            startIcon={<AddIcon />}
            onSaved={async () => {
              await reloadServiceQuery();
              await setTableReloadKey(tableReloadKey + 1);
            }}
            buttonLabel={t('addPrice')}
            dialogTitle={t('price')}
            hiddenValues={{
              projectBranchId: projectBranchViewModel.projectBranchId!,
              projectBranchInfrastructureServiceId: props.infrastructureService.projectBranchInfrastructureServiceId,
            }}
            fields={[
              { field: 'activeFromMonth', label: t('priceFrom'), type: 'date-year-month', clearableDate: false },
              {
                field: 'infrastructureCount',
                label: t('numberOfContainers'),
                type: 'long-numeric',
                numericMinValue: 1,
              },
              {
                field: 'clientLease',
                label: t('leasePriceClient'),
                type: 'numeric',
                numericPrecision: 2,
                numericMinValue: 0,
                isVisible: (_) => serviceData.owner !== ProjectInfrastructureOwnerEnum.CLIENT,
              },
              {
                field: 'subcontractorLease',
                label: t('leasePriceSubcontractor'),
                type: 'numeric',
                numericPrecision: 2,
                numericMinValue: 0,
                isVisible: (_) => serviceData.owner === ProjectInfrastructureOwnerEnum.SUBCONTRACTOR,
              },
            ]}
            validationSchema={validationSchema}
          />,
        ]}
      />
      <DataTable data={serviceData.settings} columns={tableColumns} refreshKey={tableReloadKey} />
    </>
  );
};

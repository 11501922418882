import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './calendar-styles.css';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Panel, YearFilterBar } from '@lib/ui-components';

interface Holiday {
  date: Date;
  name: string;
}

export const HolidaysCalendar = () => {
  const { i18n, t } = useTranslation();
  const now = new Date();
  const [year, setYear] = React.useState(now.getFullYear());
  const [holidays, setHolidays] = React.useState<Holiday[]>([]);

  React.useEffect(() => {
    const fetchHolidays = async (year: number) => {
      try {
        const response = await axios.get<Holiday[]>(`${process.env['REACT_APP_API_URL']}/api/public-holidays/${year}`);
        setHolidays(response.data);
      } catch (error) {
        console.error('Error fetching holidays:', error);
      }
    };
    fetchHolidays(year);
  }, [year]);

  const tileClassName = ({ date, view }: { date: Date; view: string }) => {
    if (view === 'month') {
      return holidays.some((holiday) => new Date(holiday.date).toDateString() === date.toDateString()) ? 'holiday' : null;
    }
    return null;
  };

  const tileContent = ({ date, view }: { date: Date; view: string }) => {
    if (view === 'month') {
      const holiday = holidays.find((holiday) => new Date(holiday.date).toDateString() === date.toDateString());
      return <div title={holiday ? t(holiday.name as any) : ''}>{date.getDate()}</div>;
    }
    return null;
  };

  const renderMonthlyCalendar = (month: number) => {
    const date = new Date(year, month);
    return (
      <div key={month} className='month-container'>
        <div className='month-title'>{date.toLocaleString(i18n.language, { month: 'long' })}</div>
        <Calendar
          calendarType='iso8601'
          locale={i18n.language}
          activeStartDate={date}
          tileClassName={tileClassName}
          tileContent={tileContent}
          view='month'
          showNavigation={false}
        />
      </div>
    );
  };

  return (
    <div>
      Neue Funktionalität, noch nicht implementiert
    </div>
    // <Panel header={t('holidaysCalendar')} borderType='raised'>
    //   <YearFilterBar
    //     initialYear={year.toString()}
    //     minYear={new Date(2023, 0, 1)}
    //     maxYear={new Date(now.getFullYear() + 1, 0, 1)}
    //     onFilterChanged={(data) => setYear(parseInt(data.date))}
    //   />
    //   <div className='year-container'>{Array.from({ length: 12 }, (_, i) => renderMonthlyCalendar(i))}</div>
    // </Panel>
  );
};

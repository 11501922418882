import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectBranchInfrastructureServiceViewModel, ProjectInfrastructureOwnerEnum, ProjectInfrastructureView, ProjectSettings } from '@lib/api-interface';
import { AddIcon, Button, Dialog, Edit, GeneralFormSelectOption, HStack, Panel, ToggleButtons, Toolbar, UsecaseButton, UsecaseDeleteButton } from '@lib/ui-components';
import { InfrastructureCategorySelectOptionsFactory } from '../../../../../../../../factories/infrastructure-category-select-options.factory';
import * as Yup from 'yup';
import { useStateIfMounted } from 'use-state-if-mounted';
import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import { InfrastructureServicePrices } from './infrastructure-service-prices';
import CloseIcon from '@mui/icons-material/Close';
import { AnyObject } from 'yup/lib/types';
import { useProjectBranchContext, useProjectContext } from '@context';
import { useQueryClient } from '@tanstack/react-query';
import { InfrastructureServicesDataTable } from './infrstructure-services-data-table';

interface InfrastructureServicePricesDialogProps {
  infrastructureService: ProjectBranchInfrastructureServiceViewModel;
  onClosed: () => void;
}

const InfrastructureServicePricesDialog = (props: InfrastructureServicePricesDialogProps) => {
  const { t } = useTranslation();
  const [openDialog, setDialog] = useStateIfMounted(false);

  async function handleCloseDialog() {
    props.onClosed();
    setDialog(false);
  }

  return (
    <>
      <Button label={t('changePrices')} onClick={async () => setDialog(true)} startIcon={<Edit yellowIcon={true} />} />
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth='md' fullWidth={true}>
        <DialogTitle sx={{ margin: 'auto' }}>
          <IconButton
            aria-label='close'
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <InfrastructureServicePrices infrastructureService={props.infrastructureService} />
        </DialogContent>
      </Dialog>
    </>
  );
};

interface InfrastructureServicesProps {
  projectBranchSettings: ProjectSettings;
}

export const InfrastructureServices = (props: InfrastructureServicesProps) => {
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const projectBranchViewModel = useProjectBranchContext();
  const projectView = useProjectContext();

  const infrastructureCategoryOptions: GeneralFormSelectOption[] = InfrastructureCategorySelectOptionsFactory.getSelectOptions(t);
  const projectBranchSettings = props.projectBranchSettings;

  const [activePage, setActivePage] = React.useState(0);

  const isFixedAsset = (data: any) => {
    return data?.fixedAsset === true && data?.owner === ProjectInfrastructureOwnerEnum.ORGANIZATION;
  };

  async function handleCloseDialog() {
    await queryClient.invalidateQueries(['projectBranchViews', projectBranchViewModel.projectBranchId]);
    await setTableReloadKey(tableReloadKey + 1);
  }

  function isEmpty(value?: string) {
    return value == null || value.trim().length === 0;
  }

  const deactivationDateAfterActiveFromDate: Yup.TestFunction<string | undefined, AnyObject> = function (value) {
    const activeFromDate = this.parent.activeFromDate;

    if (isEmpty(value)) {
      return true;
    }
    if (isEmpty(activeFromDate)) {
      return true;
    }
    return value! > activeFromDate;
  };

  return (
    <Panel borderType='raised'>
      <HStack align='center'>
        <ToggleButtons labels={[t('active'), t('inactive')]} activeIndex={activePage} onChange={(idx) => setActivePage(idx)} />
      </HStack>
      <Toolbar
        right={[
          <UsecaseButton
            groupName='project'
            useCaseName='CreateProjectBranchInfrastructureServiceSagaUseCase'
            hiddenValues={{ projectBranchId: projectBranchViewModel.projectBranchId! }}
            startIcon={<AddIcon />}
            buttonLabel={t('addNewInfrastructure')}
            dialogTitle={t('addBranchInfrastructureService')}
            fields={[
              {
                rowItems: [
                  { label: t('activationDate'), type: 'date', field: 'activeFromDate' },
                  { label: t('deactivationDate'), type: 'date', field: 'deactivationDate' },
                ],
              },
              {
                rowItems: [
                  {
                    label: t('owner'),
                    type: 'select',
                    field: 'owner',
                    selectOptions: [
                      {
                        value: ProjectInfrastructureOwnerEnum.SUBCONTRACTOR,
                        label: t('subcontractor'),
                      },
                      { value: ProjectInfrastructureOwnerEnum.CLIENT, label: t('client') },
                      {
                        value: ProjectInfrastructureOwnerEnum.ORGANIZATION,
                        label: t('organization'),
                      },
                      {
                        value: ProjectInfrastructureOwnerEnum.COMMUNE,
                        label: t('commune'),
                      },
                    ],
                  },
                  {
                    label: t('subcontractorsName'),
                    type: 'table-select',
                    field: 'subcontractorCompanyBranchId',
                    tableSelectParams: {
                      modelName: 'companyBranchViews',
                      selectedValueField: 'branchId',
                      columns: [
                        { key: 'name', label: t('name'), type: 'text' },
                        {
                          label: t('town'),
                          key: 'registrationAddress.town',
                          type: 'text',
                        },
                        {
                          label: t('vatin'),
                          key: 'vatin',
                          type: 'text',
                        },
                      ],
                      displayFormat: '{name}',
                      dialogTitle: t('subcontractor'),
                      fetchFilters: {
                        roleContractor: true,
                      },
                    },
                    isVisible: (data) => data.owner === ProjectInfrastructureOwnerEnum.SUBCONTRACTOR,
                  },
                ],
              },
              {
                rowItems: [
                  {
                    label: t('fixedAsset'),
                    type: 'boolean',
                    field: 'fixedAsset',
                    isVisible: (data) => data.owner === ProjectInfrastructureOwnerEnum.ORGANIZATION,
                  },
                  {
                    label: t('projectInfrastructure'),
                    type: 'table-select',
                    field: 'projectInfrastructureId',
                    tableSelectParams: {
                      fetchFilters: {
                        projectId: projectView.projectId,
                        deleted: false,
                      },
                      modelName: 'projectInfrastructureViews',
                      modelClass: ProjectInfrastructureView,
                      selectedValueField: 'projectInfrastructureId',
                      columns: [
                        { key: 'name', label: t('name'), type: 'text' },
                        {
                          key: 'category',
                          label: t('category'),
                          type: 'enum',
                          enumValues: infrastructureCategoryOptions,
                        },
                        { key: 'volume', label: t('volume'), type: 'numeric' },
                        {
                          key: 'projectInfrastructureDescription',
                          label: t('description'),
                          type: 'text',
                        },
                        {
                          key: 'description',
                          label: t('comment'),
                          type: 'text',
                        },
                      ],
                      displayFormat: '{name}',
                      dialogTitle: t('infrastructure'),
                    },
                    isVisible: (data) => !isFixedAsset(data),
                  },
                  {
                    label: t('fixedAssetNumber'),
                    type: 'table-select',
                    field: 'organizationInfrastructureId',
                    tableSelectParams: {
                      modelName: 'organizationInfrastructures',
                      removeHateoasPathFromResult: true,
                      columns: [
                        { key: 'name', label: t('name'), type: 'text' },
                        { key: 'serialNumber', label: t('fixedAssetNumber'), type: 'text' },
                        {
                          key: 'category',
                          label: t('category'),
                          type: 'enum',
                          enumValues: infrastructureCategoryOptions,
                        },
                        { key: 'mass', label: t('mass'), type: 'numeric' },
                        { key: 'volume', label: t('volume'), type: 'numeric' },
                      ],
                      displayFormat: '{serialNumber}',
                      dialogTitle: t('fixedAsset'),
                    },
                    isVisible: (data) => isFixedAsset(data),
                  },
                ],
              },
              {
                rowItems: [
                  {
                    label: t('leasePriceClient'),
                    type: 'numeric',
                    field: 'clientLease',
                    numericPrecision: 2,
                    isVisible: (data) => data.owner !== ProjectInfrastructureOwnerEnum.CLIENT && data.owner !== ProjectInfrastructureOwnerEnum.COMMUNE,
                  },
                  {
                    label: t('leasePriceSubcontractor'),
                    type: 'numeric',
                    field: 'subcontractorLease',
                    numericPrecision: 2,
                    isVisible: (data) => data.owner === ProjectInfrastructureOwnerEnum.SUBCONTRACTOR,
                  },
                ],
              },
              {
                label: t('navResource'),
                type: 'table-select',
                field: 'navResourceId',
                tableSelectParams: {
                  modelName: 'navResources',
                  selectedValueField: 'id',
                  displayFormat: '{name}',
                  columns: [
                    { label: t('navResourceName'), type: 'text', key: 'name' },
                    { label: t('navResourceNumber'), type: 'text', key: 'code' },
                  ],
                  dialogTitle: t('navResource'),
                },
                isVisible: (data) => data.owner !== ProjectInfrastructureOwnerEnum.CLIENT && data.owner !== ProjectInfrastructureOwnerEnum.COMMUNE,
              },
              {
                rowItems: [
                  {
                    label: t('organizationAsSide'),
                    type: 'boolean',
                    field: 'organizationAsSide',
                    defaultValue: projectBranchSettings?.organizationIsParticipating,
                  },
                  {
                    label: t('commune'),
                    type: 'boolean',
                    field: 'commune',
                  },
                  {
                    label: t('containerAmount'),
                    type: 'long-numeric',
                    field: 'infrastructureCount',
                    numericMinValue: 1,
                  },
                ],
              },
            ]}
            onBeforeSave={(data) => {
              if (isFixedAsset(data)) {
                delete data.projectInfrastructureId;
              } else {
                delete data.organizationInfrastructureId;
              }
              if (data.owner !== ProjectInfrastructureOwnerEnum.SUBCONTRACTOR) {
                delete data.subcontractorCompanyBranchId;
                delete data.subcontractorLease;
              }
              return data;
            }}
            onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
            validationSchema={{
              subcontractorCompanyBranchId: Yup.string().test('is-subcontractor-needed', t('subcontractor.required'), function (value) {
                const owner = this.parent.owner;
                return !(owner === ProjectInfrastructureOwnerEnum.SUBCONTRACTOR && (value == null || value.trim().length === 0));
              }),
              deactivationDate: Yup.string().test(
                'is-deactivation-date-after-active-from-date',
                t('deactivationDateAfterActivationDateRequired'),
                deactivationDateAfterActiveFromDate,
              ),
              activeFromDate: Yup.string().required(t('activationDate.required')).typeError(t('activationDate.required')),
              infrastructureCount: Yup.string().required(t('containerAmount.required')),
              clientLease: Yup.string().test('is-clientLease-required', t('leasePrice.required'), function (value) {
                const owner = this.parent.owner;
                return !(owner !== ProjectInfrastructureOwnerEnum.CLIENT && owner !== ProjectInfrastructureOwnerEnum.COMMUNE && (value == null || value.trim().length === 0));
              }),
              subcontractorLease: Yup.string().test('is-subcontractorLease-required', t('leasePrice.required'), function (value) {
                const owner = this.parent.owner;
                return !(owner === ProjectInfrastructureOwnerEnum.SUBCONTRACTOR && (value == null || value.trim().length === 0));
              }),
              projectInfrastructureId: Yup.string().test('is-projectInfrastructure-required', t('fieldRequired'), function (value) {
                const fixedAsset = this.parent.fixedAsset;
                return !(fixedAsset !== true && (value == null || value.trim().length === 0));
              }),
              organizationInfrastructureId: Yup.string().test('is-organizationInfrastructure-required', t('fieldRequired'), function (value) {
                const fixedAsset = this.parent.fixedAsset;
                return !(fixedAsset === true && (value == null || value.trim().length === 0));
              }),
            }}
          />,
        ]}
      />
      <InfrastructureServicesDataTable
        refreshKey={tableReloadKey}
        modelDef={{
          modelName: 'projectBranchInfrastructureServiceViews',
          modelClass: ProjectBranchInfrastructureServiceViewModel,
        }}
        fetchFilters={{
          projectBranchId: projectBranchViewModel.projectBranchId,
          projectId: projectView.projectId,
          deleted: false,
          active: activePage === 0,
        }}
        rowOptions={[
          {
            renderer: (row: ProjectBranchInfrastructureServiceViewModel) => <InfrastructureServicePricesDialog infrastructureService={row} onClosed={() => handleCloseDialog()} />,
          },
          {
            renderer: (row: ProjectBranchInfrastructureServiceViewModel) => (
              <UsecaseButton
                groupName='project-branch'
                useCaseName={'EditProjectBranchInfrastructureServiceDeactivationDateUseCase'}
                startIcon={<Edit yellowIcon={true} />}
                buttonLabel={t('deactivate')}
                dialogTitle={''}
                hiddenValues={{
                  projectBranchInfrastructureServiceId: row.projectBranchInfrastructureServiceId,
                  projectBranchId: projectBranchViewModel.projectBranchId,
                }}
                fields={[
                  {
                    label: t('deactivationDate'),
                    type: 'date',
                    field: 'deactivationDate',
                    defaultValue: row.deactivationDate,
                  },
                ]}
                validationSchema={{
                  deactivationDate: Yup.mixed()
                    .nullable()
                    .test('deactivationDate', t('dateCannotBeBeforeActivationDate'), function (value) {
                      if (!value) return true;
                      const activationDateTimestamp = new Date(row.activationDate!);
                      const deactivationDateTimestamp = new Date(value);
                      return deactivationDateTimestamp >= activationDateTimestamp;
                    }),
                }}
                onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
              />
            ),
          },
          {
            renderer: (row: ProjectBranchInfrastructureServiceViewModel) => (
              <UsecaseDeleteButton
                useCaseName='delete-project-branch-service-infrastructure'
                groupName='project'
                payload={{ projectBranchInfrastructureServiceId: row.projectBranchInfrastructureServiceId }}
                onDeleted={async () => setTableReloadKey(tableReloadKey + 1)}
              />
            ),
          },
        ]}
      />
    </Panel>
  );
};

import { CompanyRole, FinancialReportPeriod, FinancialReportType } from '@lib/api-interface';
import { AddIcon, GeneralFormItem, GeneralFormRowOfItems, UsecaseButton } from '@lib/ui-components';
import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

interface CreateReportButtonProps {
  clientUser?: boolean;
  reportType: FinancialReportType;
  onSaved: () => Promise<any>;
}

export const CreateClientAdminReportButton = ({ clientUser, reportType, onSaved }: CreateReportButtonProps) => {
  const { t } = useTranslation();
  const reportTypeSpecificFilters: (GeneralFormItem | GeneralFormRowOfItems)[] = [];

  // FinancialReportType.FINANCIAL_INCOME_AND_COSTS
  reportTypeSpecificFilters.push(
    {
      field: 'reportPeriod',
      label: t('reportPeriod'),
      type: 'select',
      selectOptions: Object.values(FinancialReportPeriod).map((val) => {
        return { label: t(`financialReportPeriod.${val}`), value: val };
      }),
    },
    {
      rowItems: [
        {
          field: 'start',
          label: t('month'),
          type: 'date-year-month',
        },
        {
          field: 'end',
          label: t('month'),
          type: 'date-year-month',
          isVisible: (data) => data.reportPeriod === FinancialReportPeriod.CUSTOM,
        },
        {
          field: 'sumMonths',
          label: t('sumMonths'),
          type: 'boolean',
          isVisible: (data) => data.reportPeriod === FinancialReportPeriod.CUSTOM,
        },
      ],
    },
  );

  if (!clientUser) {
    reportTypeSpecificFilters.push({
      rowItems: [
        {
          field: 'branchId',
          label: t('branch'),
          type: 'table-select-by-api',
          isDeactivated: (data) => data.allProjects,
          tableSelectByApiParams: {
            apiPostEndpoint: '/api/branches',
            dialogTitle: t('branch'),
            displayFormat: '{companyBranchName}',
            selectedValueField: 'branchId',
            columns: [
              {
                key: 'companyBranchName',
                label: t('branchName'),
                type: 'text',
              },
              {
                key: 'registrationAddress.postalCode',
                label: t('postcode'),
                type: 'text',
              },
              {
                key: 'registrationAddress.commune',
                label: t('commune'),
                type: 'text',
              },
              {
                key: 'registrationAddress.street',
                label: t('street'),
                type: 'text',
              },
              {
                key: 'registrationAddress.houseNum',
                label: t('houseNum'),
                type: 'text',
              },
              {
                key: 'registrationAddress.apartmentNum',
                label: t('apartmentNum'),
                type: 'text',
              },
              {
                key: 'registrationAddress.town',
                label: t('locality'),
                type: 'text',
              },
            ],
          },
        },
      ],
    });
  }

  return (
    <UsecaseButton
      variant='contained'
      startIcon={<AddIcon />}
      onSaved={onSaved}
      groupName='reports'
      useCaseName='generate-report'
      buttonLabel={t('generateReport')}
      fields={[...reportTypeSpecificFilters]}
      onBeforeSave={(data) => {
        data.issuer = CompanyRole.CLIENT;
        data.forClient = true;
        return data;
      }}
      hiddenValues={{ reportType }}
      validationSchema={{
        reportPeriod: Yup.string().required(t('fieldRequired')),
        start: Yup.string().required(t('fieldRequired')),
      }}
    />
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfrastructureCategory, InfrastructureModel, VOLUME_MAX_VAL, VOLUME_MIN_VAL, VOLUME_PRECISION } from '@lib/api-interface';
import {
  AddModelButton,
  CheckboxLabel,
  DataTable,
  DataTableColumn,
  DeleteModelButton,
  EditModelButton,
  GeneralForm,
  GeneralFormSelectOption,
  Panel,
  Toolbar,
} from '@lib/ui-components';
import { InfrastructureCategorySelectOptionsFactory } from '../../../../factories/infrastructure-category-select-options.factory';
import styles from './styles.module.css';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

type TF = Yup.TestFunction<string | number | undefined, AnyObject>;
const isValueMissing: TF = function (value) {
  const { category } = this.parent;
  const mustHaveValue = InfrastructureModel.isGatheringResource(category);
  if (!mustHaveValue) {
    return true;
  }
  if (value === undefined) return false;
  return '' + value !== '';
};

export const Infrastructure = () => {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const [selectedCategory, setSelectedCategory] = React.useState('');
  const [createsResource, setCreatesResource] = React.useState(InfrastructureModel.isGatheringResource(InfrastructureCategory.DUMPSTER));

  const infrastructureCategoryOptionsWithAll: GeneralFormSelectOption[] = InfrastructureCategorySelectOptionsFactory.getSelectOptionsWithAll(t);
  const infrastructureCategoryOptions: GeneralFormSelectOption[] = InfrastructureCategorySelectOptionsFactory.getSelectOptions(t);

  return (
    <Panel header={t('GlobalSettings.infrastructure.header')} borderType={'raised'}>
      <div className={styles['infrastructureCategorySelectorContainer']}>
        <GeneralForm
          width='30%'
          hideSubmitButton={true}
          hideCancelButton={true}
          fields={[
            {
              field: 'infrastructureCategory',
              label: t('Model.Infrastructure.category'),
              type: 'select',
              selectOptions: infrastructureCategoryOptionsWithAll,
              defaultValue: InfrastructureCategory.ALL,
            },
          ]}
          onChanged={(data) => {
            setCreatesResource(InfrastructureModel.isGatheringResource(data['infrastructureCategory']));
            if (data['infrastructureCategory'] === InfrastructureCategory.ALL) {
              setSelectedCategory('');
            } else {
              setSelectedCategory(data['infrastructureCategory']);
            }
          }}
        />
        <CheckboxLabel label={t('collectionMethod')} value={createsResource} type='toggle' flexEnd />
      </div>
      <Toolbar
        right={[
          <AddModelButton
            modelName={'infrastructures'}
            buttonLabel={t('Button.InfrastructureModel.add')}
            dialogTitle={t('Button.InfrastructureModel.add.dialog.title')}
            fields={[
              {
                field: 'category',
                label: t('Model.Infrastructure.category'),
                type: 'select',
                selectOptions: infrastructureCategoryOptions,
              },
              { field: 'name', label: t('resourceName'), type: 'text' },
              {
                field: 'volume',
                label: t('volumeM3'),
                type: 'numeric',
                numericPrecision: VOLUME_PRECISION,
                numericMinValue: VOLUME_MIN_VAL,
                numericMaxValue: VOLUME_MAX_VAL,
                isVisible: (data) => InfrastructureModel.isGatheringResource(data.category),
              },
              { field: 'comment', label: t('comment'), type: 'text' },
            ]}
            validationSchema={{
              category: Yup.string().required(t('Model.Infrastructure.category.required')),
              name: Yup.string().required(t('Model.Infrastructure.name.required')),
            }}
            postErrorMappings={{ '500': t('resourceExists') }}
            onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
          />,
        ]}
      />
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{
          modelName: 'infrastructures',
          modelClass: InfrastructureModel,
        }}
        fetchFilters={{ category: selectedCategory }}
        columns={[
          ...(selectedCategory === ''
            ? [
                {
                  key: 'category',
                  label: t('Model.Infrastructure.category'),
                  type: 'enum',
                  enumValues: infrastructureCategoryOptions,
                } as DataTableColumn,
              ]
            : []),
          {
            key: 'name',
            label: t('resourceName'),
            type: 'text',
          },
          ...(createsResource
            ? [
                {
                  key: 'volume',
                  label: t('volumeM3'),
                  type: 'numeric',
                  numericPrecision: VOLUME_PRECISION,
                } as DataTableColumn,
              ]
            : []),
          {
            key: 'comment',
            label: t('comment'),
            type: 'text',
          },
        ]}
        rowOptions={[
          {
            renderer: (row) => (
              <EditModelButton
                id={row.id}
                entity={row}
                modelName={'infrastructures'}
                buttonLabel={t('edit')}
                dialogTitle={t('Button.InfrastructureModel.edit.dialog.title')}
                fields={[
                  {
                    field: 'category',
                    label: t('Model.Infrastructure.category'),
                    type: 'select',
                    selectOptions: infrastructureCategoryOptions,
                    isVisible: () => false,
                  },
                  {
                    field: 'name',
                    label: t('resourceName'),
                    type: 'text',
                  },
                  {
                    field: 'volume',
                    label: t('volumeM3'),
                    type: 'numeric',
                    numericPrecision: VOLUME_PRECISION,
                    numericMinValue: VOLUME_MIN_VAL,
                    numericMaxValue: VOLUME_MAX_VAL,
                    isVisible: (data) => InfrastructureModel.isGatheringResource(data.category),
                  },
                  {
                    field: 'comment',
                    label: t('comment'),
                    type: 'text',
                  },
                ]}
                validationSchema={{
                  category: Yup.string().required(t('Model.Infrastructure.category.required')),
                  name: Yup.string().required(t('Model.Infrastructure.name.required')),
                  volume: Yup.string().test('is-mass-missing', t('requiredField'), isValueMissing),
                }}
                onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
                postErrorMappings={{ '500': t('resourceExists') }}
                yellowIcon
              />
            ),
          },
          {
            renderer: (row) => (
              <DeleteModelButton
                id={row.id}
                modelName={'infrastructures'}
                tooltip={t('GlobalSettings.infrastructure.tooltip.delete')}
                onDeleted={async () => setTableReloadKey(tableReloadKey + 1)}
              />
            ),
          },
        ]}
      />
    </Panel>
  );
};

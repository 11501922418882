import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import React from 'react';
import { CollapsedArea, LabelDiv, StyledTableBorder, TableAdaptiveArea } from '@shared/general';
import { Button, Panel, RenderIf } from '@lib/ui-components';
import { CanceledIcon, ForApprovalIcon, InvoicedIcon, SettledIcon, UnsettledIcon } from './icons';
import { CollapseIcon, ExpandIcon } from '@shared/service-table';
import {
  ServiceBar,
} from '../../projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-services/shared-components/service-bar';
import { InfrastructureServicesSettlementTableFooter } from './infrastructure-services-settlement-table-footer';
import { SettlementDetailsView } from '@lib/api-interface';

export interface InfrastructureServicesSettlementTableProps {
  settlement: SettlementDetailsView;
  reloadSettlements: () => void;
  showButtons?: boolean;
}

export function InfrastructureServicesSettlementTable({ settlement, reloadSettlements, showButtons=true }: InfrastructureServicesSettlementTableProps) {
  const { t } = useTranslation();
  const translations: any = i18n.getDataByLanguage(i18n.language);
  const [expandCollapseSwitch, setExpandCollapseSwitch] = React.useState(true);

  const regex = /(\d{4})-(\d{2})/g;
  const match = regex.exec(settlement.yearMonth!) || [null, null, null];
  const monthLabel = translations.common[`shortMonth.${match[2]}`];
  const dateLabel = `${monthLabel} ${match[1]}`;

  return (
    <StyledTableBorder data-settlement-id={settlement.settlementId} data-service-id={settlement.serviceId}>
      <Panel justifyContent='space-between' direction='row' onClick={() => setExpandCollapseSwitch((prevState) => !prevState)}>
        <LabelDiv>
          {settlement.settlementStatus === 'SETTLED' && <SettledIcon />}
          {settlement.settlementStatus === 'UNSETTLED' && <UnsettledIcon />}
          {settlement.settlementStatus === 'TO_BE_ACCEPTED' && <ForApprovalIcon />}
          {settlement.settlementStatus === 'INVOICED' && <InvoicedIcon />}
          {settlement.settlementStatus === 'CANCELLED' && <CanceledIcon />}
          {dateLabel}
        </LabelDiv>
        <Button
          label={expandCollapseSwitch ? t('Projects.ViewProject.expand') : t('Projects.ViewProject.collapse')}
          variant='text'
          endIcon={expandCollapseSwitch ? <ExpandIcon /> : <CollapseIcon />}
          onClick={() => setExpandCollapseSwitch((prevState) => !prevState)}
        />
      </Panel>
      <TableAdaptiveArea isDeactivated={settlement.settlementStatus === 'CANCELLED'}>
        <ServiceBar
          headers={[
            { label: t('projectNumber') },
            { label: t('clientName') },
            { label: t('branch') },
            { label: t('locality') },
            { label: t('street') },
            { label: t('serviceType') },
          ]}
          items={[
            { value: settlement.projectNumber, options: { bold: true } },
            { value: settlement.companyName },
            { value: settlement.branchName },
            { value: settlement.registrationAddress!.town },
            { value: settlement.registrationAddress!.street },
            { value: t('lease') },
          ]}
        />
        <ServiceBar
          headers={[
            { label: t('category') },
            { label: t('resourceName') },
            { label: t('description') },
            { label: t('owner') },
            { label: t('subcontractor') },
            { label: t('fixedAsset') },
            { label: t('number') },
            { label: t('clientPrice') },
            { label: t('subcontractorPrice') },
            { label: t('organizationAsSide') },
            { label: t('navResourceNumber') },
            { label: t('navResourceName') },
          ]}
          items={[
            {
              value: translations.common[`Model.Infrastructure.InfrastructureCategory.${settlement.infrastructureService!.category}`],
              options: { bold: true },
            },
            { value: settlement.infrastructureService!.name, options: { bold: true } },
            { value: settlement.infrastructureService!.description },
            { value: translations.common[settlement.infrastructureService!.owner!.toLowerCase()] },
            { value: settlement.infrastructureService!.subcontractorName || '-' },
            { value: settlement.settings.fixedAsset, type: 'boolean' },
            { value: settlement.infrastructureServiceQuantities },
            { value: settlement.settings.clientLease, options: { color: 'green' } },
            { value: settlement.settings.subcontractorLease || '-', options: { color: 'red' } },
            { value: settlement.infrastructureService!.organizationAsSide, type: 'boolean' },
            { value: settlement.infrastructureService!.navResourceNumber || '-' },
            { value: settlement.infrastructureService!.navResourceName || '-' },
          ]}
        />
        <CollapsedArea collapse={expandCollapseSwitch}></CollapsedArea>
        <RenderIf true={showButtons}>
          <InfrastructureServicesSettlementTableFooter settlement={settlement} t={t} reloadSettlements={reloadSettlements} />
        </RenderIf>
      </TableAdaptiveArea>
    </StyledTableBorder>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, DataTableColumn, RenderIf, ToggleButtons, Toolbar } from '@lib/ui-components';
import { FlexHorizontalContainer, FlexItem } from '../../../libs/ui-components/components/flex-horizontal-container/flex-horizontal-container';
import { CreateCompanyButton } from './create-company-button';

export interface CompaniesDataTableProps {
  roleClient?: boolean;
  roleSubcontractor?: boolean;
}

export const CompaniesDataTable = (props: CompaniesDataTableProps) => {
  const { t } = useTranslation();
  const [activePage, setActivePage] = React.useState(0);
  const [tableReloadKey, setTableReloadKey] = React.useState(1);

  const fetchFilters: any = React.useMemo(() => {
    return {
      active: activePage === 0,
      roleClient: props.roleClient ? true : undefined,
      roleContractor: props.roleSubcontractor ? true : undefined,
    };
  }, [activePage, props.roleClient, props.roleSubcontractor]);
  const columns: DataTableColumn[] = React.useMemo(() => {
    const columns: DataTableColumn[] = [
      {
        key: 'name',
        label: t('companyName'),
        type: 'text',
        minWidth: '15rem',
      },
      {
        key: 'vatin',
        label: t('vatin'),
        type: 'text',
      },
      {
        key: 'creditor',
        label: t('creditor'),
        type: 'text',
      },
      {
        key: 'debitor',
        label: t('debitor'),
        type: 'text',
      },
      {
        key: 'roleClient',
        label: t('client'),
        type: 'boolean',
        align: 'center',
      },
      {
        key: 'roleContractor',
        label: t('subcontractor'),
        type: 'boolean',
        align: 'center',
      },
      {
        key: 'registrationAddress.postalCode',
        label: t('postcode'),
        type: 'text',
      },
      {
        key: 'registrationAddress.town',
        label: t('locality'),
        type: 'text',
      },
      {
        key: 'registrationAddress.street',
        label: t('street'),
        type: 'text',
      },
      {
        key: 'registrationAddress.houseNum',
        label: t('houseNum'),
        type: 'text',
      },
      {
        key: 'registrationAddress.apartmentNum',
        label: t('apartmentNum'),
        type: 'text',
      },
      {
        key: 'registrationAddress.province',
        label: t('province'),
        type: 'text',
      },
      {
        key: 'organizationContact',
        label: t('Model.Company.address.keeper'),
        type: 'text',
      },
      {
        key: 'organizationContactEmail',
        label: t('email'),
        type: 'text',
      },
    ];
    if (props.roleSubcontractor) {
      columns.push({
        key: 'collectionDecisionNotRequired',
        label: t('collectionDecisionNotRequired'),
        type: 'boolean',
        align: 'center',
      });
    }
    return columns;
  }, [props.roleSubcontractor]);

  return (
    <>
      <FlexHorizontalContainer>
        <FlexItem position={'center'}>
          <ToggleButtons labels={[t('active'), t('inactive')]} activeIndex={activePage} onChange={(index) => setActivePage(index)} />
        </FlexItem>
        <FlexItem position={'right'}>
          <Toolbar
            right={[
              <RenderIf true={activePage === 0}>
                <CreateCompanyButton onSaved={async () => setTableReloadKey(tableReloadKey + 1)} />
              </RenderIf>,
            ]}
          />
        </FlexItem>
      </FlexHorizontalContainer>
      <DataTable refreshKey={tableReloadKey} fetchUrl={'/api/company-views'} fetchFilters={fetchFilters} rowLink={{ linkPath: '', linkRowId: 'companyId' }} columns={columns} />
    </>
  );
};

import { IsNotEmpty } from 'class-validator';
import { Expose } from 'class-transformer';
import { BaseModel } from '../../../common-sdk';

export class ProjectPersonModel extends BaseModel {
  @IsNotEmpty()
  @Expose()
  name: string | undefined;

  @IsNotEmpty()
  @Expose()
  invoicing: boolean | undefined = false;

  @IsNotEmpty()
  @Expose()
  salesperson: boolean | undefined = false;
}

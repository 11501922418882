import { IsNotEmpty } from 'class-validator';
import { Expose } from 'class-transformer';
import { CompanyAddress, SubcontractorTypesEnum } from './company.model';
import { BaseModel } from '@lib/common-sdk';

export class CompanyBranchViewModel extends BaseModel {
  @Expose()
  companyId?: string;

  @Expose()
  branchId?: string;

  @Expose()
  branchNestedNumber?: number;

  @Expose()
  active?: boolean;

  @Expose()
  name?: string;

  @Expose()
  shortName?: string;

  @Expose()
  companyName?: string;

  @Expose()
  vatin?: string;

  @Expose()
  correspondenceAddress?: CompanyAddress;

  @Expose()
  registrationAddress?: CompanyAddress;

  @Expose()
  central?: boolean = false;

  @Expose()
  roleClient?: boolean;

  @Expose()
  roleContractor?: boolean;

  @IsNotEmpty()
  @Expose()
  creationDate?: Date = new Date();

  @Expose()
  subcontractorTypes?: Array<SubcontractorTypesEnum>;

  @Expose()
  contractConclusionDate?: Date;

  @Expose()
  lastModifiedBy?: string;

  @Expose()
  lastModifiedDate?: string;

  @Expose()
  latitude?: number;

  @Expose()
  longitude?: number;

  @Expose()
  deactivationDate?: Date;
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddModelButton,
  DataTable,
  DeleteModelButton,
  GetContentButton,
  Panel,
  RenderIf,
  Toolbar,
} from '@lib/ui-components';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CompanyViewModel } from '@lib/api-interface';
import * as Yup from 'yup';

export interface ContractorDecisionsOnCollectingProps {
  company: CompanyViewModel;
  addNewDocumentButtonHidden?: boolean;
}

export function ContractorDecisionsOnCollecting(props: ContractorDecisionsOnCollectingProps) {
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const { t } = useTranslation();

  return (
    <Panel borderType='flat'>
      <RenderIf false={props.addNewDocumentButtonHidden}>
        <Toolbar
          right={[
            <AddModelButton
              multipart={true}
              buttonLabel={t('uploadFile')}
              dialogTitle={t('uploadFile')}
              modelName={'companyFileViews'}
              fields={[
                {
                  label: t('branch'),
                  type: 'table-select',
                  field: 'companyBranchId',
                  tableSelectParams: {
                    modelName: 'companyBranchViews',
                    dialogTitle: t('branch'),
                    displayFormat: '{name}',
                    selectedValueField: 'branchId',
                    columns: [{ key: 'name', label: t('Model.CompanyBranch.name'), type: 'text' }],
                    fetchFilters: { companyId: props.company.companyId },
                  },
                },
                {
                  label: t('expirationDate'),
                  type: 'date',
                  field: 'expirationDate',
                  isVisible: (data) => data['forUnlimitedPeriod'] == false,
                },
                { label: t('forUnlimitedPeriod'), type: 'boolean', field: 'forUnlimitedPeriod' },
                { label: t('dragAndDropAFile'), type: 'file', field: 'content' },
                { label: t('comment'), type: 'text', field: 'comment' },
              ]}
              validationSchema={{
                companyBranchId: Yup.string().required(t('fieldRequired')),
              }}
              hiddenValues={{ type: 'CONTRACTOR_DECISION_ON_COLLECTING', companyId: props.company.companyId }}
              onBeforeSave={(formData: any) => {
                const decisionOnCollectingData: any = {};
                decisionOnCollectingData.expirationDate = formData.expirationDate;
                decisionOnCollectingData.forUnlimitedPeriod = formData.forUnlimitedPeriod;
                if (decisionOnCollectingData.forUnlimitedPeriod === true) delete decisionOnCollectingData.expirationDate;

                formData.decisionOnCollectingDataString = JSON.stringify(decisionOnCollectingData);
                delete formData.expirationDate;
                return formData;
              }}
              onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
            />,
          ]}
        />
      </RenderIf>
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{ modelName: 'companyFileViews' }}
        fetchFilters={{ companyId: props.company.companyId, type: 'CONTRACTOR_DECISION_ON_COLLECTING' }}
        columns={[
          {
            key: 'fileName',
            label: t('decisionsOnCollecting'),
            type: 'text',
          },
          {
            key: 'companyBranch.name',
            label: t('Model.CompanyBranch.name'),
            type: 'text',
          },
          {
            key: 'companyBranch.registrationAddress.town',
            label: t('locality'),
            type: 'text',
          },
          {
            key: 'companyBranch.registrationAddress.street',
            label: t('street'),
            type: 'text',
          },
          {
            key: 'decisionOnCollectingData.expirationDate',
            label: t('expirationDate'),
            type: 'date',
          },
          {
            key: 'decisionOnCollectingData.forUnlimitedPeriod',
            label: t('forUnlimitedPeriod'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: 'creationDate',
            label: t('uploadDate'),
            type: 'date',
          },
          {
            key: 'createdBy',
            label: t('addedBy'),
            type: 'text',
          },
          {
            key: 'comment',
            label: t('comment'),
            type: 'text',
          },
        ]}
        rowOptions={[
          {
            renderer: (row) => <GetContentButton label={t('viewFile')} icon={<VisibilityIcon />} resourcePath={`api/resource/companyFileViews/${row.id}/content`} />,
          },
          {
            renderer: (row) => <DeleteModelButton modelName={'companyFileViews'} id={row.id} onDeleted={async () => setTableReloadKey(tableReloadKey + 1)} />,
          },
        ]}
      />
    </Panel>
  );
}

export enum AuthoritiesEnum {
  ORGANIZATION_ADMIN = 'ORGANIZATION_ADMIN',
  MANAGES_USERS = 'MANAGES_USERS',
  ASSIGNS_PROJECT_NUMBER = 'ASSIGNS_PROJECT_NUMBER',
  VIEWS_UNASSIGNED_PROJECTS = 'VIEWS_UNASSIGNED_PROJECTS',
  PROJECT_INVOICE = 'PROJECT_INVOICE',
  PROJECT_SALES = 'PROJECT_SALES',
  PROJECT_LEAD = 'PROJECT_LEAD',
  SEES_PRICES = 'SEES_PRICES',
  DECISION_CHANGE_ACCEPTANCE_NOT_REQUIRED = 'DECISION_CHANGE_ACCEPTANCE_NOT_REQUIRED',
}

import { Panel, RenderIf, TextLabel, Toolbar, YearMonthFilterBar, YearMonthFilterBarValues } from '@lib/ui-components';
import { StyledServiceDivider } from '@shared/general';
import { ServicesSettlementTable } from './services-settlement-table';
import { InfrastructureServicesSettlementTable } from './infrastructure-services-settlement-table';
import React from 'react';
import { ServiceComplaintsSettlementTable } from './service-complaints-settlement-table';
import { SettlementsFilter } from './settlements-filter';
import { TableFilterItem } from '../../../../libs/ui-components/components/data-table/data-table-filter/data-table-filter';
import { useInfiniteSettlementDetailsByFilters } from '@data-source-hooks';
import { SettlementDetailsView, SettlementViewType } from '@lib/api-interface';
import { ServicesSettlementExportExcelButton } from './services-settlement-export-excel-button';
import { InfiniteScrollRenderer } from '../../../../libs/ui-components/components/infinite-scroll-renderer';
import { ServicesSettlementExcelLogs } from './services-settlement-excel-logs';

export interface ServicesSettlementTabProps {
  settlementStatus?: string;
  countLabel?: string;
  subcontractorCompanyId?: string;
  transporterId?: string;
  showButtons?: boolean;
}

export const ServicesSettlementTab = ({
  settlementStatus,
  countLabel,
  subcontractorCompanyId,
  transporterId,
  showButtons = true,
}: ServicesSettlementTabProps) => {
  const now = new Date();
  const month = now.getMonth() + 1;
  const yearMonthNow = `${now.getFullYear()}-${month > 9 ? month : '0' + month}`;
  const maxValueOfDateFilter = new Date(now.getFullYear(), now.getMonth() + 2, now.getDate());

  const [activeFilters, setActiveFilters] = React.useState<YearMonthFilterBarValues>({
    date: yearMonthNow,
    filter: '',
  });
  const [tableFilters, setTableFilters] = React.useState<TableFilterItem[]>([]);
  const [query, reloadDataFunction] = useInfiniteSettlementDetailsByFilters(
    activeFilters.date,
    settlementStatus,
    tableFilters,
    subcontractorCompanyId,
    transporterId,
  );
  const totalSettlementsCount = query.data?.pages[0]?.totalItems;

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <div style={{ width: '50%', display: 'flex' }}>
          <YearMonthFilterBar onFilterChanged={(filters) => setActiveFilters(filters)} initialMonthYear={yearMonthNow} showSearch={false} maxYearMonth={maxValueOfDateFilter} />
        </div>
        <div style={{ width: '50%', display: 'flex', justifyContent: 'right' }}>
          <Panel contentMarginBottom='2rem' contentMarginRight='1rem'>
            <Toolbar right={[<TextLabel label={`${countLabel}:`} value={totalSettlementsCount?.toString() || '0'} labelColor='#828282' valueColor='#EB5757' direction='row' />]} />
          </Panel>
        </div>
      </div>
      <Toolbar
        left={[
          <SettlementsFilter
            onFiltersChange={(filters) => {
              const activeFilters: TableFilterItem[] = [];
              filters.forEach((filter) => {
                if (filter.value !== '') {
                  activeFilters.push(filter);
                }
              });
              setTableFilters(activeFilters);
            }}
          />,
        ]}
      />
      <InfiniteScrollRenderer
        query={query}
        headerRenderer={() => (
          <>
            <ServicesSettlementExcelLogs />
            <RenderIf true={totalSettlementsCount > 0}>
              <ServicesSettlementExportExcelButton
                date={activeFilters.date}
                settlementStatus={settlementStatus}
                tableFilters={tableFilters}
                totalSettlementsCount={totalSettlementsCount}
              />
            </RenderIf>
          </>
        )}
        rowRenderer={(settlement: SettlementDetailsView) => {
          return (
            <StyledServiceDivider key={settlement.id}>
              <RenderIf true={settlement.settlementType === SettlementViewType.SERVICE}>
                <ServicesSettlementTable settlement={settlement} reloadSettlements={reloadDataFunction} showButtons={showButtons} />
              </RenderIf>
              <RenderIf true={settlement.settlementType === SettlementViewType.INFRASTRUCTURE_SERVICE}>
                <InfrastructureServicesSettlementTable settlement={settlement} reloadSettlements={reloadDataFunction} showButtons={showButtons} />
              </RenderIf>
              <RenderIf true={settlement.settlementType === SettlementViewType.COMPLAINT}>
                <ServiceComplaintsSettlementTable settlement={settlement} reloadSettlements={reloadDataFunction} showButtons={showButtons} />
              </RenderIf>
            </StyledServiceDivider>
          );
        }}
      />
    </>
  );
};

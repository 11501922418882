import { SettlementDetailsView, SettlementViewType } from '@lib/api-interface';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useSettlementDetailsByFilters } from '@data-source-hooks';
import { TableFilterItem } from '../../../../libs/ui-components/components/data-table/data-table-filter/data-table-filter';
import axios from 'axios';
import { Button, ExportToExcelIcon, Snackbar } from '@lib/ui-components';

export interface ServicesSettlementExportExcelButtonProps {
  date: string;
  settlementStatus?: string;
  tableFilters: TableFilterItem[];
  totalSettlementsCount: number;
}

export const ServicesSettlementExportExcelButton = (props: ServicesSettlementExportExcelButtonProps) => {
  const { t } = useTranslation();
  const [settlements, setSettlements] = React.useState<SettlementDetailsView[]>([]);
  const [buttonClicked, setButtonClicked] = React.useState(false);
  const [dataFetched, setDataFetched] = React.useState(false);
  const [snackbarVisible, setSnackbarVisible] = React.useState(false);

  const priceTypeTranslations = {
    MG: t('priceType.MG'),
    M3: t('priceType.M3'),
    UNIT: t('priceType.UNIT'),
  };

  const bearerTranslations = {
    ORGANIZATION_COST: t('isCost'),
    ORGANIZATION_REVENUE: t('isRevenue'),
  };

  function getServiceSheet() {
    return {
      sheetName: t('service'),
      rows: settlements.filter((s) => s.settlementType === SettlementViewType.SERVICE),
      parameters: {
        columnDefinitions: [
          {
            key: 'settlementStatus',
            label: t('settlementStatus'),
            translations: {
              SETTLED: t('settled'),
              UNSETTLED: t('unsettled'),
              TO_BE_ACCEPTED: t('forApproval'),
              INVOICED: t('invoiced'),
              CANCELLED: t('canceled'),
            },
          },
          {
            key: 'projectNumber',
            label: t('projectNumber'),
          },
          { key: 'projectName', label: t('projectName') },
          { key: 'companyName', label: t('companyName') },
          { key: 'branchName', label: t('branchName') },
          { key: 'registrationAddress.town', label: t('town') },
          { key: 'registrationAddress.street', label: t('street') },
          {
            key: 'serviceView.serviceType',
            label: t('serviceType'),
            translations: {
              SCHEDULED: t('serviceType.SCHEDULED'),
              PER_REQUEST: t('serviceType.PER_REQUEST'),
              ADDITIONAL: t('serviceType.ADDITIONAL'),
              COMMUNE: t('serviceType.COMMUNE'),
            },
          },

          { key: 'settings.clientTransportPrice.price', label: `${t('clientTransport')} - ${t('price')}` },
          {
            key: 'settings.clientTransportPrice.priceType',
            label: `${t('clientTransport')} - ${t('priceType')}`,
            translations: priceTypeTranslations,
          },
          {
            key: 'settings.clientTransportPrice.currency',
            label: `${t('clientTransport')} - ${t('currency')}`,
          },
          {
            key: 'settings.clientTransportPrice.bearer',
            label: `${t('clientTransport')} - ${t('owner')}`,
            translations: bearerTranslations,
          },

          {
            key: 'settings.subcontractorTransportPrice.price',
            label: `${t('subcontractorTransport')} - ${t('price')}`,
          },
          {
            key: 'settings.subcontractorTransportPrice.priceType',
            label: `${t('subcontractorTransport')} - ${t('priceType')}`,
            translations: priceTypeTranslations,
          },
          {
            key: 'settings.subcontractorTransportPrice.currency',
            label: `${t('subcontractorTransport')} - ${t('currency')}`,
          },
          {
            key: 'settings.subcontractorTransportPrice.bearer',
            label: `${t('subcontractorTransport')} - ${t('owner')}`,
            translations: bearerTranslations,
          },

          {
            key: 'settings.clientCollectionPrice.price',
            label: `${t('clientCollection')} - ${t('price')}`,
          },
          {
            key: 'settings.clientCollectionPrice.priceType',
            label: `${t('clientCollection')} - ${t('priceType')}`,
            translations: priceTypeTranslations,
          },
          {
            key: 'settings.clientCollectionPrice.currency',
            label: `${t('clientCollection')} - ${t('currency')}`,
          },
          {
            key: 'settings.clientCollectionPrice.bearer',
            label: `${t('clientCollection')} - ${t('owner')}`,
            translations: bearerTranslations,
          },

          {
            key: 'settings.subcontractorCollectionPrice.price',
            label: `${t('subcontractorCollection')} - ${t('price')}`,
          },
          {
            key: 'settings.subcontractorCollectionPrice.priceType',
            label: `${t('subcontractorCollection')} - ${t('priceType')}`,
            translations: priceTypeTranslations,
          },
          {
            key: 'settings.subcontractorCollectionPrice.currency',
            label: `${t('subcontractorCollection')} - ${t('currency')}`,
          },
          {
            key: 'settings.subcontractorCollectionPrice.bearer',
            label: `${t('subcontractorCollection')} - ${t('owner')}`,
            translations: bearerTranslations,
          },

          { key: 'settings.pix', label: t('pix') },
          { key: 'pixData.monthlyPixRate', label: t('monthlyPixRate') },
          { key: 'pixData.monthlyEurPrice', label: t('monthlyEurPrice') },
          { key: 'pixData.fixedPixRate', label: t('fixedPixRate') },
          { key: 'pixPrice.price', label: `${t('pix')} - ${t('price')}` },
          { key: 'pixPrice.priceType', label: `${t('pix')} - ${t('priceType')}` },
          { key: 'pixPrice.currency', label: `${t('pix')} - ${t('currency')}` },
          { key: 'pixPrice.bearer', label: `${t('pix')} - ${t('owner')}` },

          { key: 'hasComplaint', label: t('hasComplaint') },
          { key: 'serviceView.projectWaste.waste.code', label: t('wasteCode') },
          { key: 'serviceView.projectWaste.waste.description', label: t('wasteDescription') },
          { key: 'serviceView.projectWaste.projectWasteDescription', label: t('wasteNameServiceDesc') },
          {
            key: 'serviceView.collectionInfrastructureService.name',
            label: `${t('projectInfrastructureService')} - ${t('name')}`,
          },
          { key: 'serviceView.collectionInfrastructureCount', label: t('containerAmount') },
          { key: 'serviceView.utilization', label: t('forUtilization') },
          { key: 'reportDate', label: t('reportDate') },
          { key: 'implementationDate', label: t('implementationDate') },
          { key: 'serviceView.subcontractor', label: t('settledSubcontractorName') },
          { key: 'serviceView.transporter', label: t('settledTransportEntity') },
          {
            key: 'serviceView.settlementType',
            label: t('settlementType'),
            translations: {
              PER_COLLECTION: t('settlementType.PER_COLLECTION'),
              PER_MONTH: t('settlementType.PER_MONTH'),
            },
          },
          { key: 'serviceView.logisticMinimum', label: t('logisticMinimum') },
          { key: 'serviceView.logisticMinimumSurcharge', label: t('logisticMinimumSurcharge') },
          {
            key: 'serviceView.paymentMethod',
            label: t('paymentMethodCode'),
            translations: {
              PREPAYMENT: t('paymentMethodCode.PREPAYMENT'),
              TRANSFER: t('paymentMethodCode.TRANSFER'),
            },
          },
          { key: 'serviceView.separateInvoice', label: t('separatedInvoice') },
          {
            key: 'serviceView.invoicingMethod',
            label: t('invoicingMethod'),
            translations: {
              PER_COLLECTION: t('settlementType.PER_COLLECTION'),
              PER_MONTH: t('settlementType.PER_MONTH'),
            },
          },
          {
            key: 'serviceView.settlementUnit',
            label: t('settlementUnit'),
            translations: {
              MG: t('settlementUnit.MG'),
              UNIT: t('settlementUnit.UNIT'),
              M3: t('settlementUnit.M3'),
            },
          },
          { key: 'serviceView.constantCost', label: t('constantCost') },
          { key: 'settings.organizationAsSide', label: t('organizationAsSide') },
          { key: 'serviceView.countryImport', label: t('countryImport') },
          { key: 'serviceView.countryExport', label: t('countryExport') },
          { key: 'estimatedMass', label: t('estimatedMass') },
          { key: 'reportedMass', label: t('reportedWeight') },
          { key: 'receivedMass', label: t('receivedMass') },
          { key: 'estimatedQuantities', label: t('estimatedQuantities') },
          { key: 'reportedQuantities', label: t('reportedQuantities') },
          { key: 'receivedQuantities', label: t('quantityReceived') },
          { key: 'receivedVolume', label: t('volumeReceived') },
          { key: 'completedTransports', label: t('transportPlan') },
          { key: 'reportedTransports', label: t('transportCompleted') },
          { key: 'settlementId', label: 'settlementId', hidden: true },
        ],
      },
    };
  }

  function getInfrastructureServiceSheet() {
    return {
      sheetName: t('projectInfrastructureService'),
      rows: settlements.filter((s) => s.settlementType === SettlementViewType.INFRASTRUCTURE_SERVICE),
      parameters: {
        columnDefinitions: [
          {
            key: 'settlementStatus',
            label: t('settlementStatus'),
            translations: {
              SETTLED: t('settled'),
              UNSETTLED: t('unsettled'),
              TO_BE_ACCEPTED: t('forApproval'),
              INVOICED: t('invoiced'),
              CANCELLED: t('canceled'),
            },
          },
          {
            key: 'projectNumber',
            label: t('projectNumber'),
          },
          { key: 'projectName', label: t('projectName') },
          { key: 'companyName', label: t('companyName') },
          { key: 'branchName', label: t('branchName') },
          { key: 'registrationAddress.town', label: t('town') },
          { key: 'registrationAddress.street', label: t('street') },
          {
            key: 'infrastructureService.category',
            label: t('category'),
            translations: {
              DUMPSTER: t('Model.Infrastructure.InfrastructureCategory.DUMPSTER'),
              ROLL_OFF_CONTAINER: t('Model.Infrastructure.InfrastructureCategory.ROLL_OFF_CONTAINER'),
              BARREL: t('Model.Infrastructure.InfrastructureCategory.BARREL'),
              BALER: t('Model.Infrastructure.InfrastructureCategory.BALER'),
              STATIONARY_PRESS: t('Model.Infrastructure.InfrastructureCategory.STATIONARY_PRESS'),
              BIG_BAG: t('Model.Infrastructure.InfrastructureCategory.BIG_BAG'),
              PRESS_CONTAINER: t('Model.Infrastructure.InfrastructureCategory.PRESS_CONTAINER'),
              PALLET: t('Model.Infrastructure.InfrastructureCategory.PALLET'),
              BAG: t('Model.Infrastructure.InfrastructureCategory.BAG'),
              LOOSE: t('Model.Infrastructure.InfrastructureCategory.LOOSE'),
              IBC: t('Model.Infrastructure.InfrastructureCategory.IBC'),
              TANK: t('Model.Infrastructure.InfrastructureCategory.TANK'),
              BIN: t('Model.Infrastructure.InfrastructureCategory.BIN'),
              GITTERBOX: t('Model.Infrastructure.InfrastructureCategory.GITTERBOX'),
              PALOXE: t('Model.Infrastructure.InfrastructureCategory.PALOXE'),
              ASP: t('Model.Infrastructure.InfrastructureCategory.ASP'),
              ALL: t('Model.Infrastructure.InfrastructureCategory.ALL'),
            },
          },
          { key: 'infrastructureService.name', label: t('name') },
          { key: 'infrastructureService.description', label: t('description') },
          {
            key: 'infrastructureService.owner',
            label: t('owner'),
            translations: {
              CLIENT: t('client'),
              SUBCONTRACTOR: t('subcontractor'),
              ORGANIZATION: t('organization'),
            },
          },
          { key: 'infrastructureService.subcontractorName', label: t('subcontractorsName') },
          { key: 'settings.fixedAsset', label: t('fixedAsset') },
          { key: 'infrastructureServiceQuantities', label: t('containerAmount') },
          { key: 'settings.clientLease', label: t('clientLease') },
          { key: 'settings.subcontractorLease', label: t('subcontractorLease') },
          { key: 'infrastructureService.navResourceNumber', label: t('navResourceNumber') },
          { key: 'infrastructureService.navResourceName', label: t('navResourceName') },
          { key: 'settlementId', label: 'settlementId', hidden: true },
        ],
      },
    };
  }

  function getData() {
    return {
      sheets: [getServiceSheet(), getInfrastructureServiceSheet()],
    };
  }

  const fetchData = async () => {
    setSnackbarVisible(true);
    try {
      const settlementDetails = await useSettlementDetailsByFilters(props.date, props.settlementStatus, props.tableFilters, props.totalSettlementsCount);
      setSettlements(settlementDetails);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  React.useEffect(() => {
    const fetchDataAndSetDataFetched = async () => {
      if (buttonClicked) {
        await fetchData();
        setDataFetched(true);
      }
    };

    fetchDataAndSetDataFetched();
  }, [buttonClicked]);

  React.useEffect(() => {
    async function handleClick() {
      try {
        const response = await axios({
          method: 'post',
          data: getData(),
          url: '/api/excel',
          responseType: 'blob',
        });

        const contentDispositionHeader = response.headers['content-disposition'];
        let fileName = '';

        if (contentDispositionHeader) {
          const fileNameMatch = contentDispositionHeader.match(/filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/);
          if (fileNameMatch && fileNameMatch.length > 1) {
            fileName = decodeURIComponent(fileNameMatch[1]);
          }
        }

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error exporting to Excel:', error);
      }
    }

    if (dataFetched) {
      handleClick();
      setDataFetched(false);
      setButtonClicked(false);
    }
  }, [dataFetched]);

  async function sendExportRequest() {
    setSnackbarVisible(true);
    const params = {
      yearMonth: props.date,
      settlementStatus: props.settlementStatus,
    };
    props.tableFilters?.forEach((value) => Object.assign(params, { [value.column.key]: value.value }));
    const response = await axios({
      method: 'post',
      url: '/api/settlement/export',
      responseType: 'json',
      params: params,
    });

    if (response.status !== 200) {
      throw new Error('Export failed');
    }
  }

  return (
    <>
      {snackbarVisible && <Snackbar message={t('settlementExportStarted')} severity='info' onClose={() => setSnackbarVisible(false)} />}
      <Button label={t('exportToExcel')} startIcon={<ExportToExcelIcon />} onClick={async () => sendExportRequest()} />
    </>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, Panel, RenderIf, ToggleButtons, Toolbar } from '@lib/ui-components';
import { ProjectType } from '@lib/api-interface';
import { AuthoritiesEnum } from '../../../domain/enums/authorities.enum';
import { useUserState } from '../../../state/UserState';
import { FlexHorizontalContainer, FlexItem } from '../../../libs/ui-components/components/flex-horizontal-container/flex-horizontal-container';
import { CreateProjectButton } from './create-project-button';

export const Projects = () => {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const userState = useUserState();
  const canCreateProjects = userState.authorities.findIndex((value) => value === AuthoritiesEnum.ORGANIZATION_ADMIN) >= 0;
  const [activePage, setActivePage] = React.useState(0);

  return (
    <Panel header={t('Projects.header')} borderType='raised'>
      <FlexHorizontalContainer>
        <FlexItem position='center'>
          <ToggleButtons labels={[t('active'), t('inactive')]} activeIndex={activePage} onChange={(index) => setActivePage(index)} />
        </FlexItem>
        <FlexItem position='right'>
          {' '}
          <RenderIf true={canCreateProjects && activePage === 0}>
            <Toolbar right={[<CreateProjectButton onSaved={async () => setTableReloadKey(tableReloadKey + 1)} />]} />
          </RenderIf>
        </FlexItem>
      </FlexHorizontalContainer>
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{ modelName: 'projectViews' }}
        rowLink={{ linkPath: '', linkRowId: 'projectId' }}
        fetchFilters={{ active: activePage === 0 }}
        columns={[
          {
            key: 'projectNumber',
            label: t('projectNum'),
            type: 'text-chip',
          },
          {
            key: 'name',
            label: t('name'),
            type: 'text',
          },
          {
            key: 'vatin',
            label: t('vatin'),
            type: 'text',
          },
          {
            key: 'type',
            label: t('projectType'),
            type: 'enum',
            enumValues: [
              { value: ProjectType.TRADING, label: t('Model.Project.type.TRADING') },
              { value: ProjectType.ONE_TIME, label: t('Model.Project.type.ONE_TIME') },
              { value: ProjectType.OTHER, label: t('Model.Project.type.OTHER') },
            ],
          },
          {
            key: 'mainCompanyBranchName',
            label: t('Model.Project.mainCompanyName'),
            type: 'text',
          },
          {
            key: 'sellerPerson',
            label: t('salesperson'),
            type: 'text',
          },
          {
            key: 'projectLeadPerson',
            label: t('projectLeadPerson'),
            type: 'text',
          },
          {
            key: 'invoicePerson',
            label: t('invoicingPerson'),
            type: 'text',
          },
          {
            key: 'contractConclusionDate',
            label: t('contractConclusionDate'),
            type: 'date',
          },
        ]}
      />
    </Panel>
  );
};

import { Box, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { Panel } from '@lib/ui-components';
import React from 'react';
import { StyledBooleanTrueSign } from '../../../../../../../../../libs/ui-components/components/data-table/StyledBooleanTrueSign';

export interface ServiceBarCellOptions {
  colSpan?: number;
  color?: 'green' | 'red';
  bold?: boolean;
}

export interface ServiceBarItem {
  value?: string | number | boolean | JSX.Element;
  type?: 'text' | 'boolean';
  options?: ServiceBarCellOptions;
  tooltip?: string;
}

export interface ServiceBarHeader {
  label?: string | number;
  options?: ServiceBarCellOptions;
}

export interface ServiceBarProps {
  headers: ServiceBarHeader[];
  items: ServiceBarItem[][] | ServiceBarItem[];
  isVisible?: boolean;
}

export const ServiceBar = (props: ServiceBarProps) => {
  if (props.isVisible === false) {
    return null;
  }

  const toItems = (items: any): ServiceBarItem[][] => {
    return Array.isArray(items[0]) ? items : [items];
  };

  function mapValue(item: ServiceBarItem) {
    if (item.type === 'boolean' && item.value === true) {
      return <StyledBooleanTrueSign />;
    }
    return item.value;
  }

  function styleFromOptions(options?: ServiceBarCellOptions) {
    const result: any = {};
    if (options == null) {
      return result;
    }
    if (options.color === 'green') {
      result.color = '#0AAD50';
    } else if (options.color === 'red') {
      result.color = '#EB5757';
    }
    if (options.bold) {
      result['font-weight'] = 'bold';
    }
    return result;
  }

  const cellBorderStyle = '1px solid #E8E8E8';

  const bodyCellStyle = {
    border: 0,
    borderBottom: cellBorderStyle,
    borderRight: cellBorderStyle,
    '&:first-child': {
      borderTopLeftRadius: '8px',
    },
    '&:last-child': {
      borderRight: 0,
      borderTopRightRadius: '8px',
    },
  };

  const headerCellStyle = {
    ...bodyCellStyle,
    backgroundColor: '#F9F9FA',
  };

  return (
    <Panel>
      <Box sx={{ border: cellBorderStyle, borderRadius: '8px' }}>
        <Table size={'small'}>
          <TableHead>
            <TableRow>
              {React.Children.toArray(
                props.headers.map((header) => (
                  <TableCell key={header.label} sx={{ ...headerCellStyle, ...styleFromOptions(header.options) }} colSpan={header.options?.colSpan}>
                    {header.label}
                  </TableCell>
                )),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {toItems(props.items).map((items: ServiceBarItem[], index) => (
              <TableRow key={index}>
                {items.map((item: ServiceBarItem, itemIndex) => (
                  <Tooltip key={`${index}-${itemIndex}-tooltip`} title={item.tooltip || ''} arrow placement='top'>
                    <TableCell key={`${index}-${itemIndex}`} sx={{ ...bodyCellStyle, ...styleFromOptions(item.options) }} colSpan={item.options?.colSpan}>
                      {mapValue(item)}
                    </TableCell>
                  </Tooltip>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Panel>
  );
};

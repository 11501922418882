import React from 'react';
import { Panel, RenderIf, TextLabel } from '@lib/ui-components';
import { StyledTableBorder, TableAdaptiveArea } from '@shared/general';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { ServiceBar } from '../../../../organization/projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-services/shared-components/service-bar';
import { Stack } from '@mui/material';
import { priceItem } from '../../../../organization/projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-services/shared-components/shared';
import { ServiceStatusLabel } from '../../../../organization/projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-services/shared-components/service-status-label';

export interface AdditionalTableProps {
  serviceViewDetails: any;
  reloadServices: () => void;
  showButtons?: boolean;
}

export function AdditionalTable(props: AdditionalTableProps) {
  const { t } = useTranslation();
  const translations: any = i18n.getDataByLanguage(i18n.language);
  const service = props.serviceViewDetails;

  return (
    <StyledTableBorder>
      <Panel justifyContent='space-between' direction='row'>
        <ServiceStatusLabel active={service.active} acceptanceState={service.acceptanceState} started={service.started} />
      </Panel>
      <TableAdaptiveArea isDeactivated={!service.active || service.acceptanceState === 'INITIALIZED'}>
        <ServiceBar
          headers={[{ label: t('navResourceName') }, { label: t('serviceDesc') }, { label: t('activationDate') }, { label: t('deactivationDate') }]}
          items={[
            { value: service.navResourceName },
            { value: service.description, options: { bold: true } },
            { value: service.activationDate },
            { value: service.deactivationDate },
          ]}
        />
        <ServiceBar
          headers={[{ label: t('settledSubcontractorName') }, { label: t('reactionTime') }, { label: t('collectionPrice') }]}
          items={[
            { value: service.currentSettings?.organizationAsSide ? 'Interzero Austria' : service.subcontractor },
            { value: service.reactionTime },
            priceItem(service.currentSettings.clientCollectionPrice, {
              priceTypeVisible: true,
              translations,
              reverseColors: true,
            }),
          ]}
        />
        <Stack direction={'row'} marginLeft={2} marginBottom={2} spacing={6}>
          <RenderIf true={service.acceptanceState === 'ACCEPTED'}>
            <TextLabel value={`${t('acceptanceDate')}: ${service.acceptanceDate}`} />
          </RenderIf>
        </Stack>
      </TableAdaptiveArea>
    </StyledTableBorder>
  );
}

import { IsNotEmpty } from 'class-validator';
import { Expose } from 'class-transformer';
import { BaseModel } from '@lib/common-sdk';

export enum InfrastructureCategory {
  DUMPSTER = 'DUMPSTER',
  ROLL_OFF_CONTAINER = 'ROLL_OFF_CONTAINER',
  BARREL = 'BARREL',
  BALER = 'BALER',
  STATIONARY_PRESS = 'STATIONARY_PRESS',
  BIG_BAG = 'BIG_BAG',
  PRESS_CONTAINER = 'PRESS_CONTAINER',
  PALLET = 'PALLET',
  BAG = 'BAG',
  LOOSE = 'LOOSE',
  IBC = 'IBC',
  TANK = 'TANK',
  BIN = 'BIN',
  GITTERBOX = 'GITTERBOX',
  PALOXE = 'PALOXE',
  ASP = 'ASP',
  ALL = 'ALL',
}

export class InfrastructureModel extends BaseModel {
  @IsNotEmpty()
  @Expose()
  category: InfrastructureCategory | undefined;

  @Expose()
  mass?: number; // in kg

  @Expose()
  volume?: number; // in cm2

  @IsNotEmpty()
  @Expose()
  name: string | undefined;

  @Expose()
  comment?: string;

  get baler(): boolean {
    return this.category === InfrastructureCategory.BALER;
  }

  get createsResource(): boolean {
    return InfrastructureModel.isGatheringResource(this.category);
  }

  static isGatheringResource(category: InfrastructureCategory | undefined) {
    return category !== InfrastructureCategory.BALER && category !== InfrastructureCategory.STATIONARY_PRESS && category !== InfrastructureCategory.LOOSE;
  }

  static isBaller(category: InfrastructureCategory | undefined) {
    return category === InfrastructureCategory.BALER;
  }

  static isPress(category: InfrastructureCategory | undefined) {
    return category === InfrastructureCategory.STATIONARY_PRESS;
  }
}

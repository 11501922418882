import React, { PropsWithChildren } from 'react';
import { DialogContent, DialogTitle, IconButton, InputAdornment, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormInputProps } from './form-input-props';
import { DataTable, DataTableColumn } from '../data-table/data-table';
import { ClassConstructor } from 'class-transformer';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '../button/button';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { HateoasRestApiClientService } from '@lib/common-sdk';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Dialog } from '../dialog/dialog';

export interface FormInputTableSelectProps extends FormInputProps {
  initialId?: string | number | undefined;
  displayFormat: string; // ex '{firstName} {lastName}'
  dialogTitle: string;

  modelName: string;
  modelClass?: ClassConstructor<any>;
  search?: string;

  fetchFilters?: any;
  columns: DataTableColumn[];
  selectedValueField?: string; // _id by default
  removeHateoasPathFromResult?: boolean; // false by default
  onRowSelected: (row: any) => void;
  onRowClear?: () => void;
  getFormValues?: () => any;
  initialDisplayValue?: string;
}

export const FormInputTableSelect: React.FC<FormInputTableSelectProps> = (props: PropsWithChildren<FormInputTableSelectProps>) => {
  const [openDialog, setOpenDialog] = useStateIfMounted(false);
  const [displayedValue, setDisplayedValue] = useStateIfMounted(props.initialDisplayValue || '');
  const [selectedValue, setSelectedValue] = useStateIfMounted<string | null>('');
  const { t } = useTranslation();

  function computeFetchFilters() {
    const filters = props.fetchFilters != undefined ? { ...props.fetchFilters } : undefined;
    if (props.getFormValues != null && props.fetchFilters != null) {
      const formValues = props.getFormValues();
      Object.keys(props.fetchFilters).forEach((key) => {
        const paramVal = props.fetchFilters[key];
        if (typeof paramVal === 'string' && paramVal.startsWith('$')) {
          filters[key] = formValues[paramVal.substring(1)];
        } else {
          filters[key] = paramVal;
        }
      });
    }
    return filters;
  }

  const computedFetchFilters = computeFetchFilters();

  const handleSelect = React.useCallback(
    async (row: any, onChange: (...event: any[]) => void, isSetValue?: boolean) => {
      const idFieldName = 'id';
      let selectedValue;
      if (props.selectedValueField != null) {
        selectedValue = row[props.selectedValueField];
      } else {
        if (props.removeHateoasPathFromResult === true) {
          selectedValue = row[idFieldName];
        } else {
          selectedValue = `${process.env['REACT_APP_RESOURCES_URL']}/${props.modelName}/${row[idFieldName]}`;
        }
      }
      props.onRowSelected(row);
      setDisplayedValue(formatDisplay(props.displayFormat, row));
      setSelectedValue(selectedValue);
      if (isSetValue === true) {
        onChange(props.name, selectedValue);
      } else {
        onChange(selectedValue);
      }
      setOpenDialog(false);
    },
    [props.displayFormat, props.modelName, props.selectedValueField, setDisplayedValue, setOpenDialog, setSelectedValue],
  );

  React.useEffect(() => {
    if (props.initialId != null) {
      if (props.selectedValueField != null && props.selectedValueField !== '_id' && props.selectedValueField !== 'id') {
        const filters: any = {};
        const idFieldName = 'id';
        filters[props.selectedValueField != null ? props.selectedValueField : idFieldName] = props.initialId;
        HateoasRestApiClientService.findAll<any>(props.modelName, filters, undefined, {
          modelClass: props.modelClass,
          search: props.search,
        }).then((results) => {
          if (!results.failed) {
            if (results.response && results.response.length > 0) {
              handleSelect(results.response[0], props.setValue, true);
            }
          }
        });
      } else {
        HateoasRestApiClientService.findOne<any>(props.modelName, `${props.initialId}`, {
          modelClass: props.modelClass,
        }).then((results) => {
          if (!results.failed && results.response != null) {
            handleSelect(results.response, props.setValue, true);
          }
        });
      }
    }
  }, [handleSelect, props.initialId, props.modelClass, props.modelName, props.selectedValueField, props.setValue, props.search]);

  function formatDisplay(text: string, args: any) {
    return text.replace(/\{([^}]+)\}/g, (match, path) => {
      const properties = path.split('.');
      let value = args;
      for (const prop of properties) {
        if (value === undefined || value === null) {
          return match;
        }
        value = value[prop];
      }
      return value !== undefined ? value : match;
    });
  }

  async function handleCloseDialog() {
    setOpenDialog(false);
  }

  async function handleInputClick(onChange: (...event: any[]) => void) {
    if (displayedValue !== '') {
      props.onRowSelected(null);
      setDisplayedValue('');
      onChange('');
      setSelectedValue(null);
    } else {
      setOpenDialog(true);
    }
  }

  function processValueToDisplay(displayedValue: string, value: any) {
    if (value === '' && selectedValue !== value) {
      props.onRowSelected(null);
      setDisplayedValue('');
      setSelectedValue(null);
    } else if (value !== '' && selectedValue !== value) {
      setSelectedValue(value);
    }
    return displayedValue;
  }

  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
        <>
          <div data-test-id={`input-${props.name}-wrapper`}>
            <TextField
              inputProps={{ 'data-test-id': `input-${props.name}` }}
              helperText={error ? error.message : ' '}
              error={!!error}
              size='small'
              value={processValueToDisplay(displayedValue, value)}
              onChange={(event) => {
                onChange(selectedValue);
              }}
              onClick={(event) => setOpenDialog(true)}
              onKeyDown={(event) => event.preventDefault()}
              fullWidth
              label={props.label}
              variant={props.variant || 'outlined'}
              margin='normal'
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton data-test-id={`input-${props.name}-clear-btn`} onClick={() => handleInputClick(onChange)}>
                      {displayedValue !== '' ? <ClearIcon /> : <SearchIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                caretColor: 'transparent',
                cursor: 'pointer',
              }}
              disabled={props.isDeactivated}
            />
          </div>
          <Dialog open={openDialog} onClose={() => handleCloseDialog()} maxWidth='xl' fullWidth={true}>
            <DialogTitle>
              {props.dialogTitle}
              <IconButton
                data-test-id={'dialog-close-btn'}
                aria-label='close'
                onClick={handleCloseDialog}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DataTable
                refreshKey={1}
                columns={props.columns}
                modelDef={{
                  modelName: props.modelName,
                  modelClass: props.modelClass,
                  search: props.search,
                }}
                fetchFilters={computedFetchFilters}
                rowOptions={[
                  {
                    renderer: (row) => <Button label={t('Common.select')} color='primary' variant='outlined' onClick={async () => handleSelect(row, onChange)} />,
                  },
                ]}
              />
            </DialogContent>
          </Dialog>
        </>
      )}
    />
  );
};

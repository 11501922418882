import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from '@lib/ui-components';
import {
  ResetAccountPasswordButton,
} from '../../../../components/reset-account-password-button/reset-account-password-button';

interface ProjectBranchDataUsersProps {
  branchId: string;
  projectId: string;
}

export const ProjectBranchDataUsers = (props: ProjectBranchDataUsersProps) => {
  const { t } = useTranslation();

  return (
    <DataTable
      refreshKey={1}
      fetchUrl={`api/projects/${props.projectId}/branches/${props.branchId}/accounts`}
      columns={[
        {
          key: 'login',
          label: t('login'),
          type: 'text',
        },
        {
          key: 'name',
          label: t('firstName'),
          type: 'text',
        },
        {
          key: 'tags.phone',
          label: t('phone'),
          type: 'text',
        },
        {
          key: 'email',
          label: t('email'),
          type: 'text',
        },
      ]}
      rowOptions={[
        {
          renderer: (row) => <ResetAccountPasswordButton login={row.accountLogin} />,
        },
      ]}
    />
  );
};

import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Button, ButtonColor, ButtonFormDialog, GeneralFormItem, GeneralFormRowOfItems, RenderIf, Snackbar } from '@lib/ui-components';
import React, { useState } from 'react';
import { Breakpoint } from '@mui/system';
import axios from 'axios';
import { JsonUtils } from '@lib/common-sdk';
import { getErrorMessage } from '../../custom-post-error.handler';

export interface UsecaseButtonProps {
  dataTestId?: string;
  color?: ButtonColor;
  variant?: 'text' | 'outlined' | 'contained';
  onBeforeSave?: (formData: any) => any;
  onSaved: (result: any) => Promise<any>;
  method?: 'POST' | 'GET' | 'DELETE' | 'PUT' | 'PATCH';
  forceConfirmation?: boolean;
  confirmationMsg?: string;
  groupName?: string;
  useCaseName: string;
  validationSchema?: any;
  buttonLabel?: string;
  tooltip?: string;
  dialogTitle?: string;
  fields?: (GeneralFormItem | GeneralFormRowOfItems)[];
  hiddenValues?: any;
  bottomMessage?: string;
  postErrorMappings?: Record<string, string>;
  multipart?: boolean;
  startIcon?: JSX.Element;
  icon?: JSX.Element;
  dialogIcon?: JSX.Element;
  maxWidth?: Breakpoint | false;
  isDisabled?: boolean;
  onError?: (errorText: any) => void;
}

export const UsecaseButton = (props: UsecaseButtonProps) => {
  const { t } = useTranslation(); // needed to react to language change (even if not used directly)
  const translations: any = i18n.getDataByLanguage(i18n.language);
  const [failureMessage, setFailureMessage] = useStateIfMounted('');
  const [infoMessage, setInfoMessage] = useStateIfMounted('');
  const [openDialog, setDialog] = useStateIfMounted(false);
  const [loading, setLoading] = useState(false);

  async function handleButtonClick() {
    if (props.fields) {
      setDialog(true);
    } else {
      handleSaveDialog({});
    }
  }

  function convertToKebabCaseRemoveUseCaseIfExists(input: string): string {
    let convertedString = input
      .replace(/([a-z])([A-Z])/g, '$1-$2') // Convert camelCase to kebab-case
      .replace(/([A-Z])([A-Z])/g, '$1-$2') // Convert acronyms to kebab-case
      .toLowerCase(); // Convert all characters to lowercase

    if (convertedString.endsWith('-use-case')) {
      convertedString = convertedString.slice(0, convertedString.length - '-use-case'.length);
    }

    return convertedString;
  }

  async function handleSaveDialog(formData: any) {
    try {
      setLoading(true);
      if (props.onBeforeSave) {
        formData = props.onBeforeSave(formData);
      }
      const results = await axios.request({
        method: props.method || 'POST',
        url: `${process.env['REACT_APP_USECASE_URL']}${props.groupName ? `/${props.groupName}` : ''}/${convertToKebabCaseRemoveUseCaseIfExists(props.useCaseName)}`,
        data: { ...props.hiddenValues, ...formData },
        headers: {
          'Content-Type': props?.multipart ? 'multipart/form-data' : 'application/json',
        },
      });
      setLoading(false);
      if (results.status < 400) {
        setDialog(false);
        setTimeout(() => props.onSaved(results.data), 0);
        if (results.status === 204) {
          setInfoMessage(t('Common.noData'));
        }
      } else {
        if (props.postErrorMappings !== undefined) {
          const errorMessage = props.postErrorMappings[`${results.status}`];
          if (errorMessage !== undefined) {
            setFailureMessage(errorMessage);
            return;
          }
        }
        const errorPayload = JSON.parse(results.data);
        const errorText = getErrorMessage(errorPayload, translations) || t('Common.error');
        if (props.onError) {
          props.onError(errorText);
        } else {
          setFailureMessage(errorText);
        }
      }
    } catch (e: any) {
      setLoading(false);
      const status = e.response?.status;
      const data = e.response?.data || '{ "code": "E00001"}';
      const code = JsonUtils.extractValue(data, 'code');
      const context = JsonUtils.extractValue(data, 'context');
      if (props.postErrorMappings !== undefined) {
        const errorMessage = props.postErrorMappings[`${status}`];
        if (errorMessage !== undefined) {
          if (props.onError) {
            props.onError(errorMessage);
            return;
          }
          setFailureMessage(errorMessage);
          return;
        }
      }
      const errorText = getErrorMessage({ code, context }, translations) || t('Common.error');
      if (props.onError) {
        props.onError(errorText);
      } else {
        setFailureMessage(errorText);
      }
    }
  }

  return (
    <>
      <Button
        dataTestId={props.dataTestId || `${props.groupName}-${props.useCaseName}-btn`}
        label={props.buttonLabel}
        tooltip={props.tooltip}
        onClick={handleButtonClick}
        variant={props.variant}
        color={props.color}
        startIcon={props.startIcon}
        icon={props.icon}
        forceConfirmation={props.forceConfirmation}
        confirmationMsg={props.confirmationMsg}
        isDisabled={props.isDisabled}
        forceLoadingSign={loading}
      />
      <ButtonFormDialog
        openDialog={openDialog}
        setDialog={setDialog}
        handleSaveDialog={handleSaveDialog}
        dialogTitle={props.dialogTitle}
        fields={props.fields}
        validationSchema={props.validationSchema}
        hiddenValues={props.hiddenValues}
        dialogIcon={props.dialogIcon}
        maxWidth={props.maxWidth}
        bottomMessage={props.bottomMessage}
      />
      <RenderIf true={failureMessage !== undefined && failureMessage !== ''}>
        <Snackbar message={failureMessage} severity='error' onClose={() => setFailureMessage('')} />
      </RenderIf>
      <RenderIf true={infoMessage !== undefined && infoMessage !== ''}>
        <Snackbar message={infoMessage} severity='warning' onClose={() => setInfoMessage('')} />
      </RenderIf>
    </>
  );
};

import { BreadcrumbDataQuery } from '@lib/ui-components';
import {
  ReactQueryHateoasClientService,
} from '../libs/common-sdk/hateoas-rest-api-client/react-query-hateoas-client.service';

export const CompanyBreadcrumbProvider: BreadcrumbDataQuery = {
  fetchFunction(id: string): () => Promise<any> {
    return function () {
      return ReactQueryHateoasClientService.findOneByAggregateId<any>('companyViews', 'companyId', id);
    };
  },

  mapToBreadcrumb(data: any): string {
    return data.name;
  },

  query: 'companyViews',
};

import * as React from 'react';
import { useTheme } from '@mui/material';
import styles from './styles.module.css';
import { AppBarOptions } from './app-bar-options/app-bar-options';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Breadcrumb } from './breadcrumb/breadcrumb';

export const AppBar = () => {
    const breadcrumbs = useBreadcrumbs();
    const theme = useTheme();
    return (
        <div
            className={styles['container']}
            style={{
                backgroundColor: theme.palette.primary.main
            }}
        >
            <div>
                {breadcrumbs.map(({match, breadcrumb}) => (
                    <Breadcrumb key={match.pathname} match={match}/>
                ))}
            </div>
            <AppBarOptions/>
        </div>
    );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { AddModelButton, DataTable, DataTableRowOptionItem, DeleteModelButton, EditModelButton, Header, Toolbar } from '@lib/ui-components';

export const Wastes = () => {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);

  const rowOptions: DataTableRowOptionItem[] = [
    {
      renderer: (row) => (
        <EditModelButton
          id={row['id']}
          entity={row}
          modelName='wastes'
          buttonLabel={t('edit')}
          dialogTitle={t('Button.WasteModel.edit.dialog.title')}
          fields={[
            { field: 'code', label: t('wasteCode'), type: 'text' },
            { field: 'description', label: t('wasteDescription'), type: 'text' },
            {
              field: 'gtin',
              label: t('gtin'),
              type: 'numeric',
              numericMinValue: 0,
              numericMaxValue: 99999999999999,
            },
          ]}
          validationSchema={{
            code: Yup.string().required(t('Model.Waste.code.required')),
            description: Yup.string().required(t('Model.Waste.description.required')),
          }}
          onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
          yellowIcon
        />
      ),
    },
    {
      renderer: (row) => <DeleteModelButton id={row['id']} modelName='wastes' onDeleted={async () => setTableReloadKey(tableReloadKey + 1)} />,
    },
  ];

  const rightButtons: JSX.Element[] = [
    <AddModelButton
      modelName='wastes'
      buttonLabel={t('Button.WasteModel.add')}
      dialogTitle={t('Button.WasteModel.add.dialog.title')}
      fields={[
        { field: 'code', label: t('wasteCode'), type: 'text' },
        {
          field: 'description',
          label: t('wasteDescription'),
          type: 'text',
        },
        {
          field: 'gtin',
          label: t('gtin'),
          type: 'numeric',
          numericMinValue: 0,
          numericMaxValue: 99999999999999,
        },
      ]}
      validationSchema={{
        code: Yup.string().required(t('Model.Waste.code.required')),
        description: Yup.string().required(t('Model.Waste.description.required')),
      }}
      onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
    />,
  ];

  return (
    <>
      <Header label={t('GlobalSettings.wastes.header')} />
      <Toolbar right={rightButtons} />
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{ modelName: 'wastes' }}
        columns={[
          {
            key: 'code',
            label: t('wasteCode'),
            type: 'text',
          },
          {
            key: 'description',
            label: t('wasteDescription'),
            type: 'text',
          },
          {
            key: 'gtin',
            label: t('gtin'),
            type: 'numeric',
          },
        ]}
        rowOptions={rowOptions}
      />
    </>
  );
};

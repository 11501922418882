import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useStateIfMounted } from 'use-state-if-mounted';
import {
  Button,
  ButtonColor,
  Dialog,
  GeneralForm,
  GeneralFormItem,
  GeneralFormRowOfItems,
  LargeAddIcon,
  Snackbar,
} from '@lib/ui-components';
import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import { Breakpoint } from '@mui/system';
import { AggregateCommandClientService, JsonUtils } from '@lib/common-sdk';
import { getErrorMessage } from '../../custom-post-error.handler';

export interface AggregateEventButtonProps {
  color?: ButtonColor;
  variant?: 'text' | 'outlined' | 'contained';
  onBeforeSave?: (formData: any) => any;
  onSaved: () => Promise<any>;
  method?: 'POST' | 'GET' | 'DELETE' | 'PUT' | 'PATCH';
  forceConfirmation?: boolean;
  confirmationMessage?: string;
  aggregateName: string;
  aggregateId: string;
  commandName: string;
  validationSchema?: any;
  buttonLabel?: string;
  dialogTitle?: string;
  fields?: (GeneralFormItem | GeneralFormRowOfItems)[];
  hiddenValues?: any;
  bottomMessage?: string;
  postErrorMappings?: Record<string, string>;
  multipart?: boolean;
  startIcon?: JSX.Element;
  dialogIcon?: JSX.Element;
  maxWidth?: Breakpoint | false;
  buttonDisabled?: boolean;
}

export const AggregateCommandButton = (props: AggregateEventButtonProps) => {
  const { t } = useTranslation(); // needed to react to language change (even if not used directly)
  const translations: any = i18n.getDataByLanguage(i18n.language);
  const errorKeys = translations.error;
  const [failureMessage, setFailureMessage] = useStateIfMounted('');
  const [openDialog, setDialog] = useStateIfMounted(false);
  const [loading, setLoading] = useState(false);

  async function handleButtonClick() {
    if (props.fields) {
      setDialog(true);
    } else {
      handleSaveDialog({});
    }
  }

  async function handleSaveDialog(formData: any) {
    setLoading(true);
    try {
      if (props.onBeforeSave) {
        formData = props.onBeforeSave(formData);
      }
      await AggregateCommandClientService.callCommand<any>(props.aggregateName, props.commandName, props.aggregateId, { ...props.hiddenValues, ...formData });
      setDialog(false);
      setTimeout(() => props.onSaved(), 0);
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      const status = e.response?.status;
      const data = e.response?.data || '{ "code": "E00001"}';
      const code = JsonUtils.extractValue(data, 'code');
      const context = JsonUtils.extractValue(data, 'context');
      if (props.postErrorMappings !== undefined) {
        const errorMessage = props.postErrorMappings[`${status}`];
        if (errorMessage !== undefined) {
          setFailureMessage(errorMessage);
          return;
        }
      }
      setFailureMessage(getErrorMessage({ code, context }, translations) || t('Common.error'));
    }
  }

  async function handleCloseDialog() {
    setDialog(false);
  }

  return (
    <>
      <Button
        dataTestId={`${props.aggregateName}-${props.commandName}-btn`}
        label={props.buttonLabel}
        onClick={handleButtonClick}
        variant={props.variant}
        color={props.color}
        startIcon={props.startIcon}
        forceConfirmation={props.forceConfirmation}
        confirmationMsg={props.confirmationMessage}
        forceLoadingSign={loading}
        isDisabled={props.buttonDisabled}
      />
      <Dialog open={openDialog} onClose={() => handleCloseDialog()} maxWidth={props.maxWidth === undefined ? 'md' : props.maxWidth} fullWidth={true}>
        <div style={{ margin: 'auto', marginTop: '2rem' }}>{props.dialogIcon ? props.dialogIcon : <LargeAddIcon />}</div>
        <DialogTitle sx={{ margin: 'auto' }}>
          {props.dialogTitle}
          <IconButton
            aria-label='close'
            data-test-id={'dialog-close-btn'}
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <GeneralForm
            fields={props.fields || []}
            validationSchema={props.validationSchema}
            onSaved={async (data) => handleSaveDialog(data)}
            onCanceled={async () => handleCloseDialog()}
            hideCancelButton={false}
            hiddenValues={props.hiddenValues}
            variant='standard'
          />
          {props.bottomMessage && (
            <DialogTitle
              sx={{
                margin: 'auto',
                fontSize: '1rem',
                maxWidth: '30rem',
                textAlign: 'center',
              }}
            >
              {props.bottomMessage}
            </DialogTitle>
          )}
        </DialogContent>
      </Dialog>
      <Snackbar message={failureMessage} severity='error' onClose={() => setFailureMessage('')} />
    </>
  );
};

import React from 'react';
import { DataTable, DataTableColumn, DataTableModelDescription, DataTableRowOptionItem } from '@lib/ui-components';
import { useTranslation } from 'react-i18next';
import { InfrastructureCategorySelectOptionsFactory } from '../../../../../../../../factories/infrastructure-category-select-options.factory';
import { ProjectInfrastructureOwnerEnum } from '@lib/api-interface';

interface InfrastructureServicesDataTableProps {
  refreshKey: number;
  fetchPostUrl?: string;
  rowOptions?: DataTableRowOptionItem[];
  modelDef?: DataTableModelDescription;
  fetchFilters?: any;
  forSubcontractor?: boolean;
}

export const InfrastructureServicesDataTable = (props: InfrastructureServicesDataTableProps) => {
  const { t } = useTranslation();
  const predefinedColumns: DataTableColumn[] = [];

  if (props.forSubcontractor) {
    predefinedColumns.push({
      key: 'projectNumber',
      label: t('projectNumber'),
      type: 'text',
    });
  }

  predefinedColumns.push(
    {
      key: props.forSubcontractor ? 'projectBranchInfrastructureServiceView.category' : 'category',
      label: t('category'),
      type: 'enum',
      enumValues: InfrastructureCategorySelectOptionsFactory.getSelectOptionsWithAll(t),
    },
    {
      key: props.forSubcontractor ? 'projectBranchInfrastructureServiceView.name' : 'name',
      label: t('resourceName'),
      type: 'text',
    },
    {
      key: props.forSubcontractor ? 'projectBranchInfrastructureServiceView.projectInfrastructureDescription' : 'projectInfrastructureDescription',
      label: t('projectInfrastructureDescription'),
      type: 'text',
    },
    {
      key: props.forSubcontractor ? 'projectBranchInfrastructureServiceView.owner' : 'owner',
      label: t('owner'),
      type: 'enum',
      enumValues: [
        { value: ProjectInfrastructureOwnerEnum.CLIENT, label: t('client') },
        { value: ProjectInfrastructureOwnerEnum.SUBCONTRACTOR, label: t('subcontractor') },
        { value: ProjectInfrastructureOwnerEnum.ORGANIZATION, label: t('is') },
        { value: ProjectInfrastructureOwnerEnum.COMMUNE, label: t('commune') },
      ],
    },
    {
      key: props.forSubcontractor ? 'projectBranchInfrastructureServiceView.subcontractorName' : 'subcontractorName',
      label: t('subcontractorsName'),
      type: 'text',
    },
    {
      key: props.forSubcontractor ? 'projectBranchInfrastructureServiceView.fixedAsset' : 'fixedAsset',
      label: t('fixedAsset'),
      type: 'boolean',
      align: 'center',
    },
    {
      key: props.forSubcontractor ? 'projectBranchInfrastructureServiceView.currentSettings.infrastructureCount' : 'currentSettings.infrastructureCount',
      label: t('number'),
      type: 'numeric',
    },
    {
      key: props.forSubcontractor ? 'projectBranchInfrastructureServiceView.currentSettings.clientLease' : 'currentSettings.clientLease',
      label: t('leasePriceClient'),
      type: 'text',
    },
    {
      key: props.forSubcontractor ? 'projectBranchInfrastructureServiceView.currentSettings.subcontractorLease' : 'currentSettings.subcontractorLease',
      label: t('leasePriceSubcontractor'),
      type: 'text',
    },
    {
      key: props.forSubcontractor ? 'projectBranchInfrastructureServiceView.navResourceNumber' : 'navResourceNumber',
      label: t('navResourceNumber'),
      type: 'text',
    },
    {
      key: props.forSubcontractor ? 'projectBranchInfrastructureServiceView.navResourceName' : 'navResourceName',
      label: t('navResourceName'),
      type: 'text',
    },
    {
      key: props.forSubcontractor ? 'projectBranchInfrastructureServiceView.organizationAsSide' : 'organizationAsSide',
      label: t('organizationAsSide'),
      type: 'boolean',
      align: 'center',
    },
    {
      key: props.forSubcontractor ? 'projectBranchInfrastructureServiceView.commune' : 'commune',
      label: t('commune'),
      type: 'boolean',
      align: 'center',
    },
    {
      key: props.forSubcontractor ? 'projectBranchInfrastructureServiceView.activationDate' : 'activationDate',
      label: t('activationDate'),
      type: 'date',
    },
    {
      key: props.forSubcontractor ? 'projectBranchInfrastructureServiceView.deactivationDate' : 'deactivationDate',
      label: t('deactivationDate'),
      type: 'date',
    },
  );

  return (
    <DataTable
      columns={predefinedColumns}
      refreshKey={props.refreshKey}
      fetchPostUrl={props.fetchPostUrl}
      rowOptions={props.rowOptions}
      modelDef={props.modelDef}
      fetchFilters={props.fetchFilters}
    />
  );
};

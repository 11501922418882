import { useTranslation } from 'react-i18next';
import { useProjectBranchContext } from '@context';
import { GeneralFormItem, GeneralFormRowOfItems, UsecaseButton } from '@lib/ui-components';
import {
  getServiceButtonConsts,
  infrastructureServiceFields,
  paymentDayEnumValues,
  paymentDueEnumValues,
  paymentPeriodEnumValues,
  priceInputFields,
  scheduleTypeControls,
} from '../shared-components/shared';
import { PAYMENT_PERIOD_PRECISION, PaymentPeriod, PICKUP_PRECISION, ServiceType } from '@lib/api-interface';
import { communeServiceValidationSchema } from './commune-service-validation';
import React from 'react';
import { ServiceButtonMode, ServiceButtonProps } from '../service-button-props';

export const CommuneServiceButton = (props: ServiceButtonProps) => {
  const { t } = useTranslation();
  const projectBranch = useProjectBranchContext();
  const { label, useCaseName, startIcon } = getServiceButtonConsts(t, props.serviceButtonMode);

  const initialService = props.serviceButtonMode !== ServiceButtonMode.CREATE ? props?.service : undefined;
  const [selectedPaymentPeriod, setSelectedPaymentPeriod] = React.useState<PaymentPeriod | undefined>(initialService?.paymentPeriod || undefined);
  const hiddenValues =
    props.serviceButtonMode === ServiceButtonMode.EDIT
      ? {
          serviceId: initialService!.serviceId,
          declaredPrice: initialService!.declaredPrice,
        }
      : {
          projectBranchId: projectBranch.projectBranchId,
          companyBranchId: projectBranch.branchId,
          projectId: projectBranch.projectId,
          acceptanceState: 'INITIALIZED',
          serviceType: ServiceType.COMMUNE,
        };
  const prices: GeneralFormRowOfItems = {
    rowItems: [
      ...priceInputFields('communePrice', t, {
        defaultPrice: 0.0,
        defaultType: 'UNIT',
        priceTypeVisible: () => false,
        defaultBearer: 'ORGANIZATION_COST',
        bearerVisible: () => false,
        initialService: initialService,
      }),
      {
        label: t('declaredPrice'),
        type: 'numeric',
        field: 'declaredPrice',
        numericPrecision: 2,
        defaultValue: initialService?.declaredPrice,
      },
    ],
  };

  const fields: (GeneralFormItem | GeneralFormRowOfItems)[] = [
    {
      rowItems: infrastructureServiceFields(t, {
        label: t('collectionMethod'),
        idField: 'collectionInfrastructureServiceId',
        countField: 'collectionInfrastructureCount',
        projectBranchId: projectBranch.projectBranchId!,
        collectionMethod: true,
        isCommune: true,
        service: initialService,
      }),
    },
    {
      label: t('additionalDescription'),
      type: 'text',
      field: 'description',
      defaultValue: initialService?.description,
    },
    {
      rowItems: [
        {
          label: t('activationDate'),
          type: 'date',
          field: 'activationDate',
          defaultValue: props.serviceButtonMode === ServiceButtonMode.EDIT ? initialService?.activationDate : undefined,
        },
        {
          label: t('deactivationDate'),
          type: 'date',
          field: 'deactivationDate',
          defaultValue: props.serviceButtonMode === ServiceButtonMode.EDIT ? initialService?.deactivationDate : undefined,
        },
      ],
    },
    {
      rowItems: [
        {
          label: t('declaredTransports'),
          type: 'numeric',
          field: 'declaredTransports',
          numericPrecision: PICKUP_PRECISION,
          defaultValue: initialService?.declaredTransports,
        },
        {
          label: t('monthsCount'),
          type: 'numeric',
          field: 'monthsCount',
          numericPrecision: PAYMENT_PERIOD_PRECISION,
          defaultValue: initialService?.monthsCount,
        },
      ],
    },
    {
      rowItems: [
        {
          label: t('paymentPeriod'),
          type: 'select',
          field: 'paymentPeriod',
          selectOptions: paymentPeriodEnumValues(t),
          defaultValue: initialService?.paymentPeriod,
          onSelect: (value: string) => {
            setSelectedPaymentPeriod(value as PaymentPeriod);
          },
        },
        {
          label: t('paymentDay'),
          type: 'select',
          field: 'paymentDay',
          selectOptions: paymentDayEnumValues(t),
          defaultValue: initialService?.paymentDay,
        },
        {
          label: t('paymentDue'),
          type: 'select',
          field: 'paymentDue',
          selectOptions: paymentDueEnumValues(t, selectedPaymentPeriod),
          defaultValue: initialService?.paymentDue,
          isDeactivated: () => !selectedPaymentPeriod,
        },
      ],
    },
    ...scheduleTypeControls(t, initialService, () => false),
    {
      rowItems: [
        {
          label: t('bankAccountNumber'),
          type: 'text',
          field: 'bankAccountNumber',
          defaultValue: initialService?.bankAccountNumber,
        },
        {
          label: t('bankPaymentReceiver'),
          type: 'text',
          field: 'bankPaymentReceiver',
          defaultValue: initialService?.bankPaymentReceiver,
        },
      ],
    },
    {
      label: t('bankPaymentTitle'),
      type: 'text',
      field: 'bankPaymentTitle',
      defaultValue: initialService?.bankPaymentTitle,
    },
  ];

  if (props.serviceButtonMode != ServiceButtonMode.EDIT) {
    fields.splice(3, 0, prices);
  }

  return (
    <UsecaseButton
      dataTestId={`service-button-${props.serviceButtonMode}`}
      maxWidth='lg'
      buttonLabel={label}
      dialogTitle={label}
      startIcon={startIcon}
      groupName='service'
      useCaseName={useCaseName}
      fields={fields}
      hiddenValues={hiddenValues}
      onSaved={async () => {
        if (props.onSaved) {
          props.onSaved();
        }
        if (props.serviceButtonMode !== ServiceButtonMode.EDIT) props.reloadServices();
      }}
      validationSchema={communeServiceValidationSchema(t, props.serviceButtonMode === ServiceButtonMode.EDIT)}
    />
  );
};

import styled from 'styled-components';
import { DataTableColumn, DataTableFilter, TableFilterItem } from '@lib/ui-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SettlementType } from '@lib/api-interface';

const TableToolbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0.8rem 1rem;
`;

export interface SettlementsFilterProps {
  onFiltersChange: (filters: TableFilterItem[]) => void;
}

export const SettlementsFilter = ({ onFiltersChange }: SettlementsFilterProps) => {
  const { t } = useTranslation();

  const columns: DataTableColumn[] = useMemo(
    () => [
      {
        label: t('serviceType'),
        key: 'serviceType',
        type: 'enum',
        enumValues: [
          { value: 'SCHEDULED', label: t('serviceType.SCHEDULED') },
          { value: 'PER_REQUEST', label: t('serviceType.PER_REQUEST') },
          { value: 'ADDITIONAL', label: t('serviceType.ADDITIONAL') },
          { value: 'INFRASTRUCTURE_SERVICE', label: t('lease') },
          { value: 'COMPLAINT', label: t('complaint') },
        ],
      },
      { label: t('projectNumber'), key: 'projectNumber', type: 'text' },
      { label: t('projectName'), key: 'projectName', type: 'text' },
      { label: t('clientName'), key: 'clientName', type: 'text' },
      { label: t('branch'), key: 'branchName', type: 'text' },
      { label: t('locality'), key: 'locality', type: 'text' },
      { label: t('street'), key: 'street', type: 'text' },
      { label: t('hasComplaint'), key: 'hasComplaint', type: 'boolean' },
      { label: t('wasteCode'), key: 'wasteCode', type: 'text' },
      { label: t('wasteName'), key: 'wasteName', type: 'text' },
      { label: t('ownDescriptionOfWaste'), key: 'projectWasteDescription', type: 'text' },
      { label: t('resourceName'), key: 'resourceName', type: 'text' },
      { label: t('collectionMethod'), key: 'collectionMethod', type: 'text' },
      { label: t('forUtilization'), key: 'forUtilization', type: 'boolean' },
      { label: t('reportDate'), key: 'reportDate', type: 'date' },
      { label: t('implementationDate'), key: 'implementationDate', type: 'date' },
      { label: t('settledSubcontractorName'), key: 'settledSubcontractorName', type: 'text' },
      { label: t('settledTransportEntity'), key: 'settledTransportEntity', type: 'text' },
      {
        label: t('settlementType'),
        key: 'settlementType',
        type: 'enum',
        enumValues: [
          { value: SettlementType.PER_COLLECTION, label: t('settlementType.PER_COLLECTION') },
          { value: SettlementType.PER_MONTH, label: t('settlementType.PER_MONTH') },
        ],
      },
      { label: t('pix'), key: 'pix', type: 'boolean' },
      { label: t('serviceDesc'), key: 'serviceDesc', type: 'text' },
      { label: t('organizationAsSide'), key: 'organizationAsSide', type: 'boolean' },
    ],
    [],
  );

  return (
    <>
      <TableToolbar>
        <DataTableFilter columns={columns} onFiltersChange={(filters) => onFiltersChange(filters)} />
      </TableToolbar>
    </>
  );
};

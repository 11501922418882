import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, NavigateButton } from '@lib/ui-components';
import { priceTypeEnumValues } from '../../projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-services/shared-components/shared';

export interface CommuneCompaniesDataTableProps {
  communeId: number;
}

export const CommuneServicesDataTable = (props: CommuneCompaniesDataTableProps) => {
  const { t } = useTranslation();
  return (
    <DataTable
      refreshKey={1}
      fetchUrl={'/api/commune-services'}
      fetchFilters={{ ownerBranchCommuneId: props.communeId, active: true }}
      columns={[
        {
          key: 'project.projectNumber',
          label: t('projectNum'),
          type: 'text',
        },
        {
          key: 'project.name',
          label: t('projectName'),
          type: 'text',
        },
        {
          key: 'ownerProjectBranch.companyName',
          label: t('companyName'),
          type: 'text',
        },
        {
          key: 'ownerProjectBranch.companyBranchName',
          label: t('branchName'),
          type: 'text',
        },
        {
          key: 'subcontractor',
          label: t('subcontractor'),
          type: 'text',
        },
        {
          key: 'projectWaste.waste.code',
          label: t('wasteCode'),
          type: 'text',
        },
        {
          key: 'serviceType',
          label: t('serviceType'),
          type: 'enum',
          enumValues: [
            { value: 'PER_REQUEST', label: t('serviceType.PER_REQUEST') },
            { value: 'ADDITIONAL', label: t('serviceType.ADDITIONAL') },
            { value: 'SCHEDULED', label: t('serviceType.SCHEDULED') },
          ],
        },
        {
          key: 'currentSettings.clientCollectionPrice.priceAsOrganization',
          label: t('clientPrice'),
          type: 'numeric',
          numericPrecision: 2,
        },
        {
          key: 'currentSettings.subcontractorCollectionPrice.priceType',
          label: t('priceType'),
          type: 'enum',
          enumValues: priceTypeEnumValues(t),
        },
        {
          key: 'currentSettings.subcontractorCollectionPrice.priceAsOrganization',
          label: t('subcontractorPrice'),
          type: 'numeric',
          numericPrecision: 2,
        },
        {
          key: 'currentSettings.subcontractorCollectionPrice.priceType',
          label: t('priceType'),
          type: 'enum',
          enumValues: priceTypeEnumValues(t),
        },
        {
          key: 'ownerProjectBranch.registrationAddress.postalCode',
          label: t('postcode'),
          type: 'text',
        },
        {
          key: 'ownerProjectBranch.registrationAddress.street',
          label: t('street'),
          type: 'text',
        },
        {
          key: 'ownerProjectBranch.registrationAddress.houseNum',
          label: t('houseNum'),
          type: 'text',
        },
        {
          key: 'ownerProjectBranch.registrationAddress.town',
          label: t('town'),
          type: 'text',
        },
      ]}
      rowOptions={[
        {
          renderer: (row) => <NavigateButton label={t('show')} to={`/projects/!${row.project.projectId}/!${row.ownerProjectBranch.projectBranchId}`} />,
        },
      ]}
    />
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { useStateIfMounted } from 'use-state-if-mounted';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Button, ButtonColor, Dialog, FormInputCheckbox, FormInputText, Panel, Snackbar } from '@lib/ui-components';
import * as Yup from 'yup';
import { AuthApiService } from '../../../../services/auth.api.service';
import { AuthoritiesEnum } from '../../../../domain/enums/authorities.enum';
import EditArrow from '../../../../libs/ui-components/components/action-buttons/EditArrow';
import { UserAuthoritiesService } from '../../../../domain/utils/user-authorities.service';

interface IFormInput {
  name: string;
  email: string;
  login: string;
  authorities: string[];
}

export interface EditUserButtonProps {
  id: string;

  name?: string;
  login?: string;
  email?: string;
  roles?: string[];
  authorities?: string[];

  buttonLabel: string;
  color?: ButtonColor;
  variant?: 'text' | 'outlined' | 'contained';
  onSaved: () => Promise<void>;
}

export const EditUserButton = (props: EditUserButtonProps) => {
  const { t } = useTranslation();
  const [failureMessage, setFailureMessage] = useStateIfMounted('');
  const [openDialog, setDialog] = useStateIfMounted(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('OrganizationUsers.addAccount.email.format')),
  });

  const defaultValues = {
    name: props.name ?? '',
    email: props.email ?? '',
    login: props.login ?? '',
    authorities: props.authorities ?? [],
  };

  const methods = useForm<IFormInput>({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, control, reset, setValue } = methods;

  async function handleSaveEditUserDialog(data: any) {
    const result = await AuthApiService.updateUser({
      id: props.id,
      name: data.name,
      email: data.email?.trim(),
      login: data.login?.trim(),
      authorities: UserAuthoritiesService.extractAuthorities(data.authorities),
    });
    if (result === '') {
      setDialog(false);
      setTimeout(() => props.onSaved(), 0);
    } else if ('httpStatus' in result) {
      const httpStatus = result.httpStatus;
      if (httpStatus === 409) {
        setFailureMessage(t('Common.error.unique-error'));
      } else {
        setFailureMessage(t('Common.error'));
      }
    }
  }

  async function handleCloseDialog() {
    setDialog(false);
  }

  async function handleCloseAddUserDialog() {
    setDialog(false);
  }

  async function showDialog() {
    reset({
      name: props.name ?? '',
      email: props.email ?? '',
      login: props.login ?? '',
      authorities: props.authorities ?? [],
    });
    setDialog(true);
  }

  return (
    <>
      <Button label={props.buttonLabel} onClick={async () => showDialog()} variant={props.variant} color={props.color} startIcon={<Edit />} />
      <Dialog open={openDialog} onClose={() => handleCloseDialog()} maxWidth='md' fullWidth={true}>
        <div style={{ margin: 'auto', marginTop: '2rem' }}>
          <EditArrow />
        </div>
        <DialogTitle>
          {t('OrganizationUsers.editAccount.dialogTitle')}{' '}
          <IconButton
            aria-label='close'
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ paddingTop: '0.5rem' }}>
          <FormInputText name='name' control={control} label={t('name')} />
          <FormInputText name='login' control={control} label={t('login')} />
          <FormInputText name='email' control={control} label={t('email')} />
          <FormInputCheckbox
            name='authorities.organizationAdmin'
            control={control}
            label={t('admin')}
            setValue={setValue}
            initialValue={props.authorities?.includes(AuthoritiesEnum.ORGANIZATION_ADMIN)}
          />
          <FormInputCheckbox
            name='authorities.viewsUnassignedProjects'
            control={control}
            label={t('OrganizationUsers.viewsUnassignedProjects')}
            setValue={setValue}
            initialValue={props.authorities?.includes(AuthoritiesEnum.VIEWS_UNASSIGNED_PROJECTS)}
          />
          <FormInputCheckbox
            name='authorities.seesPrices'
            control={control}
            label={t('seesPrices')}
            setValue={setValue}
            initialValue={props.authorities?.includes(AuthoritiesEnum.SEES_PRICES)}
          />
          <Panel borderType='flat' header={t('projects')}>
            <FormInputCheckbox
              name='authorities.projectSales'
              control={control}
              label={t('salesperson')}
              setValue={setValue}
              initialValue={props.authorities?.includes(AuthoritiesEnum.PROJECT_SALES)}
            />
            <FormInputCheckbox
              name='authorities.projectInvoice'
              control={control}
              label={t('invoicingPerson')}
              setValue={setValue}
              initialValue={props.authorities?.includes(AuthoritiesEnum.PROJECT_INVOICE)}
            />
            <FormInputCheckbox
              name='authorities.projectLeadPerson'
              control={control}
              label={t('projectLeadPerson')}
              setValue={setValue}
              initialValue={props.authorities?.includes(AuthoritiesEnum.PROJECT_LEAD)}
            />
          </Panel>
        </DialogContent>
        <DialogActions>
          <Button label={t('Common.cancel')} onClick={() => handleCloseAddUserDialog()} />
          <Button label={t('save')} color='primary' variant='contained' onClick={async () => handleSubmit(handleSaveEditUserDialog)()} />
        </DialogActions>
      </Dialog>
      <Snackbar message={failureMessage} severity='error' onClose={() => setFailureMessage('')} />
    </>
  );
};

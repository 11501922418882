import React from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, ToggleButtons } from '@lib/ui-components';
import { ServicesSettlementTab } from './services-settlement-tab';

export const ServicesSettlement = () => {
  const { t } = useTranslation();
  const [activePage, setActivePage] = React.useState(4);

  const labels = [t('unsettled'), t('settled'), t('invoiced'), t('canceled'), t('all')];
  const settlementStatuses = ['UNSETTLED', 'SETTLED', 'INVOICED', 'CANCELLED', undefined];

  return (
    <>
      <HStack align='center'>
        <ToggleButtons labels={labels} activeIndex={activePage} onChange={(index) => setActivePage(index)} />
      </HStack>
      <ServicesSettlementTab settlementStatus={settlementStatuses[activePage]} countLabel={labels[activePage]} />
    </>
  );
};

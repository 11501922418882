import React from 'react';
import { TitlePositioner } from '@shared/general';
import { useTranslation } from 'react-i18next';
import { AggregateCommandButton, CheckboxLabel, Edit, GridLabel, Panel, TextLabel } from '@lib/ui-components';
import { useQueryClient } from '@tanstack/react-query';
import { useProjectContext } from '@context';
import { InvoicingMethod, PaymentMethod } from '@lib/api-interface';

export default function GeneralTab() {
  const { t } = useTranslation();
  const projectView = useProjectContext();
  const queryClient = useQueryClient();

  const reloadData = () => queryClient.invalidateQueries(['projectViews', projectView.projectId]);

  return (
    <Panel direction='row'>
      <Panel flex='1'>
        {/* FIXME: assign API calls */}
        <TitlePositioner marginLeft='0.6rem'>
          <TextLabel label={t('Model.ProjectClient.billingSettings')} />
        </TitlePositioner>
        <Panel borderType='raised'>
          <GridLabel gridTemplateColumns='1fr 1fr 1fr'>
            <Panel>
              <TextLabel label={t('Model.ProjectClient.payoff')} />
              <CheckboxLabel label={t('no')} value={projectView.terms?.aggregatingSettlement} type='toggle' marginTop='-1.7rem' />
            </Panel>
            <Panel>
              <TextLabel label={t('Model.ProjectClient.paymentMethod')} value={t(`paymentMethodCode.${projectView.terms!.paymentMethod!}`)} />
              <TextLabel label={t('Model.ProjectClient.paymentConditions')} value={t(`invoicingMethod.${projectView.terms!.invoicingMethod!}`)} />
            </Panel>
            <GridLabel gridTemplateColumns='none' justifyContent='flex-end'>
              <AggregateCommandButton
                aggregateId={projectView.projectId!}
                aggregateName={'ProjectAggregate'}
                commandName={'updateProjectTerms'}
                buttonLabel=''
                startIcon={<Edit />}
                dialogTitle={t('Model.ProjectClient.billingSettings')}
                fields={[
                  {
                    field: 'aggregatingSettlement',
                    label: t('Model.ProjectClient.payoff'),
                    type: 'boolean',
                    defaultValue: projectView.terms?.aggregatingSettlement,
                  },
                  {
                    field: 'paymentMethod',
                    label: t('Model.ProjectClient.paymentMethod'),
                    type: 'select',
                    defaultValue: projectView.terms?.paymentMethod,
                    selectOptions: [
                      { value: PaymentMethod.PREPAYMENT, label: t('paymentMethodCode.PREPAYMENT') },
                      { value: PaymentMethod.TRANSFER, label: t('paymentMethodCode.TRANSFER') },
                    ],
                  },
                  {
                    field: 'invoicingMethod',
                    label: t('Model.ProjectClient.paymentConditions'),
                    type: 'select',
                    defaultValue: projectView.terms?.invoicingMethod,
                    selectOptions: [
                      { value: InvoicingMethod.PER_MONTH, label: t('invoicingMethod.PER_MONTH') },
                      { value: InvoicingMethod.PER_COLLECTION, label: t('invoicingMethod.PER_COLLECTION') },
                    ],
                  },
                ]}
                onBeforeSave={(formData) => {
                  return { ...projectView.terms, ...formData };
                }}
                onSaved={async () => {
                  await reloadData();
                }}
              />
            </GridLabel>
          </GridLabel>
        </Panel>
      </Panel>
      <Panel flex='1'>
        <TitlePositioner>
          <TextLabel label={t('organizationServices')} />
        </TitlePositioner>
        <Panel borderType='raised'>
          <GridLabel>
            <Panel>
              <CheckboxLabel label={t('Projects.ViewProject.Terms.General.rsi')} value={projectView.terms?.rsi} type='toggle' />
              <CheckboxLabel label={t('Projects.ViewProject.Terms.General.counselling')} value={projectView.terms?.counselling} type='toggle' />
              <CheckboxLabel label={t('Projects.ViewProject.Terms.General.packing')} value={projectView.terms?.packing} type='toggle' />
              <CheckboxLabel label={t('Projects.ViewProject.Terms.General.poi')} value={projectView.terms?.poi} type='toggle' />
              <CheckboxLabel label={t('Projects.ViewProject.Terms.General.machineService')} value={projectView.terms?.machineService} type='toggle' />
              <CheckboxLabel label={t('Projects.ViewProject.Terms.General.employeeOutsourcing')} value={projectView.terms?.employeeOutsourcing} type='toggle' />
            </Panel>
            <GridLabel gridTemplateColumns='none' justifyContent='flex-end'>
              <AggregateCommandButton
                aggregateId={projectView.projectId!}
                aggregateName={'ProjectAggregate'}
                commandName={'updateProjectTerms'}
                dialogTitle={t('organizationServices')}
                buttonLabel=''
                startIcon={<Edit />}
                fields={[
                  {
                    field: 'rsi',
                    label: t('Projects.ViewProject.Terms.General.rsi'),
                    type: 'boolean',
                    defaultValue: projectView.terms?.rsi,
                  },
                  {
                    field: 'counselling',
                    label: t('Projects.ViewProject.Terms.General.counselling'),
                    type: 'boolean',
                    defaultValue: projectView.terms?.counselling,
                  },
                  {
                    field: 'packing',
                    label: t('Projects.ViewProject.Terms.General.packing'),
                    type: 'boolean',
                    defaultValue: projectView.terms?.packing,
                  },
                  {
                    field: 'poi',
                    label: t('Projects.ViewProject.Terms.General.poi'),
                    type: 'boolean',
                    defaultValue: projectView.terms?.poi,
                  },
                  {
                    field: 'machineService',
                    label: t('Projects.ViewProject.Terms.General.machineService'),
                    type: 'boolean',
                    defaultValue: projectView.terms?.machineService,
                  },
                  {
                    field: 'employeeOutsourcing',
                    label: t('Projects.ViewProject.Terms.General.employeeOutsourcing'),
                    type: 'boolean',
                    defaultValue: projectView.terms?.employeeOutsourcing,
                  },
                ]}
                onBeforeSave={(formData) => {
                  return { ...projectView.terms, ...formData };
                }}
                onSaved={async () => {
                  await reloadData();
                }}
              />
            </GridLabel>
          </GridLabel>
        </Panel>
      </Panel>
    </Panel>
  );
}

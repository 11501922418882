import React from 'react';
import { useTranslation } from 'react-i18next';
import CollectionCalendarTab from './collection-calendar-tab';
import { HStack, NavigateButton, Panel, RenderIf, ToggleButtons } from '@lib/ui-components';
import { ServiceType } from '@lib/api-interface';
import { ServiceTabFilter } from './service-tab-filter';
import { useUserState } from '../../../../../../../../state/UserState';
import { RolesEnum } from '../../../../../../../../domain/enums/roles.enum';
import {
  ServiceTable as ClientAdminServiceTable,
} from '../../../../../../../client-admin/projects/branches/services/service-table';
import {
  AdditionalTable as ClientAdminAdditionalTable,
} from '../../../../../../../client-admin/projects/branches/services/additional-table';
import {
  CommuneTable as ClientAdminCommuneTable,
} from '../../../../../../../client-admin/projects/branches/services/commune-table';
import { ServiceTable } from './shared-components/service-table';
import AdditionalTable from './additional-tab/additional-table';
import CommuneTable from './commune-tab/commune-table';

export interface BranchServicesProps {
  projectId?: string;
  projectBranchId?: string;
  projectBranchDeleted?: boolean;
}

export const BranchServices = (props: BranchServicesProps) => {
  const userState = useUserState();
  const { t } = useTranslation();
  const [selectedPage, setSelectedPage] = React.useState(1);
  const [active, setActive] = React.useState(true);
  const clientAdmin = userState.roles.findIndex((value) => value === RolesEnum.CLIENT_ADMIN) >= 0 ?? false;

  return (
    <Panel borderType='raised'>
      <HStack align='center'>
        <ToggleButtons
          labels={[t('serviceType.SCHEDULED'), t('serviceType.PER_REQUEST'), t('serviceType.ADDITIONAL'), t('serviceType.COMMUNE'), t('recipientCalendar')]}
          activeIndex={selectedPage}
          onChange={(idx) => setSelectedPage(idx)}
        />
      </HStack>
      <RenderIf false={selectedPage === 4}>
        <HStack align='center'>
          <ToggleButtons labels={[t('active'), t('inactive')]} onChange={(idx) => setActive(idx === 0)} />
        </HStack>
        <RenderIf true={selectedPage === 3 && !clientAdmin}>
          <HStack align='right'>
            <NavigateButton label={t('communeDeclarations')} to={`/projects/!${props.projectId}/!${props.projectBranchId}/commune-declarations`} />
          </HStack>
        </RenderIf>
      </RenderIf>
      <RenderIf true={selectedPage === 0}>
        <ServiceTabFilter
          active={active}
          serviceType={ServiceType.SCHEDULED}
          serviceTable={clientAdmin ? ClientAdminServiceTable : ServiceTable}
          hideButtons={props.projectBranchDeleted}
        />
      </RenderIf>
      <RenderIf true={selectedPage === 1}>
        <ServiceTabFilter
          active={active}
          serviceType={ServiceType.PER_REQUEST}
          serviceTable={clientAdmin ? ClientAdminServiceTable : ServiceTable}
          hideButtons={props.projectBranchDeleted}
        />
      </RenderIf>
      <RenderIf true={selectedPage === 2}>
        <ServiceTabFilter
          active={active}
          serviceType={ServiceType.ADDITIONAL}
          serviceTable={clientAdmin ? ClientAdminAdditionalTable : AdditionalTable}
          hideButtons={props.projectBranchDeleted}
        />
      </RenderIf>
      <RenderIf true={selectedPage === 3}>
        <ServiceTabFilter
          active={active}
          serviceType={ServiceType.COMMUNE}
          serviceTable={clientAdmin ? ClientAdminCommuneTable : CommuneTable}
          hideButtons={props.projectBranchDeleted}
        />
      </RenderIf>
      <RenderIf true={selectedPage === 4}>
        <CollectionCalendarTab />
      </RenderIf>
    </Panel>
  );
};

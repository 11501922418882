import React from 'react';
import { useTranslation } from 'react-i18next';
import { AddIcon, AggregateCommandButton, DataTable, Edit, Panel, Toolbar, UsecaseButton, UsecaseDeleteButton } from '@lib/ui-components';
import { ProjectInfrastructureView } from '@lib/api-interface';
import { InfrastructureCategorySelectOptionsFactory } from '../../../../../../../factories/infrastructure-category-select-options.factory';
import { useProjectContext } from '@context';
import * as Yup from 'yup';

export const InfrastructureTab = () => {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const infrastructureOptions = InfrastructureCategorySelectOptionsFactory.getSelectOptions(t);
  const projectView = useProjectContext();

  return (
    <Panel borderType='raised'>
      <Toolbar
        right={[
          <UsecaseButton
            groupName='project'
            useCaseName='attach-infrastructure-to-project'
            startIcon={<AddIcon />}
            buttonLabel={t('Button.InfrastructureModel.add')}
            dialogTitle={t('Button.InfrastructureModel.add.dialog.title')}
            fields={[
              {
                label: t('infrastructure'),
                type: 'table-select',
                field: 'infrastructureId',
                tableSelectParams: {
                  modelName: 'infrastructures',
                  selectedValueField: 'id',
                  search: 'byCategoryNot',
                  removeHateoasPathFromResult: true,
                  fetchFilters: {
                    category: 'LOOSE',
                  },
                  displayFormat: '{name}',
                  dialogTitle: t('Model.ProjectInfrastructure.selectInfrastructure'),
                  columns: [
                    {
                      key: 'category',
                      label: t('Model.Infrastructure.category'),
                      type: 'enum',
                      enumValues: infrastructureOptions,
                    },
                    {
                      key: 'name',
                      label: t('resourceName'),
                      type: 'text',
                    },
                    {
                      key: 'comment',
                      label: t('comment'),
                      type: 'text',
                    },
                  ],
                  otherValuesMap: {
                    category: 'category',
                  },
                },
              },
              {
                label: t('description'),
                field: 'description',
                type: 'text',
              },
            ]}
            validationSchema={{
              infrastructureId: Yup.string().required(t('requiredField')),
            }}
            hiddenValues={{
              projectId: projectView.projectId!,
            }}
            onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
          />,
        ]}
      />
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{ modelName: 'projectInfrastructureViews', modelClass: ProjectInfrastructureView }}
        fetchFilters={{ projectId: projectView.projectId, deleted: false }}
        columns={[
          {
            key: 'category',
            label: t('category'),
            type: 'enum',
            enumValues: infrastructureOptions,
          },
          {
            key: 'name',
            label: t('name'),
            type: 'text',
          },
          {
            key: 'projectInfrastructureDescription',
            label: t('description'),
            type: 'text',
          },
          {
            key: 'description',
            label: t('comment'),
            type: 'text',
          },
        ]}
        rowOptions={[
          {
            renderer: (row: ProjectInfrastructureView) => (
              <AggregateCommandButton
                aggregateId={projectView.projectId!}
                aggregateName={'ProjectAggregate'}
                commandName={'updateProjectInfrastructure'}
                startIcon={<Edit yellowIcon={true} />}
                buttonLabel={t('edit')}
                dialogTitle={t('Button.ProjectInfrastructureModel.edit.dialog.title')}
                fields={[
                  {
                    field: 'description',
                    label: t('description'),
                    type: 'text',
                    defaultValue: row.projectInfrastructureDescription,
                  },
                ]}
                hiddenValues={{
                  projectInfrastructureId: row.projectInfrastructureId,
                }}
                onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
              />
            ),
          },
          {
            renderer: (row) => (
              <UsecaseDeleteButton
                groupName='project'
                useCaseName='remove-project-infrastructure'
                tooltip={t('GlobalSettings.infrastructure.tooltip.delete')}
                payload={{
                  projectId: projectView.projectId!,
                  projectInfrastructureId: row.projectInfrastructureId,
                }}
                onDeleted={() => setTableReloadKey(tableReloadKey + 1)}
              />
            ),
          },
        ]}
      />
    </Panel>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { BranchDataContact } from './branch-data-contact';
import { BranchDataUserAccounts } from './branch-data-user-accounts';
import { Tabs } from '@lib/ui-components';
import { ProjectCompanyBranchModel } from '@lib/api-interface';
import { BranchAddressInfo } from './branch-address-info';

export interface BranchDataProps {
  projectBranch: ProjectCompanyBranchModel;
}

export const BranchData = (props: BranchDataProps) => {
  const { t } = useTranslation();

  return (
    <>
      <BranchAddressInfo correspondenceAddress={props.projectBranch?.correspondenceAddress} registrationAddress={props.projectBranch?.registrationAddress} />
      <Tabs
        marginTop='1rem'
        panels={[
          { label: t('contact'), component: <BranchDataContact /> },
          {
            label: t('userAccounts'),
            component: <BranchDataUserAccounts branchId={props.projectBranch.branchId!} projectId={props.projectBranch.projectId!} />,
          },
        ]}
        borderType='raised'
      />
    </>
  );
};

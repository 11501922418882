import { DataTable } from '@lib/ui-components';
import React from 'react';
import { useProjectContext } from '@context';
import { useTranslation } from 'react-i18next';

export const AllUserAccounts = () => {
  const { t } = useTranslation();
  const projectView = useProjectContext();

  return (
    <DataTable
      refreshKey={1}
      fetchPostUrl='api/project/branch-accounts'
      fetchFilters={{ projectId: projectView.projectId }}
      columns={[
        {
          key: 'mainCompanyName',
          label: t('companyName'),
          type: 'text',
        },
        {
          key: 'mainBranchName',
          label: t('branch'),
          type: 'text',
        },
        {
          key: 'mainCompanyVatin',
          label: t('vatin'),
          type: 'text',
        },
        {
          key: 'name',
          label: t('fullName'),
          type: 'text',
        },
        {
          key: 'login',
          label: t('login'),
          type: 'text',
        },
        {
          key: 'email',
          label: t('email'),
          type: 'text',
        },
      ]}
    />
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Infrastructure } from './infrastructure/infrastructure';
import { Wastes } from './wastes/wastes';
import { Tabs } from '@lib/ui-components';

export const GlobalSettings = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Tabs
        panels={[
          { label: t('infrastructure'), component: <Infrastructure /> },
          { label: t('GlobalSettings.wastes'), component: <Wastes /> },
        ]}
      />
    </div>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonBar,
  ButtonProps,
  DataTable,
  DataTableColumn,
  DataTableRowOptionItem,
  Dialog,
  FormInputText,
  Snackbar,
} from '@lib/ui-components';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthApiService } from '../../../services/auth.api.service';
import { RolesEnum } from '../../../domain/enums/roles.enum';
import { AuthoritiesEnum } from '../../../domain/enums/authorities.enum';
import { DeleteUserButton } from './delete-user-button/delete-user-button';
import { EditUserButton } from './edit-user-button/edit-user-button';
import { AssignProjectsButton } from './assign-projects-button/assign-projects-button';

interface IFormInput {
  name: string;
  email: string;
  login: string;
}

const defaultValues = {
  name: '',
  email: '',
  login: '',
};

export const OrganizationUsers = () => {
  const { t } = useTranslation();
  const [openAddUserDialog, setOpenAddUserDialog] = React.useState(false);
  const [generalFailure, setGeneralFailure] = React.useState(false);
  const [tableReloadKey, setTableReloadKey] = React.useState(1);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('OrganizationUsers.addAccount.name.required')),
    email: Yup.string().required(t('OrganizationUsers.addAccount.email.required')).email(t('OrganizationUsers.addAccount.email.format')),
    login: Yup.string().required(t('OrganizationUsers.addAccount.login.required')),
  });

  const methods = useForm<IFormInput>({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, control, reset } = methods;

  const columns: Array<DataTableColumn> = [
    {
      key: 'name',
      label: t('name'),
      type: 'text',
    },
    {
      key: 'login',
      label: t('login'),
      type: 'text',
    },
    {
      key: 'active',
      label: t('Model.User.active'),
      type: 'boolean',
      align: 'center',
    },
    {
      key: 'authorities',
      label: t('admin'),
      type: 'string-array-boolean',
      stringArrayValue: 'ORGANIZATION_ADMIN',
    },
  ];

  const rowOptions: DataTableRowOptionItem[] = [
    {
      renderer: (row) => (
        <EditUserButton
          id={row.id}
          name={row.name}
          login={row.login}
          email={row.email}
          authorities={row.authorities}
          buttonLabel={t('edit')}
          onSaved={async () => setTableReloadKey((prevState) => prevState + 1)}
        />
      ),
    },
    {
      renderer: (row) => <DeleteUserButton id={row.id} onDeleted={async () => setTableReloadKey((prevState) => prevState + 1)} />,
    },
    {
      renderer: (row) => <AssignProjectsButton accountId={row.id} onSaved={async () => setTableReloadKey((prevState) => prevState + 1)} />,
    },
  ];

  const leftButtons: Array<ButtonProps> = [
    {
      label: t('OrganizationUsers.addAccount'),
      onClick: async () => addAccountHandler(),
    },
  ];

  async function addAccountHandler() {
    setOpenAddUserDialog(true);
  }

  async function handleCloseAddUserDialog() {
    setOpenAddUserDialog(false);
    reset();
  }

  async function handleSaveAddUserDialog(data: IFormInput) {
    const result = await AuthApiService.createUser({
      name: data.name,
      email: data.email?.trim(),
      login: data.login?.trim(),
      roles: [RolesEnum.ORGANIZATION],
      authorities: [AuthoritiesEnum.ORGANIZATION_ADMIN],
      password: 'xxxx',
      tags: new Map<string, string>(),
    });
    if ('httpStatus' in result) {
      setGeneralFailure(true);
    } else {
      setTableReloadKey(tableReloadKey + 1); // reload table
      setOpenAddUserDialog(false);
      reset();
    }
  }

  return (
    <div>
      <ButtonBar left={leftButtons} />
      <DataTable
        refreshKey={tableReloadKey}
        fetchFilters={{ role: RolesEnum.ORGANIZATION }}
        modelDef={{ modelName: 'accounts', search: 'byRole' }}
        columns={columns}
        rowOptions={rowOptions}
      />

      <Dialog open={openAddUserDialog} onClose={() => handleCloseAddUserDialog()}>
        <DialogTitle>{t('OrganizationUsers.addAccount.dialogTitle')}</DialogTitle>
        <DialogContent style={{ paddingTop: '0.5rem' }}>
          <FormInputText name='name' control={control} label={t('name')} />
          <FormInputText name='login' control={control} label={t('login')} />
          <FormInputText name='email' control={control} label={t('email')} />
          {/* <FormInputText name="authorities" control={control} label={t('OrganizationUsers.addAccount.managesUsers')} /> */}
        </DialogContent>
        <DialogActions>
          <Button label={t('Common.cancel')} onClick={() => handleCloseAddUserDialog()} />
          <Button label={t('save')} color='primary' variant='contained' onClick={async () => handleSubmit(handleSaveAddUserDialog)()} />
        </DialogActions>
      </Dialog>
      <Snackbar open={generalFailure} message={t('Common.error')} severity='error' onClose={() => setGeneralFailure(false)} />
    </div>
  );
};

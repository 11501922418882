import { IsNotEmpty } from 'class-validator';
import { Expose, Type } from 'class-transformer';
import { BaseModel } from '../../../common-sdk';

class ProjectCompanySettings {
  companyId?: string;
  requestForDPR?: boolean;
  adminApprovalForNewServicesRequired?: boolean;
  branchCanGenerateReports?: boolean;
  customerAdminCanAskForCollection?: boolean;
  requestForCollection?: boolean;
  declarationChangeAcceptanceISAdministrator?: boolean;
  declarationChangeAcceptanceCustomerAdministrator?: boolean;
  declarationChangeAcceptanceCustomerBranch?: boolean;
  declarationChangeAcceptanceRegionalAdministrator?: boolean;
}

class ProjectPix {
  pixExchangeRateId?: string;
  eurExchangeRateId?: string;
  valueSubstractedFromPix?: number; // in cents
  dateFrom?: Date;
  dateTo?: Date;
}

export class ProjectClientBindingModel extends BaseModel {
  @IsNotEmpty()
  @Expose()
  companyId?: string;

  @IsNotEmpty()
  @Expose()
  branchId?: string;

  @Expose()
  companyName?: string;

  @Expose()
  branchName?: string;

  @IsNotEmpty()
  @Expose()
  @Type(() => String)
  projectId?: string;

  @IsNotEmpty()
  @Expose()
  projectNumber?: string;

  @IsNotEmpty()
  @Expose()
  projectName?: string;

  @IsNotEmpty()
  @Expose()
  type?: 'CLIENT' | 'SUBCONTRACTOR';

  @Expose()
  sellerPerson?: string;

  @Expose()
  projectLeadPerson?: string;

  @Expose()
  invoicePerson?: string;

  @Expose()
  projectType?: string;

  @Expose()
  creationDate?: Date;

  @Expose()
  settings?: ProjectCompanySettings;
}

import { ProjectBranchViewModel, ServiceType } from '@lib/api-interface';
import React from 'react';
import { HStack, Panel, RenderIf, ToggleButtons } from '@lib/ui-components';
import { useTranslation } from 'react-i18next';
import CollectionCalendarTab from '../../organization/projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-services/collection-calendar-tab';
import { CommonPickups } from './common-pickups';
import { ScheduledServicePickup } from './scheduled-service-pickup';

interface ProjectBranchPickupsProps {
  projectBranchView?: ProjectBranchViewModel;
}

export const Pickups = (props: ProjectBranchPickupsProps) => {
  const { t } = useTranslation();
  const projectBranchView = props.projectBranchView!;
  const projectId = projectBranchView.projectId!;
  const branchId = projectBranchView.branchId!;
  const [selectedPage, setSelectedPage] = React.useState(0);

  return (
    <Panel borderType='raised'>
      <HStack align='center'>
        <ToggleButtons labels={[t('schedule'), t('onRequest'), t('additional'), t('recipientCalendar')]} activeIndex={selectedPage} onChange={(idx) => setSelectedPage(idx)} />
      </HStack>
      <RenderIf true={selectedPage === 0}>
        <ScheduledServicePickup projectId={projectId} branchId={branchId} />
      </RenderIf>
      <RenderIf true={selectedPage === 1}>
        <CommonPickups serviceType={ServiceType.PER_REQUEST} projectId={projectId} branchId={branchId} />
      </RenderIf>
      <RenderIf true={selectedPage === 2}>
        <CommonPickups serviceType={ServiceType.ADDITIONAL} projectId={projectId} branchId={branchId} />
      </RenderIf>
      <RenderIf true={selectedPage === 3}>
        <CollectionCalendarTab />
      </RenderIf>
    </Panel>
  );
};

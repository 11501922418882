import 'reflect-metadata';
import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {RouteSelector} from './components/route-selector/route-selector';
import {createTheme, CssBaseline, ThemeProvider} from '@mui/material';
import axios from 'axios';
// from https://medium.com/geekculture/strong-typed-i18n-in-react-c43281de720c
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import de from './translations/de.json';
import en from './translations/en.json';
import {CompanyBreadcrumbProvider} from './breadcrumb-providers/company.breadcrumb-provider';
import {ProjectBreadcrumbProvider} from './breadcrumb-providers/project.breadcrumb-provider';
import {CompanyBranchBreadcrumbProvider} from './breadcrumb-providers/company-branch.breadcrumb-provider';
import {registerBreadcrumbDataFunction} from '@lib/ui-components';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ProjectBranchBreadcrumbProvider} from './breadcrumb-providers/project-branch.breadcrumb-provider';
import {CommuneBreadcrumbProvider} from './breadcrumb-providers/commune.breadcrumb-provider';

// if (process.env['REACT_APP_ENVIRONMENT'] !== 'dev') {
//   Sentry.init({
//     environment: process.env['REACT_APP_ENVIRONMENT'],
//     dsn: 'https://e9ceb4fe4808d077fc99fac242f1d5b6@o4507270989807616.ingest.de.sentry.io/4507301653381200',
//     integrations: [
//       // See docs for support of different versions of variation of react router
//       // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
//       Sentry.reactRouterV6BrowserTracingIntegration({
//         useEffect: React.useEffect,
//         useLocation,
//         useNavigationType,
//         createRoutesFromChildren,
//         matchRoutes,
//       }),
//       Sentry.replayIntegration(),
//     ],
//
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     tracesSampleRate: 1.0,
//
//     // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ['localhost', /^https:\/\/poi\.interzero\.pl/],
//
//     // Capture Replay for 10% of all sessions,
//     // plus for 100% of sessions with an error
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1.0,
//   });
// }

const resources = {
  de,
  en,
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    ns: ['common', 'error'],
    defaultNS: 'common',
    fallbackLng: 'de',
    nonExplicitSupportedLngs: true,
    supportedLngs: ['de', 'en'],
    lng: 'de',
  });

const theme = createTheme({
  typography: {
    fontFamily: ['Montserrat', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      main: '#183362',
    },
    secondary: {
      main: '#F2C94C',
      contrastText: '#183362',
    },
    info: {
      main: '#f2f2f2',
      contrastText: '#183362',
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#F2C94C',
          },
          backgroundColor: '#f2f2f2',
          border: '1px solid #e8e8e8',
          borderRadius: '0.5rem 0.5rem 0 0',
          marginRight: '0.2rem',
          minHeight: '1rem',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginBottom: '-0.3rem',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: '0px',
          whiteSpace: 'pre-line',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        container: {
          alignItems: 'flex-start',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filledInfo: {
          backgroundColor: '#2196F3',
        },
        filledWarning: {
          backgroundColor: '#ECBC49',
        },
      },
    },
  },
});

const queryClient = new QueryClient();

axios.defaults.baseURL = process.env['REACT_APP_API_URL'];
axios.defaults.withCredentials = true;
axios.defaults.paramsSerializer = (params) => {
  const keys = Object.keys(params);
  let options = '';

  keys.forEach((key) => {
    const isParamTypeObject = typeof params[key] === 'object';
    const isParamTypeArray = isParamTypeObject && params[key].length >= 0;

    if (!isParamTypeObject) {
      if (params[key] !== undefined) {
        options += `${key}=${params[key]}&`;
      }
    }

    if (isParamTypeObject && isParamTypeArray) {
      params[key].forEach((element: any) => {
        if (element !== undefined) {
          options += `${key}=${element}&`;
        }
      });
    }
  });

  return options ? options.slice(0, -1) : options;
};

registerBreadcrumbDataFunction('Breadcrumb.companies', CompanyBreadcrumbProvider);
registerBreadcrumbDataFunction('Breadcrumb.companies.$', CompanyBranchBreadcrumbProvider);
registerBreadcrumbDataFunction('Breadcrumb.projects', ProjectBreadcrumbProvider);
registerBreadcrumbDataFunction('Breadcrumb.projects.$', ProjectBranchBreadcrumbProvider);
registerBreadcrumbDataFunction('Breadcrumb.communes', CommuneBreadcrumbProvider);
registerBreadcrumbDataFunction('Breadcrumb.pickups', ProjectBranchBreadcrumbProvider);
registerBreadcrumbDataFunction('Breadcrumb.branches', ProjectBranchBreadcrumbProvider);

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOMClient.createRoot(container);

root.render(
  <>
    <CssBaseline />
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <RouteSelector />
        </BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { IsDefined, IsNotEmpty } from 'class-validator';
import { Expose } from 'class-transformer';
import { InfrastructureCategory } from './infrastructure.model';
import { BaseModel } from '../../../common-sdk';

export class OrganizationInfrastructureModel extends BaseModel {
  @IsNotEmpty()
  @Expose()
  category: InfrastructureCategory | undefined;

  @Expose()
  mass: number | undefined; // in kg

  @Expose()
  volume: number | undefined; // in cm2

  @IsNotEmpty()
  @Expose()
  name: string | undefined;

  @IsDefined()
  @Expose()
  deprecation: boolean | undefined = false;

  @Expose()
  deprecationMonths: number | undefined;

  @Expose()
  deprecationAmount: number | undefined; // in PLN gr

  @Expose()
  deprecationStartDate: string | undefined; // LocalDate type?

  @Expose()
  deprecationRate: number | undefined;

  @IsNotEmpty()
  @Expose()
  serialNumber: string | undefined;

  @Expose()
  projectId: string | undefined;

  @Expose()
  branchId: string | undefined;

  @Expose()
  generatedNumber: string | undefined;
}

import * as React from 'react';

type PositionType = 'left' | 'center' | 'right';

interface FlexItemProps {
  position: PositionType;
  children: React.ReactNode;
}

export const FlexItem: React.FC<FlexItemProps> = ({ position, children }) => {
  const style: React.CSSProperties = {
    position: 'absolute',
  };

  if (position === 'left') {
    style.left = '0';
  } else if (position === 'center') {
    style.left = '50%';
    style.transform = 'translateX(-50%)';
  } else if (position === 'right') {
    style.right = '0';
  }

  return <div style={style}>{children}</div>;
};

interface FlexHorizontalContainerProps {
  gap?: string;
  children: React.ReactElement<FlexItemProps>[];
}

export const FlexHorizontalContainer: React.FC<FlexHorizontalContainerProps> = ({ gap, children }) => {
  return (
    <div
      style={{
        display: 'flex',
        gap: gap,
        position: 'relative',
        height: 'auto',
        minHeight: '2.5rem',
        alignItems: 'center',
        marginTop: '0.5rem',
      }}
    >
      {React.Children.map(children, (child) => React.cloneElement(child))}
    </div>
  );
};

import React from 'react';
import { AddIcon, DataTable, HStack, Panel, ToggleButtons, Toolbar, UsecaseButton, UsecaseDeleteButton } from '@lib/ui-components';
import { useTranslation } from 'react-i18next';
import { ToolbarButtons } from '@shared/general';
import { useProjectContext } from '@context';

export default function HeadquartersBranchesTab() {
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const { t } = useTranslation();
  const projectView = useProjectContext();
  const [activePage, setActivePage] = React.useState(0);

  async function reload() {
    setTableReloadKey(tableReloadKey + 1);
  }

  return (
    <Panel borderType='flat'>
      <HStack align='center'>
        <ToggleButtons
          labels={[t('active'), t('inactive')]}
          activeIndex={activePage}
          onChange={(idx) => {
            setActivePage(idx);
            setTableReloadKey(tableReloadKey + 1);
          }}
        />
      </HStack>
      <Toolbar
        right={[
          <ToolbarButtons>
            <UsecaseButton
              groupName='project'
              useCaseName='create-project-company'
              buttonLabel={t('addCompany')}
              dialogTitle={t('addCompany')}
              startIcon={<AddIcon />}
              fields={[
                {
                  field: 'companyId',
                  label: t('company'),
                  type: 'table-select-by-api',
                  tableSelectByApiParams: {
                    apiPostParams: { projectId: projectView.projectId, role: 'CLIENT' },
                    apiPostEndpoint: '/api/usecase/project/fetch-companies-for-project',
                    dialogTitle: t('company'),
                    displayFormat: '{name}',
                    selectedValueField: 'companyId',
                    columns: [
                      { label: t('companyName'), key: 'name', type: 'text' },
                      { label: t('vatin'), key: 'vatin', type: 'text' },
                    ],
                  },
                },
                {
                  label: t('addAllBranches'),
                  field: 'addAllBranches',
                  type: 'boolean',
                },
                {
                  label: t('addCentral'),
                  field: 'addCentral',
                  type: 'boolean',
                  isVisible: (data) => !data.addAllBranches,
                },
              ]}
              hiddenValues={{
                projectId: projectView.projectId,
                role: 'CLIENT',
              }}
              validationSchema={{}}
              onSaved={async () => await reload()}
            />
            <UsecaseButton
              groupName='project'
              useCaseName='create-project-branch'
              buttonLabel={t('addBranch')}
              dialogTitle={t('addBranch')}
              startIcon={<AddIcon />}
              fields={[
                {
                  field: 'companyBranchId',
                  label: t('branch'),
                  type: 'table-select-by-api',
                  tableSelectByApiParams: {
                    apiPostParams: { projectId: projectView.projectId, role: 'CLIENT' },
                    apiPostEndpoint: '/api/usecase/project/fetch-branches-for-project',
                    dialogTitle: t('branch'),
                    displayFormat: '{name}',
                    selectedValueField: 'branchId',
                    columns: [
                      {
                        key: 'branchNestedNumber',
                        label: t('branchNestedNumber'),
                        type: 'numeric',
                        align: 'right',
                      },
                      {
                        key: 'companyName',
                        label: t('headquarterName'),
                        type: 'text',
                        minWidth: '15rem',
                      },
                      {
                        key: 'name',
                        label: t('branchName'),
                        type: 'text',
                        minWidth: '15rem',
                      },
                      {
                        key: 'central',
                        label: t('headquarter'),
                        type: 'boolean',
                        align: 'center',
                      },
                      {
                        key: 'vatin',
                        label: t('vatin'),
                        type: 'text',
                      },
                      {
                        key: 'registrationAddress.postalCode',
                        label: t('postcode'),
                        type: 'text',
                      },
                      {
                        key: 'registrationAddress.town',
                        label: t('locality'),
                        type: 'text',
                      },
                      {
                        key: 'registrationAddress.street',
                        label: t('street'),
                        type: 'text',
                      },
                      {
                        key: 'registrationAddress.houseNum',
                        label: t('houseNum'),
                        type: 'text',
                      },
                      {
                        key: 'registrationAddress.apartmentNum',
                        label: t('apartmentNum'),
                        type: 'text',
                      },
                      {
                        key: 'registrationAddress.commune',
                        label: t('commune'),
                        type: 'text',
                      },
                      {
                        key: 'registrationAddress.province',
                        label: t('province'),
                        type: 'text',
                      },
                    ],
                  },
                },
              ]}
              hiddenValues={{
                projectId: projectView.projectId,
              }}
              validationSchema={{}}
              onSaved={async () => await reload()}
            />
          </ToolbarButtons>,
        ]}
      />
      <Panel borderType={'none'} header={t('branchesList')}>
        <DataTable
          excel={true}
          rowLink={{ linkPath: '', linkRowId: 'projectBranchId' }}
          refreshKey={tableReloadKey}
          modelDef={{ modelName: 'projectBranchViews' }}
          fetchFilters={{ projectId: projectView.projectId, role: 'CLIENT', deleted: activePage === 1 }}
          preSortIndexes={[0, -2, 1]}
          columns={[
            {
              key: 'branchNestedNumber',
              label: t('branchNestedNumber'),
              type: 'numeric',
              align: 'right',
            },
            {
              key: 'companyName',
              label: t('companyName'),
              type: 'text',
              minWidth: '15rem',
            },
            {
              key: 'companyBranchName',
              label: t('branchName'),
              type: 'text',
              minWidth: '15rem',
            },
            {
              key: 'central',
              label: t('headquarter'),
              type: 'boolean',
              align: 'center',
            },
            {
              key: 'vatin',
              label: t('vatin'),
              type: 'text',
            },
            {
              key: 'registrationAddress.postalCode',
              label: t('postcode'),
              type: 'text',
            },
            {
              key: 'registrationAddress.town',
              label: t('locality'),
              type: 'text',
            },
            {
              key: 'registrationAddress.street',
              label: t('street'),
              type: 'text',
            },
            {
              key: 'registrationAddress.houseNum',
              label: t('houseNum'),
              type: 'text',
            },
            {
              key: 'registrationAddress.apartmentNum',
              label: t('apartmentNum'),
              type: 'text',
            },
            {
              key: 'registrationAddress.commune',
              label: t('commune'),
              type: 'text',
            },
            {
              key: 'registrationAddress.province',
              label: t('province'),
              type: 'text',
            },
          ]}
          rowOptions={[
            {
              isVisible: () => activePage === 0,
              renderer: (row) => (
                <UsecaseDeleteButton
                  groupName='project'
                  useCaseName='delete-project-branch'
                  payload={{
                    projectBranchId: row.projectBranchId,
                  }}
                  onDeleted={async () => await reload()}
                />
              ),
            },
          ]}
        />
      </Panel>
      <Panel borderType={'none'} header={t('companiesList')}>
        <DataTable
          refreshKey={tableReloadKey}
          modelDef={{ modelName: 'projectCompanyViews' }}
          fetchFilters={{ projectId: projectView.projectId, role: 'CLIENT', deleted: false }}
          columns={[
            {
              key: 'companyName',
              label: t('companyName'),
              type: 'text',
            },
            {
              key: 'vatin',
              label: t('vatin'),
              type: 'text',
            },
            {
              key: 'nationalCourtRegister',
              label: t('nationalCourtRegister'),
              type: 'text',
            },
          ]}
          rowOptions={[
            {
              renderer: (row) => (
                <UsecaseDeleteButton
                  groupName='project'
                  useCaseName='delete-project-company'
                  payload={{
                    projectCompanyId: row.projectCompanyId,
                  }}
                  onDeleted={async () => await reload()}
                />
              ),
            },
          ]}
        />
      </Panel>
    </Panel>
  );
}

import * as React from 'react';
import styles from './styles.module.css';
import { Button, ButtonProps } from '../button/button';

export interface ButtonBarProps {
    left?: Array<ButtonProps>;
    right?: Array<ButtonProps>;
}

export const ButtonBar: (props: ButtonBarProps) => JSX.Element = (props: ButtonBarProps) => {
    function getButton(btn: ButtonProps, index: number) {
        return <Button key={index} label={btn.label} variant={btn.variant} color={btn.color} onClick={btn.onClick} separated={btn.separated}/>;
    }

    const leftButtons =
        props.left === undefined
            ? []
            : props.left.map((btn, index) => {
                return getButton(btn, index);
            });

    const rightButtons =
        props.right === undefined
            ? []
            : props.right.map((btn, index) => {
                return getButton(btn, index);
            });

    return (
        <div className={styles['container']}>
            <div className={styles['containerInner']}>{leftButtons}</div>
            <div className={styles['containerInner']}>{rightButtons}</div>
        </div>
    );
};

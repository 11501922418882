import React from 'react';
import { useTranslation } from 'react-i18next';
import { AddIcon, AggregateCommandButton, DataTable, EditModelButton, Toolbar, UsecaseDeleteButton } from '@lib/ui-components';
import { useProjectContext } from '@context';
import * as Yup from 'yup';
import { AggregateCommandClientService, DataResultsDto } from '@lib/common-sdk';

export const WasteTab = () => {
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const { t } = useTranslation();
  const projectView = useProjectContext();

  return (
    <>
      <Toolbar
        right={[
          <AggregateCommandButton
            aggregateId={projectView.projectId!}
            aggregateName='ProjectAggregate'
            commandName='attachWasteToProject'
            buttonLabel={t('Button.WasteModel.add')}
            dialogTitle={t('Button.WasteModel.add')}
            startIcon={<AddIcon />}
            fields={[
              {
                field: 'wasteId',
                label: t('wasteCode'),
                type: 'table-select',
                tableSelectParams: {
                  modelName: 'wastes',
                  removeHateoasPathFromResult: true,
                  dialogTitle: t('Projects.ViewProject.Dictionary.Waste.waste'),
                  displayFormat: '{code}',
                  columns: [
                    { key: 'code', label: t('wasteCode'), type: 'text' },
                    { key: 'description', label: t('wasteDescription'), type: 'text' },
                    {
                      key: 'waste.gtin',
                      label: t('gtin'),
                      type: 'numeric',
                    },
                  ],
                },
              },
              { field: 'description', label: t('description'), type: 'text' },
            ]}
            validationSchema={{
              wasteId: Yup.string().required(t('fieldRequired')),
            }}
            onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
          />,
        ]}
      />
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{ modelName: 'projectWasteViews' }}
        fetchFilters={{ projectId: projectView.projectId }}
        columns={[
          {
            key: 'waste.code',
            label: t('wasteCode'),
            type: 'text',
          },
          {
            key: 'waste.description',
            label: t('wasteDescription'),
            type: 'text',
          },
          {
            key: 'projectWasteDescription',
            label: t('ownDescriptionOfWaste'),
            type: 'text',
          },
          {
            key: 'waste.gtin',
            label: t('gtin'),
            type: 'numeric',
          },
        ]}
        rowOptions={[
          {
            renderer: (row) => (
              <EditModelButton
                id={row.id}
                entity={row}
                modelName={'projectWasteViews'}
                buttonLabel={t('edit')}
                dialogTitle={t('editProjectWaste')}
                fields={[
                  {
                    field: 'newDescription',
                    label: t('ownDescriptionOfWaste'),
                    type: 'text',
                    defaultValue: row.projectWasteDescription,
                  },
                ]}
                onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
                overrideSaveCall={async (payload: any) => {
                  try {
                    payload.projectWasteId = row.projectWasteId;
                    await AggregateCommandClientService.callCommand<any>('ProjectAggregate', 'updateProjectWaste', projectView.projectId!, payload);
                    return new DataResultsDto(false, 200, undefined, undefined, undefined);
                  } catch (error: any) {
                    return new DataResultsDto(true, error.status, undefined, undefined, undefined);
                  }
                }}
                yellowIcon
              />
            ),
          },
          {
            renderer: (row) => (
              <UsecaseDeleteButton
                groupName='project'
                useCaseName='remove-project-waste'
                tooltip={t('GlobalSettings.wastes.tooltip.delete')}
                payload={{
                  projectId: projectView.projectId!,
                  projectWasteId: row.projectWasteId,
                }}
                onDeleted={() => setTableReloadKey(tableReloadKey + 1)}
              />
            ),
          },
        ]}
      />
    </>
  );
};

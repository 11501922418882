import React, { useMemo } from 'react';
import styled from 'styled-components';
import { DataTableFilter, TableFilterItem } from '../data-table-filter/data-table-filter';
import { useTranslation } from 'react-i18next';
import { DataTableColumn } from '../data-table';
import { ServiceType } from '@lib/api-interface';

const TableToolbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0.8rem 1rem;
`;

export interface ServicesTableProps {
  onFiltersChange: (filters: TableFilterItem[]) => void;
  serviceType: ServiceType;
}

export const ServicesFilter = (props: ServicesTableProps) => {
  const { t } = useTranslation();
    const serviceType = props.serviceType;
    const serviceTypeFilterForAdditionalAndCommune = serviceType === ServiceType.ADDITIONAL || serviceType === ServiceType.COMMUNE;

  const columns: DataTableColumn[] = useMemo(
    () => [
      { label: t('wasteCode'), key: 'wasteCode', type: 'text' },
      { label: t('Model.Waste.type'), key: 'wasteDescription', type: 'text' },
      {
        label: t('ownDescriptionOfWaste'),
        key: 'projectWasteDescription',
        type: 'text',
        excludeFromFilter: serviceTypeFilterForAdditionalAndCommune,
      },
      { label: t('activationDate'), key: 'activationDate', type: 'date' },
      { label: t('deactivationDate'), key: 'deactivationDate', type: 'date' },
      {
        label: t('settledSubcontractorName'),
        key: 'subcontractor',
        type: 'text',
        excludeFromFilter: serviceType === ServiceType.COMMUNE,
      },
      {
        label: t('settledTransportEntity'),
        key: 'transporter',
        type: 'text',
        excludeFromFilter: serviceTypeFilterForAdditionalAndCommune,
      },
    ],
    [],
  );

  return (
    <>
      <TableToolbar>
        <DataTableFilter columns={columns} onFiltersChange={(filters) => props.onFiltersChange(filters)} />
      </TableToolbar>
    </>
  );
};

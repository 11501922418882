import React from 'react';
import { useTranslation } from 'react-i18next';
import { TitlePositioner } from '@shared/general';
import { AggregateCommandButton, CheckboxLabel, Edit, EditModelButton, GridLabel, Panel, RenderIf, TextLabel } from '@lib/ui-components';
import { CompanyBranchViewModel } from '@lib/api-interface';
import { subcontractorTypesEnumToArray, subcontractorTypesEnumToObject } from '../../../../../../../../domain/enums/subcontractor.types.enum';

export interface CompanyBranchGeneralProps {
  branchView: CompanyBranchViewModel;
  refreshBranch: () => Promise<void>;
}

export function CompanyBranchGeneral(props: CompanyBranchGeneralProps) {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const branchView = props.branchView;
  const subcontractorRoles = subcontractorTypesEnumToObject(branchView.subcontractorTypes);

  return (
    <Panel direction='row' borderType='flat'>
      <Panel flex='1'>
        <TitlePositioner>
          <TextLabel label={t('general').toUpperCase()} />
        </TitlePositioner>
        <RenderIf true={props.branchView.roleContractor!}>
          <Panel borderType='raised'>
            <GridLabel>
              <GridLabel>
                <TextLabel label={t('companyShortName')} value={branchView.shortName} gridColumn='1/4' />
                <CheckboxLabel label={t('subcontractorRoleTransporting')} value={subcontractorRoles?.TRANSPORTING} />
                <CheckboxLabel label={t('subcontractorRoleReceiving')} value={subcontractorRoles?.RECEIVING} />
                <CheckboxLabel label={t('subcontractorRoleTakingOver')} value={subcontractorRoles?.TAKING_OVER} />
                <CheckboxLabel label={t('subcontractorRoleOther')} value={subcontractorRoles?.OTHER} />
                <CheckboxLabel label={t('subcontractorRoleReceivingCommunalWaste')} value={subcontractorRoles?.RECEIVING_COMMUNAL_WASTE} />
              </GridLabel>
              <GridLabel gridTemplateColumns='none' justifyContent='end'>
                <AggregateCommandButton
                  aggregateId={branchView.companyId!}
                  aggregateName='CompanyAggregate'
                  commandName='updateBranchGeneralSettings'
                  startIcon={<Edit />}
                  dialogTitle={t('general').toUpperCase()}
                  fields={[
                    {
                      field: 'shortName',
                      label: t('companyShortName'),
                      type: 'text',
                      defaultValue: branchView.shortName,
                    },
                    {
                      rowItems: [
                        {
                          field: 'subcontractorRoles.TRANSPORTING',
                          label: t('subcontractorRoleTransporting'),
                          type: 'boolean',
                          defaultValue: subcontractorRoles.TRANSPORTING,
                        },
                        {
                          field: 'subcontractorRoles.RECEIVING',
                          label: t('subcontractorRoleReceiving'),
                          type: 'boolean',
                          defaultValue: subcontractorRoles.RECEIVING,
                        },
                        {
                          field: 'subcontractorRoles.TAKING_OVER',
                          label: t('subcontractorRoleTakingOver'),
                          type: 'boolean',
                          defaultValue: subcontractorRoles.TAKING_OVER,
                        },
                        {
                          field: 'subcontractorRoles.OTHER',
                          label: t('subcontractorRoleOther'),
                          type: 'boolean',
                          defaultValue: subcontractorRoles.OTHER,
                        },
                        {
                          field: 'subcontractorRoles.RECEIVING_COMMUNAL_WASTE',
                          label: t('subcontractorRoleReceivingCommunalWaste'),
                          type: 'boolean',
                          defaultValue: subcontractorRoles.RECEIVING_COMMUNAL_WASTE,
                        },
                      ],
                    },
                  ]}
                  onBeforeSave={(data) => {
                    return {
                      subcontractorTypes: subcontractorTypesEnumToArray(data.subcontractorRoles),
                      shortName: data.shortName,
                    };
                  }}
                  hiddenValues={{ branchId: branchView.branchId }}
                  onSaved={() => props.refreshBranch()}
                />
              </GridLabel>
            </GridLabel>
          </Panel>
        </RenderIf>
      </Panel>
      <Panel flex='1'>
        <TitlePositioner>
          <TextLabel label={t('settings').toUpperCase()} />
        </TitlePositioner>
        <Panel borderType='raised'>
          <GridLabel>
            <GridLabel>
              <TextLabel label={t('perMonth')} value={'TODO'} />
            </GridLabel>
            <GridLabel gridTemplateColumns='none' justifyContent='end'>
              <EditModelButton
                id={0}
                entity={''}
                modelName={''}
                dialogTitle={t('settings').toUpperCase()}
                fields={[
                  {
                    field: '',
                    label: t('perMonth'),
                    type: 'select',
                    selectOptions: [
                      { value: '', label: t('perMonth') },
                      { value: '', label: t('Model.CompanyBranch.perReceipt') },
                    ],
                  },
                ]}
                onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
              />
            </GridLabel>
          </GridLabel>
        </Panel>
      </Panel>
    </Panel>
  );
}

import * as Yup from 'yup';
import { AggregateCommandClientService, DataResultsDto } from '@lib/common-sdk';
import { AddModelButton } from '@lib/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface CreateCompanyButtonProps {
  onSaved: () => void;
}

export const CreateCompanyButton = (props: CreateCompanyButtonProps) => {
  const { t } = useTranslation();

  function isCorrespondenceAddressVisible(data: any): boolean {
    return data['correspondenceAddressEqRegistrationAddress'] !== true;
  }

  return (
    <AddModelButton
      buttonLabel={t('Companies.addNew')}
      dialogTitle={t('Companies.addNew')}
      modelName='companies'
      fields={[
        {
          rowItems: [
            { label: t('companyName'), type: 'text', field: 'name', width: '200%' },
            { label: t('client'), type: 'boolean', field: 'roleClient' },
            { label: t('subcontractor'), type: 'boolean', field: 'roleContractor' },
          ],
        },
        {
          rowItems: [
            { label: t('vatin'), type: 'text', field: 'vatin' },
            { label: t('nationalCourtRegister'), type: 'text', field: 'nationalCourtRegister' },
            { label: t('creditor'), type: 'text', field: 'creditor' },
            { label: t('debitor'), type: 'text', field: 'debitor' },
          ],
        },
        {
          label: t('registrationAddress'),
          type: 'header',
          field: '-',
          margin: '1.5rem 0 0 0',
        },
        {
          rowItems: [
            {
              label: t('postcode'),
              type: 'text',
              field: 'registrationAddress.postalCode',
              width: '15rem',
            },
            {
              label: t('locality'),
              type: 'text',
              field: 'registrationAddress.town',
            },
          ],
        },
        {
          rowItems: [
            {
              label: t('street'),
              type: 'text',
              field: 'registrationAddress.street',
            },
            {
              label: t('houseNum'),
              type: 'text',
              field: 'registrationAddress.houseNum',
              width: '20rem',
            },
            {
              label: t('apartmentNum'),
              type: 'text',
              field: 'registrationAddress.apartmentNum',
              width: '20rem',
            },
          ],
        },
        {
          rowItems: [
            {
              label: t('country'),
              type: 'text',
              field: 'registrationAddress.country',
              defaultValue: t('austria'),
              width: '15rem',
            },
            {
              label: t('commune'),
              type: 'table-select',
              field: 'registrationAddress.communeId',
              tableSelectParams: {
                modelName: 'communes',
                dialogTitle: t('communes'),
                displayFormat: '{name}',
                columns: [
                  { key: 'name', label: t('commune'), type: 'text' },
                  { key: 'province', label: t('province'), type: 'text' },
                ],
                otherValuesMap: {
                  name: 'registrationAddress.commune',
                  province: 'registrationAddress.province',
                },
              },
            },
          ],
        },
        {
          label: t('correspondenceAddress'),
          type: 'header',
          field: '-',
          margin: '1.5rem 0 0 0',
        },
        {
          label: t('correspondenceAddressEqRegistrationAddress'),
          type: 'boolean',
          field: 'correspondenceAddressEqRegistrationAddress',
          margin: '0 0 1.5rem 0',
          defaultValue: true,
        },
        {
          rowItems: [
            {
              label: t('postcode'),
              type: 'text',
              field: 'correspondenceAddress.postalCode',
              isVisible: (data) => isCorrespondenceAddressVisible(data),
              width: '15rem',
            },
            {
              label: t('locality'),
              type: 'text',
              field: 'correspondenceAddress.town',
              isVisible: (data) => isCorrespondenceAddressVisible(data),
            },
          ],
        },

        {
          rowItems: [
            {
              label: t('street'),
              type: 'text',
              field: 'correspondenceAddress.street',
              isVisible: (data) => isCorrespondenceAddressVisible(data),
            },
            {
              label: t('houseNum'),
              type: 'text',
              field: 'correspondenceAddress.houseNum',
              isVisible: (data) => isCorrespondenceAddressVisible(data),
              width: '20rem',
            },
            {
              label: t('apartmentNum'),
              type: 'text',
              field: 'correspondenceAddress.apartmentNum',
              isVisible: (data) => isCorrespondenceAddressVisible(data),
              width: '20rem',
            },
          ],
        },
        {
          rowItems: [
            {
              label: t('country'),
              type: 'text',
              field: 'correspondenceAddress.country',
              isVisible: (data) => isCorrespondenceAddressVisible(data),
              defaultValue: t('austria'),
              width: '15rem',
            },
            {
              label: t('commune'),
              type: 'table-select',
              field: 'correspondenceAddress.communeId',
              isVisible: (data) => isCorrespondenceAddressVisible(data),
              tableSelectParams: {
                modelName: 'communes',
                dialogTitle: t('communes'),
                displayFormat: '{name}',
                columns: [
                  { key: 'name', label: t('commune'), type: 'text' },
                  { key: 'province', label: t('province'), type: 'text' },
                ],
                otherValuesMap: {
                  name: 'correspondenceAddress.commune',
                  province: 'correspondenceAddress.province',
                },
              },
            },
          ],
        },
        {
          rowItems: [
            {
              label: t('contractConclusionDate'),
              type: 'date',
              field: 'contractConclusionDate',
            },
          ],
        },
      ]}
      validationSchema={{
        name: Yup.string().required(t('requiredField')),
        vatin: Yup.string().required(t('requiredField')).max(14, t('fieldToLong')),
        nationalCourtRegister: Yup.string().required(t('requiredField')).max(9, t('fieldToLong')),

        registrationAddress: Yup.object({
          street: Yup.string().required(t('requiredField')),
          postalCode: Yup.string().required(t('requiredField')).max(10, t('fieldToLong')),
          houseNum: Yup.string().required(t('requiredField')),
          town: Yup.string().required(t('requiredField')),
          communeId: Yup.string().required(t('requiredField')),
        }),
        correspondenceAddressEqRegistrationAddress: Yup.boolean(),
        correspondenceAddress: Yup.object().when('correspondenceAddressEqRegistrationAddress', {
          is: (correspondenceAddressEqRegistrationAddress: boolean) => !correspondenceAddressEqRegistrationAddress,
          then: Yup.object({
            street: Yup.string().required(t('requiredField')),
            postalCode: Yup.string().required(t('requiredField')),
            houseNum: Yup.string().required(t('requiredField')),
            town: Yup.string().required(t('requiredField')),
            communeId: Yup.string().required(t('requiredField')),
          }),
        }),
      }}
      hiddenValues={{ active: true }}
      onBeforeSave={(formData) => {
        const registrationCommuneIdParts = formData.registrationAddress.communeId.split('/');
        formData.registrationAddress!.communeId = registrationCommuneIdParts[registrationCommuneIdParts.length - 1];

        if (formData.correspondenceAddressEqRegistrationAddress === true) {
          formData.correspondenceAddress = {} as any;
          formData.correspondenceAddress!.street = formData.registrationAddress!.street;
          formData.correspondenceAddress!.town = formData.registrationAddress!.town;

          formData.correspondenceAddress!.commune = formData.registrationAddress!.commune;
          formData.correspondenceAddress!.communeId = formData.registrationAddress!.communeId;
          formData.correspondenceAddress!.province = formData.registrationAddress!.province;

          formData.correspondenceAddress!.country = formData.registrationAddress!.country;

          formData.correspondenceAddress!.postalCode = formData.registrationAddress!.postalCode;
          formData.correspondenceAddress!.houseNum = formData.registrationAddress!.houseNum;
          formData.correspondenceAddress!.apartmentNum = formData.registrationAddress!.apartmentNum;
        } else {
          const correspondenceCommuneIdParts = formData.correspondenceAddress.communeId.split('/');
          formData.correspondenceAddress!.communeId = correspondenceCommuneIdParts[correspondenceCommuneIdParts.length - 1];
        }
        delete formData.correspondenceAddressEqRegistrationAddress;
        return formData;
      }}
      overrideSaveCall={async (payload: any) => {
        try {
          await AggregateCommandClientService.createAggregate<any>('CompanyAggregate', payload);
          return new DataResultsDto(false, 200, undefined, undefined, undefined);
        } catch (error: any) {
          return new DataResultsDto(true, error.status, undefined, undefined, undefined);
        }
      }}
      onSaved={async () => props.onSaved()}
    />
  );
};

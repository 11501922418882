import React from 'react';
import { useTranslation } from 'react-i18next';
import { AddIcon, DataTable, Toolbar, UsecaseButton, UsecaseDeleteButton } from '@lib/ui-components';
import { useProjectContext } from '@context';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { PixSettlementDate } from '@lib/api-interface';
import { AggregateCommandClientService } from '@lib/common-sdk';
import { useQueryClient } from '@tanstack/react-query';

export default function PixTab() {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const project = useProjectContext();
  const queryClient = useQueryClient();

  return (
    <>
      <Toolbar
        left={[
          <FormControl variant={'standard'}>
            <InputLabel>{t('pixSettlementDay')}</InputLabel>
            <Select
              value={project.projectPixSettings?.pixSettlementDate}
              onChange={async (event) => {
                await AggregateCommandClientService.callCommand('ProjectAggregate', 'updateProjectPixSettings', project.projectId!, {
                  pixSettlementDate: event.target.value,
                });
                await queryClient.invalidateQueries(['projectViews', project.id]);
              }}
            >
              {Object.values(PixSettlementDate).map((val) => (
                <MenuItem key={`pixSettlementDay.${val}`} value={val}>
                  {t(`pixSettlementDay.${val}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>,
        ]}
        right={[
          <UsecaseButton
            useCaseName='CreateProjectPix'
            groupName='project'
            buttonLabel={t('Projects.ViewProject.Terms.Pix.addValue')}
            dialogTitle={t('Projects.ViewProject.Terms.Pix.addValue')}
            startIcon={<AddIcon />}
            fields={[
              { field: 'yearMonth', label: t('validFrom'), type: 'date-year-month' },
              {
                field: 'value',
                label: t('Projects.ViewProject.Terms.Pix.subtractedValue'),
                type: 'numeric',
                numericPrecision: 4,
              },
            ]}
            hiddenValues={{
              projectId: project.projectId,
            }}
            onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
          />,
        ]}
      />
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{ modelName: 'projectPixes' }}
        fetchFilters={{ projectId: project.projectId }}
        columns={[
          {
            key: 'yearMonth',
            label: t('validFrom'),
            type: 'date-year-month',
          },
          {
            key: 'value',
            label: t('Projects.ViewProject.Terms.Pix.subtractedValue'),
            type: 'numeric',
          },
        ]}
        rowOptions={[
          {
            renderer: (row) => (
              <UsecaseDeleteButton
                useCaseName='DeleteProjectPix'
                groupName='project'
                onDeleted={async () => setTableReloadKey(tableReloadKey + 1)}
                payload={{
                  projectId: row.projectId,
                  yearMonth: row.yearMonth,
                }}
              />
            ),
          },
        ]}
      />
    </>
  );
}

import React from 'react';
import { IconButton, TextField } from '@mui/material';
import { DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import enLocale from 'date-fns/locale/en-US';
import deLocale from 'date-fns/locale/de';
import { useTranslation } from 'react-i18next';
import { addDays, addMonths, addYears, format, isAfter, isBefore, parseISO } from 'date-fns';
import { useStateIfMounted } from 'use-state-if-mounted';
import { CalendarPickerView } from '@mui/x-date-pickers/internals/models';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import styled from 'styled-components';
import { RenderIf } from '../render-if';

const StyledDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: center;
`;

const StyledImageButtonContainer = styled.div`
  height: 2rem;
  margin-top: -1.5rem;
`;

export interface FormInputDatePlainProps {
  label?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  dataTestId?: string;

  dateVariant?: 'date' | 'year-month' | 'year';
  onChange: (value: any) => void;
  defaultValue?: any;
  error?: any;
  hideHelperTextIfEmpty?: boolean;
  isDeactivated?: boolean;
  clearableDate?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
}

export const FormInputDatePlain = (props: FormInputDatePlainProps) => {
  const { i18n } = useTranslation();
  const locale = i18n.language === 'de' ? deLocale : enLocale;
  const [value, changeValue] = useStateIfMounted(props.defaultValue);
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    changeValue(props.defaultValue);
    // eslint-disable-next-line
  }, [props.defaultValue]);

  let dateViews: CalendarPickerView[] | undefined;
  if (props.dateVariant === 'year-month') {
    dateViews = ['year', 'month'];
  } else if (props.dateVariant === 'year') {
    dateViews = ['year'];
  }

  function dateToString(date: Date) {
    try {
      if (props.dateVariant === 'year-month') {
        return format(date, 'yyyy-MM');
      } else if (props.dateVariant === 'year') {
        return format(date, 'yyyy');
      }
      return format(date, 'yyyy-MM-dd');
    } catch (e) {
      return null;
    }
  }

  function getInputFormat() {
    if (props.dateVariant === 'year') {
      return 'yyyy';
    } else if (props.dateVariant !== 'year-month') {
      return i18n.language === 'de' ? 'dd.MM.yyyy' : 'MM/dd/yyyy';
    }
    return i18n.language === 'de' ? 'MM.yyyy' : 'MM/yyyy';
  }

  function getInputMask() {
    if (props.dateVariant === 'year') {
      return '____';
    } else if (props.dateVariant !== 'year-month') {
      return i18n.language === 'de' ? '__.__.____' : '__/__/____';
    }
    return i18n.language === 'de' ? '__.____' : '__/____';
  }

  function onDateChange(val: Date | string | null) {
    let stringVal: string | Date | null = val != null ? val : '';
    if (val instanceof Date) {
      stringVal = dateToString(val);
    }
    props.onChange(stringVal);
    changeValue(stringVal);
  }

  function moveDate(delta: number) {
    let date = parseISO(value);
    if (props.dateVariant === 'year-month') {
      date = addMonths(date, delta);
      if (delta > 0 && props.maxDate != null && isAfter(date, props.maxDate)) {
        date = props.maxDate;
        date.setDate(1);
      } else if (delta < 0 && props.minDate != null && isBefore(date, props.minDate)) {
        date = props.minDate;
        date.setDate(1);
      }
    } else if (props.dateVariant === 'year') {
      date = addYears(date, delta);
      if (delta > 0 && props.maxDate != null && isAfter(date, props.maxDate)) {
        date = props.maxDate;
        date.setDate(1);
      } else if (delta < 0 && props.minDate != null && isBefore(date, props.minDate)) {
        date = props.minDate;
        date.setDate(1);
      }
    } else {
      date = addDays(date, delta);
      if (delta > 0 && props.maxDate != null && isAfter(date, props.maxDate)) {
        date = props.maxDate;
      } else if (delta < 0 && props.minDate != null && isBefore(date, props.minDate)) {
        date = props.minDate;
      }
    }
    onDateChange(date);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
      <DatePicker
        open={open}
        onClose={() => setOpen(false)}
        views={dateViews}
        inputFormat={getInputFormat()}
        mask={getInputMask()}
        onChange={(val) => onDateChange(val)}
        clearable={props.clearableDate !== false}
        clearText={t('Common.delete')}
        value={value !== '' && typeof value === 'string' ? parseISO(value) : null}
        minDate={props.minDate}
        maxDate={props.maxDate}
        renderInput={(params) => {
          if (params.inputProps === undefined) {
            params.inputProps = {};
          }
          params.inputProps['data-test-id'] = props.dataTestId || `input-${props.label?.replaceAll(' ', '-')}`;
          return (
            <StyledDateContainer>
              <RenderIf true={value !== ''}>
                <StyledImageButtonContainer>
                  <IconButton onClick={() => moveDate(-1)} data-test-id={`${params.inputProps['data-test-id']}-left`}>
                    <ArrowLeft />
                  </IconButton>
                </StyledImageButtonContainer>
              </RenderIf>
              <TextField
                {...params}
                size='small'
                margin='normal'
                variant={props.variant}
                fullWidth
                label={props.label}
                onClick={(event) => setOpen(true)}
                sx={{
                  marginTop: props.hideHelperTextIfEmpty ? '11px' : undefined,
                  caretColor: 'transparent',
                  cursor: 'pointer',
                }}
                helperText={props.error ? props.error.message : props.hideHelperTextIfEmpty ? '' : ' '}
                error={!!props.error}
                disabled={props.isDeactivated}
                onKeyDown={(event) => event.preventDefault()}
              />
              <RenderIf true={value === '' && props.dataTestId?.endsWith('-from')}>
                <div style={{ width: '1rem' }} />
              </RenderIf>
              <RenderIf true={value !== ''}>
                <StyledImageButtonContainer>
                  <IconButton onClick={() => moveDate(1)} data-test-id={`${params.inputProps['data-test-id']}-right`}>
                    <ArrowRight />
                  </IconButton>
                </StyledImageButtonContainer>
              </RenderIf>
            </StyledDateContainer>
          );
        }}
      />
    </LocalizationProvider>
  );
};

import { useTranslation } from 'react-i18next';
import { CheckboxLabel, CheckboxPanel, EditModelButton, GridLabel, Panel, TabPage, Tabs, TextLabel } from '@lib/ui-components';
import { CompanyBranchData } from './company-branch-data/company-branch-data';
import { CompanyBranchAgreements } from './company-branch-agreements';
import { CompanyBranchDecisionsConclusions } from './company-branch-decisions-conclusions/company-branch-decisions-conclusions';
import { useParams } from 'react-router-dom';
import { useCompanyBranchView } from '@data-source-hooks';
import { QueryResultRenderer } from '../../../../../../../libs/ui-components/components/query-result-renderer';
import { AggregateCommandClientService, DataResultsDto } from '@lib/common-sdk';
import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { CompanyBranchContextProvider } from '@context';
import { CompanyBranchFiles } from './company-branch-files/company-branch-files';
import { DateTimeService } from '../../../../../../../services/date-time.service';
import { ContractorClientServices } from '../../../contractor-view/contractor-client-services';

export const CompanyBranchView = () => {
  const { t } = useTranslation();
  const pathParams = useParams();
  const [branchQuery, reloadBranch] = useCompanyBranchView(pathParams['branchId']?.substring(1) || '');
  const queryClient = useQueryClient();

  return (
    <QueryResultRenderer
      query={branchQuery}
      render={(companyBranchView) => {
        const panels = new Array<TabPage>();

        panels.push({
          label: t('Model.CompanyBranch.data'),
          component: <CompanyBranchData companyBranchId={companyBranchView.branchId!} />,
        });

        panels.push({
          label: t('clientsServices'),
          component: <ContractorClientServices companyBranch={companyBranchView} />,
        });

        // TODO: implement services view for company branch
        // panels.push({
        //     label: t('clientsServices'),
        //     component: <CompanyBranchClientServices />
        // });

        // TODO: implement quantity received for subcontractor
        // panels.push({
        //     label: t('quantityReceived'),
        //     component: <CompanyBranchQuantityReceived />
        // });

        panels.push({
          label: t('agreements'),
          component: <CompanyBranchAgreements companyBranchId={companyBranchView.branchId!} />,
        });

        if (companyBranchView.roleContractor) {
          panels.push({
            label: t('decisionsConclusions'),
            component: <CompanyBranchDecisionsConclusions companyBranchId={companyBranchView.branchId!} />,
          });
        }

        panels.push({
          label: t('files'),
          component: <CompanyBranchFiles />,
        });

        return (
          <>
            <CompanyBranchContextProvider value={companyBranchView}>
              <Panel
                borderType='raised'
                header={companyBranchView.name}
                direction={'row'}
                rightItem={
                  <EditModelButton
                    id={0}
                    entity={companyBranchView}
                    modelName={'companyBranchViews'}
                    dialogTitle={t('companyData').toUpperCase()}
                    fields={[
                      {
                        rowItems: [
                          { field: 'name', label: t('name'), type: 'text' },
                          { field: 'shortName', label: t('companyShortName'), type: 'text' },
                        ],
                      },
                      {
                        rowItems: [
                          {
                            field: 'contractConclusionDate',
                            label: t('contractConclusionDate'),
                            type: 'date',
                          },
                          {
                            field: 'deactivationDate',
                            label: t('deactivationDate'),
                            type: 'date',
                            defaultValue: companyBranchView.deactivationDate,
                          },
                        ],
                      },
                    ]}
                    hiddenValues={{
                      companyId: companyBranchView.companyId,
                      branchId: companyBranchView.branchId,
                    }}
                    overrideSaveCall={async (payload: any) => {
                      try {
                        payload.correspondenceAddress = companyBranchView.correspondenceAddress;
                        payload.registrationAddress = companyBranchView.registrationAddress;

                        await AggregateCommandClientService.callCommand<any>('CompanyAggregate', 'updateCompanyBranch', companyBranchView.companyId!, payload);
                        return new DataResultsDto(false, 200, undefined, undefined, undefined);
                      } catch (error: any) {
                        return new DataResultsDto(true, error.status, undefined, undefined, undefined);
                      }
                    }}
                    onSaved={async (newModel) => {
                      await reloadBranch();
                      await queryClient.invalidateQueries(['companyViews', companyBranchView.companyId]);
                    }}
                  />
                }
              >
                <GridLabel placeItems='end'>
                  <GridLabel gridTemplateColumns='repeat(4, 1fr)'>
                    <TextLabel label={t('companyShortName')} value={companyBranchView.shortName} />
                    <TextLabel
                      label={t('contractConclusionDate')}
                      value={companyBranchView.contractConclusionDate ? new Date(companyBranchView.contractConclusionDate).toISOString() : undefined}
                      dateFormat={DateTimeService.DEFAULT_FORMAT_DATE}
                    />
                  </GridLabel>

                  <Panel direction={'row'}>
                    <CheckboxPanel>
                      <CheckboxLabel label={t('client')} value={companyBranchView.roleClient} />
                      <CheckboxLabel label={t('subcontractor')} value={companyBranchView.roleContractor} />
                    </CheckboxPanel>
                  </Panel>
                </GridLabel>
              </Panel>
              <Tabs marginTop='1rem' borderType='none' panels={panels} />
            </CompanyBranchContextProvider>
            {companyBranchView.lastModifiedBy != null && (
              <TextLabel value={`${t('lastModified')}: ${companyBranchView.lastModifiedBy}  [${DateTimeService.isoStringDateToDateTime(companyBranchView.lastModifiedDate)}]`} />
            )}
          </>
        );
      }}
    />
  );
};

import { useTranslation } from 'react-i18next';
import { GridLabel, Panel, Tabs, TextLabel } from '@lib/ui-components';
import React from 'react';
import axios from 'axios';
import {
  BranchAddressInfo,
} from '../../organization/projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-data/branch-address-info';
import { CompanyAgreements } from './company-agreements';
import { CompanyOrganizationUsers } from './company-organization-users';
import { FetchErrorIndicator } from '../../../libs/ui-components/components/fetch-error-indicator';
import { DateTimeService } from '../../../services/date-time.service';
import { CompanyAddress } from '@lib/api-interface';

interface ClientData {
  companyId: string;
  projectId: string;
  name: string;
  vatin: string;
  nationalCourtRegister: string;
  contractConclusionDate: Date;
  nameForSubcontractor: string;
  correspondenceAddress: CompanyAddress;
  registrationAddress: CompanyAddress;
}

export const ClientData = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(true);
  const [clientData, setClientData] = React.useState<ClientData | null>(null);

  React.useEffect(() => {
    const fetchCompanyView = async () => {
      const response = await axios.get('/api/company-info');
      setClientData(response.data);
      setIsLoading(false);
    };
    fetchCompanyView();
  }, []);

  if (isLoading) {
    return <div>{t('Common.loading')}</div>;
  }
  if (!clientData) {
    return <FetchErrorIndicator error={'Error while fetching company data'} />;
  }

  return (
    <>
      <Panel borderType={'raised'} header={clientData.name}>
        <GridLabel gridTemplateColumns={'auto auto auto auto'} justifyContent={'flex-start'} gap={'6rem'}>
          <TextLabel
            label={t('contractConclusionDate')}
            value={clientData?.contractConclusionDate ? new Date(clientData.contractConclusionDate).toISOString() : undefined}
            dateFormat={DateTimeService.DEFAULT_FORMAT_DATE}
          />
          <TextLabel label={t('vatin')} value={clientData.vatin} />
          <TextLabel label={t('nationalCourtRegister')} value={clientData.nationalCourtRegister} />
          <TextLabel label={t('Model.Project.projectNameForSubcontractor')} value={clientData.nameForSubcontractor} />
        </GridLabel>
      </Panel>
      <Tabs
        marginTop='1rem'
        initialTabIndex={0}
        panels={[
          {
            label: t('Model.CompanyBranch.data'),
            component: <BranchAddressInfo correspondenceAddress={clientData.correspondenceAddress} registrationAddress={clientData.registrationAddress} />,
          },
        ]}
        borderType='none'
      />
      <Tabs
        marginTop='1rem'
        initialTabIndex={0}
        panels={[
          {
            label: t('agreements'),
            component: <CompanyAgreements companyId={clientData.companyId} projectId={clientData.projectId} />,
          },
          {
            label: t('contactPersonsInterzero'),
            component: <CompanyOrganizationUsers companyId={clientData.companyId} />,
          },
        ]}
        borderType='none'
      />
    </>
  );
};

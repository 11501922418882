import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, Panel, ToggleButtons, Toolbar } from '@lib/ui-components';
import { CompanyBranchOrders } from './company-branch-orders';
import { CompanyBranchContactPersons } from './company-branch-contact-persons';
import { CompanyBranchInvoices } from './company-branch-invoices';
import { CompanyBranchReports } from './company-branch-reports';
import { CompanyBranchOrganizations } from './company-branch-organizations';
import { AddCompanyContactButton } from '../../../../add-company-contact-button';
import { useCompanyBranchContext } from '@context';
import { useCompanyView } from '@data-source-hooks';
import { ContactPersonTypeEnum } from '@lib/api-interface';
import { FetchErrorIndicator } from '../../../../../../../../../libs/ui-components/components/fetch-error-indicator';

export function CompanyBranchContact() {
  const { t } = useTranslation();
  const [activePage, setActivePage] = React.useState(0);
  const [activePageKey, setActivePageKey] = React.useState(0); // used to refresh page after adding new contact
  const companyBranch = useCompanyBranchContext();
  const [companyQuery] = useCompanyView(companyBranch.companyId!);
  const activePageType = useMemo(() => getActivePageType(activePage, companyBranch.roleContractor!), [activePage, companyBranch.roleContractor!]);
  const toggleButtons = companyBranch.roleContractor!
    ? [t('orders'), t('contactPersons'), t('einvoices'), t('reports'), t('interseroh')]
    : [t('contactPersons'), t('einvoices'), t('reports'), t('interseroh')];

  if (companyQuery.isLoading) {
    return <div>{t('Common.loading')}</div>;
  }
  if (companyQuery.isError) {
    return <FetchErrorIndicator error={companyQuery.error} />;
  }

  return (
    <Panel borderType='raised'>
      <HStack align='center'>
        <ToggleButtons labels={toggleButtons} activeIndex={activePage} onChange={(index) => setActivePage(index)} />
      </HStack>
      <Toolbar
        right={[
          <AddCompanyContactButton
            company={companyQuery.data!}
            branchId={companyBranch.branchId}
            contactType={activePageType}
            onSaved={() => setActivePageKey(activePageKey + 1)}
          />,
        ]}
      />
      {activePageType === ContactPersonTypeEnum.ORDER && <CompanyBranchOrders />}
      {activePageType === ContactPersonTypeEnum.CONTACT && <CompanyBranchContactPersons />}
      {activePageType === ContactPersonTypeEnum.EINVOICE && <CompanyBranchInvoices />}
      {activePageType === ContactPersonTypeEnum.REPORT && <CompanyBranchReports />}
      {activePageType === ContactPersonTypeEnum.ORGANIZATION && <CompanyBranchOrganizations />}
    </Panel>
  );
}

function getActivePageType(activePage: number, isContractor: boolean): ContactPersonTypeEnum {
  let contactType: ContactPersonTypeEnum | undefined = undefined;
  if (!isContractor) {
    if (activePage === 0) contactType = ContactPersonTypeEnum.CONTACT;
    if (activePage === 1) contactType = ContactPersonTypeEnum.EINVOICE;
    if (activePage === 2) contactType = ContactPersonTypeEnum.REPORT;
    if (activePage === 3) contactType = ContactPersonTypeEnum.ORGANIZATION;
  } else {
    if (activePage === 0) contactType = ContactPersonTypeEnum.CONTACT;
    if (activePage === 1) contactType = ContactPersonTypeEnum.ORDER;
    if (activePage === 2) contactType = ContactPersonTypeEnum.EINVOICE;
    if (activePage === 3) contactType = ContactPersonTypeEnum.REPORT;
    if (activePage === 4) contactType = ContactPersonTypeEnum.ORGANIZATION;
  }
  if (contactType === undefined) {
    throw new Error('Contact type is undefined');
  }
  return contactType;
}
